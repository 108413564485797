import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    catalog: [],
}

export const rCatalogSlice = createSlice({
    name: 'rCatalog',
    initialState,
    reducers:{
        rSetCatalog:(state, action)=>{
            state.catalog = action.payload
        },
    }
});

export const {rSetCatalog} = rCatalogSlice.actions;
export default rCatalogSlice.reducer;