import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Card, CardHeader, CardMedia, CardContent, Stack, Typography, FormGroup, FormControlLabel, Chip, Select, Fade, Button, Menu, MenuItem, CircularProgress, RadioGroup, Radio, NativeSelect, InputLabel, FormControl, Skeleton } from '@mui/material';
import { rLoading } from '../../Redux/Slice/loadingSlice';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import configServ from '../../Services/Config/config';
import { rSetAllRooms } from '../../Redux/Slice/roomListSlice';
import { rLockRoomList } from '../../Redux/Slice/lockRoomsSlice';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import crypto from '../../Utils/Crypto/Crypto';
import { Accordion, AccordionSummary, AccordionDetails, Checkbox, IconButton , Paper, Modal } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubPageHead from "../../Layout/Header/SubPageHead";
import beachImg from '../../Assets/Images/Beaches1.jpg';
import hillStationImg from '../../Assets/Images/HillStations.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ReadMoreReadLess from '../ReadMoreReadLess/ReadMoreReadLess';
import SortIcon from '@mui/icons-material/Sort';
import PaginateComp from "../PaginateComp/PaginateComp";
import 'leaflet/dist/leaflet.css';
import ShowOnMap from '../PopUp/StudioApartment/ShowOnMap';
import { message } from "../../Redux/Action/alertActions";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import { rSetRoom } from "../../Redux/Slice/roomSlice";
import { rSetRoomsByHotel } from "../../Redux/Slice/roomsByHotelSlice";
import { rSetGroupByHotel } from "../../Redux/Slice/groupByHotelSlice";
import { rSetBrandId, rSetHotelId, rSetRoomId } from '../../Redux/Slice/rGlobalVariableSlice';
import RoomDetails from '../PopUp/RoomDetailsPopup/RoomDetails';
import jwtDecode from 'jwt-decode';
import { rSetAvailableRooms } from '../../Redux/Slice/roomListSlice';
import rLocation from '../../Redux/Action/rLocationActions';
import TransportsPopUp from '../PopUp/Neighbourhood/NeighbourhoodPopUp';
import { map } from 'leaflet';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

export default function Ashram() {
  //#region code
  // const room = useSelector((state)=>state.room.room);
  // const allRooms = useSelector((state)=>state.rooms.rooms);
  const rLocationID = useSelector((state) => state.rLocation.rLocationId);
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const roomId = useSelector((state)=> state.rGlobalVariable.roomId);

  const dispatch = useDispatch();
  const rooms = useSelector((state) => state.rooms.rooms);
  const availableRooms = useSelector((state) => state.rooms.availableRooms);
  const [roomList, setRoomList] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isMapOpened, setIsMapOpened] = useState(false);
  //const [location, setLocation] = useState({ lng: 51.505, lat: -0.09 }); // Replace with your coordinates
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openDetailPopup, setOpenDetailPopup] = useState(false);
  const [itemId, setItemId] = useState('');
  const [noRecords, setNoRecords] = useState([]);
  const [locality, setLocality] = useState([]);
  const [selectedLocalities, setSelectedLocalities] = useState([]);
  const [transportPopup, setTransportPopup] = useState(false);
  const [neighbourhoodType, setNeighbourhoodType] = useState([]);
  const [locationIds, setLocationIds] = useState('');;
  const [amenities, setAmenities] = useState([]);
  const [ritualism, setRitualism] = useState([]);
  const [neighbourType, setNeighbourType] = useState(null);
  const [mapData, setMapData] = useState('');
  const [lockRoomId, setLockRoomId] = useState('');
  const [loading, setLoading] = useState(true);
  const [budget, setBudget] = useState([]);
  const [sortOrder, setSortOrder] = useState('low');
  const [budgetStart, setBudgetStart] = useState('');
  const [budgetEnd, setBudgetEnd] = useState('');

  const encrptedCity = Cookies.get('noRoom_rememberedCity');
  const city = crypto.decryptText(encrptedCity);

  useEffect(() => {
    // fetchRoomsByCity();
    getLocality();
    //getAmenities();
    //getRitualism();
    fetchBudget();
    fetchNeighbourhoodType();
    window.scrollTo(0,0);
  }, []);

  useEffect(() => {
    fetchRoomsByCity();
    window.scrollTo(0,0);
  }, [selectedLocalities]);

  useEffect(() => {
    setLoading(true);
    fetchRoomsByCity();
    window.scrollTo(0,0);
  }, [sortOrder,budgetStart]);

  useEffect(() => {
    setLoading(true);
    fetchRoomsByCity();
    window.scrollTo(0,0);
  }, [page]);

  //Function to get Localities
  const getLocality = async () => {
    try {
      const data = {
        "city": city
      }
      const res = await configServ.locality(data);
      if (res.status === 200) {
        setLocality(res.data);
      }
      else {
        setLocality([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  //Function to get Amenities
  const getAmenities = async(params) => {
    try{
      const data = {
        "city": city,
        "superCategory": null,
        "hotelId": "",
        "category": "Amenities",
        "locationId": "",
        "sortOrder": sortOrder,
        "budgetStart": budgetStart,
        "budgetEnd": budgetEnd, 
        "checkIn": null,
        "checkOut": null,
        "page": page,
        "rowsPerPage": rowsPerPage
      }
      const res = await configServ.getRoomListByCity(data);
      if (res.status === 200) {
        const amenityData = res.result.data;
        const amenityListData = amenityData.map((item) => ({
          id: item.id,
          name: item.item_name,
        }));
        setAmenities(amenityListData);
      }
    }
    catch(error){
      console.log(error);
    }
  }
  
  //Function to get Ritualism
  const getRitualism = async(params) => {
    try{
      const data = {
        "city": city,
        "superCategory": null,
        "hotelId": "",
        "category": "Ritualism",
        "locationId": "",
        "sortOrder": sortOrder,
        "budgetStart": budgetStart,
        "budgetEnd": budgetEnd, 
        "checkIn": null,
        "checkOut": null,
        "page": page,
        "rowsPerPage": rowsPerPage
      }
      const res = await configServ.getRoomListByCity(data);
      if (res.status === 200) {
        const ritualData = res.result.data;
        const ritualListData = ritualData.map((item) => ({
          id: item.id,
          name: item.item_name,
        }));
        setRitualism(ritualListData);
      }
    }
    catch(error){
      console.log(error);
    }
  }

  // Function to open the modal
  const openModal = () => {
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
    setIsMapOpened(false)
    setTransportPopup(false);
  };

  const toggleMap = (item) => {
    setMapData(item.location)
    setIsMapOpen(true);
    setIsMapOpened(!isMapOpened)
  };

  const handleDetails = async (item) => {
    try {
      // if (!isLogin) {
      //   dispatch(rSignInToggle());
      //   dispatch(message(true, "Please sign in to continue"));
      //   return false;
      // }
      setOpenDetailPopup(!openDetailPopup);
      dispatch(rSetHotelId(item.hotelId));
      dispatch(rSetRoomId(item.id));
      dispatch(rSetBrandId(item.brandId));
      const token = localStorage.getItem('noRoom_token');
      let user_id = 0;
      let user_email = "guest@gmail.com";
      if(token !== null){
        const decode = jwtDecode(token);
        user_id = parseInt(decode.user_id);
        user_email = decode.email;
      }

      dispatch(rSetRoom(item));
      const filteredByHotelId = rooms.filter(x => x.hotelId === item.hotelId);
      dispatch(rSetRoomsByHotel(filteredByHotelId));
      setItemId(item);
      setMapData(item.location);

      const data = {
        "hotelId": parseInt(item.hotelId),
        "roomId": parseInt(item.id),
        "userId": user_id,
        "userEmail": user_email
      }
      setLockRoomId(item.id);
      const res = configServ.lockRoom(data);
      if (res.status === 200) {
        //alert('locked room');
      }
      await fetchLockRooms();
      await fetchGroup(item.hotelId);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGroup = async (hotelId) => {
    try {
      const res = await configServ.getGroup(hotelId);
      if (res.status === 200) {
        rSetGroupByHotel(res.data);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchRoomsByCity = async () => {
    try {
      let superCategory = {};
      const stayData = {
          "serviceName": "",
          "createdBy": ""
        }
      const stay = await configServ.stayTypes(stayData);
      if(stay.status === 200){
          const result = stay.data.filter(x=>x.status === true);
          const staysData = result.map((item)=>({
              id: item.id.toString(),
              label: item.name,
          }))
          superCategory = staysData.filter(x=>x.label.toLowerCase().includes("plot"));
      }
      const data = {
        "city": city,
        "superCategory": superCategory[0].id,
        "hotelId": "",
        "category": "",
        "locationId": selectedLocalities,
        "sortOrder": sortOrder,
        "budgetStart": budgetStart,
        "budgetEnd": budgetEnd, 
        "checkIn": null,
        "checkOut": null,
        "page": page,
        "rowsPerPage": rowsPerPage
      }
      
      const res = await configServ.getRoomListByCity(data);
      if (res.status === 200) {
        const roomDatas = res.result.data;
        const roomListData = roomDatas.map((item) => ({
          id: item.id,
          hotelId: item.company_id,
          src: item.image,
          alt: item.item_code,
          brand: item.brand,
          brandId: item.brand_id,
          category: item.category,
          categoryId: item.category_id,
          group: item.group_name,
          groupId: item.group_id,
          roomNo: item.item_code,
          roomType: item.category,
          caption: item.category,
          price: item.costprice,
          title: item.item_name,
          detail: item.description,
          locationId: item.location_id,
          status: item.status,
          createdAt: item.created_at,
          facilities: item.associated_item,
          neighbourhood: item.company.neighbourhood,
          location: item.location !== null && item.location !== undefined ?item.location:'',
        }));
        let availableRooms = roomListData;
        await fetchLockRooms();
        // if (lockRoomsResponse !== undefined) {
        //   availableRooms = roomListData.filter(room =>
        //     !lockRoomsResponse.some(booking => booking.item_id.toString() === room.id.toString())
        //   );
        // }
        dispatch(rSetAllRooms(availableRooms));
        const activeRooms = availableRooms.filter(x => x.status === true);
        setTotalItems(res.result.count);
        setRoomList(activeRooms);
        dispatch(rSetAvailableRooms((rLocationID === null ? activeRooms : activeRooms.filter(x => x.locationId === rLocationID))));
        if(rLocationID !== null){
          setTotalItems(activeRooms.filter(x => x.locationId === rLocationID).length);
          dispatch(rLocation(null, null));
        }
        setLoading(false);
      }
      else if (res.status === 404) {
        setNoRecords("No Data found");
        dispatch(rSetAvailableRooms([]));
        setLoading(false);
      }
    }
    catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
 
const fetchNeighbourhoodType = async () =>{
  try{
     const neighbourhoodTypeRes = await configServ.getNeighbourhoodType();
     console.log(neighbourhoodTypeRes)
     if (neighbourhoodTypeRes.status === 200) {
       setNeighbourhoodType(neighbourhoodTypeRes.data)
     }
  }
  catch (error) {
    console.log(error)
  }
}

  const fetchLockRooms = async () => {
    try {
      const lockRoomsResponse = await configServ.lockRoomList();
      if (lockRoomsResponse.status === 200) {
        dispatch(rLockRoomList(lockRoomsResponse.data));
      }
      return lockRoomsResponse.data;
    }
    catch (error) {
      console.log(error);
    }
  };

  const breadcrumbItem = {
    Homepage: "/",
    Ashram: "/ashram",
  };

  // const handleSort = (params) => {
  //   try{
  //     if(params.id === 1){
  //       const oldest = roomList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  //       setRoomData(oldest);
  //     }
  //     else if(params.id === 2){
  //       const newest = roomList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //       setRoomData(newest);
  //     }
  //     else if(params.id === 3){
  //       const lowToHigh = roomList.sort((a, b) => a.price - b.price);
  //       setRoomData(lowToHigh);
  //     }
  //     else if(params.id === 4){
  //       const highToLow = roomList.sort((a, b) => b.price - a.price);
  //       setRoomData(highToLow);
  //     }
  //     handleClose();
  //   }
  //   catch(error){
  //     console.log(error);
  //   }
  // };

  const handleLocality = () => {
    try {
      if (selectedLocalities.length > 0) {
        const filteredRoomData = roomList.filter((item) =>
          selectedLocalities.includes(item.locationId)
        );
        setTotalItems(filteredRoomData.length);
        dispatch(rSetAvailableRooms(filteredRoomData));
      }
      else {
        setTotalItems(roomList.length);
        dispatch(rSetAvailableRooms(roomList));
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  function handleLocalityCheckboxChange(event, localityId) {
    if (event.target.checked) {
      // If the checkbox is checked, add the locality ID to the selectedLocalities array
      setSelectedLocalities([...selectedLocalities, localityId]);
    } else {
      // If the checkbox is unchecked, remove the locality ID from the selectedLocalities array
      setSelectedLocalities(selectedLocalities.filter(id => id !== localityId));
    }
  }

  const fetchBudget = async() => {
    try{
      const res = await configServ.getRoomBudgetRange(city);
      if(res.status === 200){
        setBudget(res.data);
        // Define the range and the number of intervals
        const startValue = parseInt(res.data.min_cost_price);
        const endValue = parseInt(res.data.max_cost_price);
        const numberOfIntervals = 5;

        // Calculate the step size
        const step = (endValue - startValue) / numberOfIntervals;

        // Generate the list of objects
        const rangeList = Array.from({ length: numberOfIntervals }, (_, index) => {
          const start = startValue + index * step;
          const end = start + step;
          return { start, end };
        });
        setBudget(rangeList);
      }
    }
    catch(error){
      console.log(error);
    }
  }
  

  const handleBudget = (event, newValue) => {
    try {
      const filteredRoomData = roomList.filter((item) => {
        const budgetList = budget.find((x) => x.value === newValue);
        if (budgetList) {
          const price = parseFloat(item.price);
          return price <= budgetList.label;
        }
        return false;
      });
      dispatch(rSetAvailableRooms(filteredRoomData));
    }
    catch (error) {
      console.log(error);
    }
  }
   
  
  const handleNeighbourhoodPOPup = (item, neighbourId) =>{
    setNeighbourType(neighbourId);
    setTransportPopup(true);
    setLocationIds(item.locationId)
  }

  const handleRoomDetailClose = async(params) => {
    setOpenDetailPopup(params);
    const unlockRoomsResponse = await configServ.unlockRoom(parseInt(roomId));
    if(unlockRoomsResponse.status === 200){
    }
    fetchRoomsByCity();
  }

  const handleBudgetChange = (event) => {
    // Handle range selection if needed
    const budgetRange = event.target.value;
    const start = budgetRange.split('-')[0] === undefined? "" : budgetRange.split('-')[0];
    const end = budgetRange.split('-')[1] === undefined? "" : budgetRange.split('-')[1];
    setBudgetStart(start);
    setBudgetEnd(end);
  };
  
  const handleSortOrder = (params) => {
    try{
      setSortOrder(params);
      handleClose();
    }
    catch(error){
      console.log(error);
    }
  }

  const handleSortByClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  //#endregion

  //#region UI
  return (
    <>
    {transportPopup === true && <TransportsPopUp open={transportPopup} setOpen={closeModal} location={locationIds}  neighbourType={neighbourType}/>}
      {<RoomDetails open={openDetailPopup} close={handleRoomDetailClose} mapData={mapData}/>}
      {isMapOpened === true && <ShowOnMap open={isMapOpen} setOpen={closeModal} mapData={mapData} />}
      <SubPageHead breadcrumbItems={breadcrumbItem} />
      <Stack direction={{xs:"column",md:"row"}} spacing={2} sx={{ padding: '20px', backgroundColor:'#f2f2f2'}}>

        {/* Left-side filter card */}
        <Stack direction="column" spacing={2} sx={{
          width: {
            xs: '90%', md: '30%'
          }
        }}>
          <Paper sx={{ position: 'sticky', top: '100px', fontWeight:'bold',zIndex: 100, width: { xs: '100%', sm: '100%', md: '100%', lg: '100%' } }} elevation={0}>
            <Card elevation={0}>
              <CardHeader
                title="Filter By:"
                sx={{ textAlign: 'center',fontWeight:'bold'}}
              />
              <CardContent sx={{ padding: '0px' }}>
                {/* Localities Accordion */}
                <Accordion defaultExpanded={false} elevation={0}
                  sx={{
                    border: 'none',
                    boxShadow: 'none',
                    "::before": {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant='h6' sx={{fontWeight:'bold'}}>Localities</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {locality.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={<Checkbox
                            size='small'
                            checked={selectedLocalities.includes(item.id)}
                            onChange={(e) => handleLocalityCheckboxChange(e, item.id)}
                          />}
                          label={<span style={{ fontSize: '13px' }}>{item.location_name}</span>}
                          sx={{ alignItems: 'center' }}
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>

                {/* Budget Accordion */}
                <Accordion defaultExpanded={false} elevation={0}
                  sx={{
                    "::before": {
                      display: 'none'
                    }
                  }}
                >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' sx={{fontWeight:'bold'}}>Budget</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <RadioGroup onChange={handleBudgetChange}>
                    <FormControlLabel
                      key={0}
                      value={0}
                      control={<Radio />}
                      label={`None`}
                    />
                    {budget.map((range, index) => (
                      <FormControlLabel
                        key={index+1}
                        value={`${range.start}-${range.end}`}
                        control={<Radio />}
                        label={`₹${range.start} - ₹${range.end}`}
                      />
                    ))}
                  </RadioGroup>
                </AccordionDetails>
              </Accordion>

                {/* Amenities Accordion */}
                {/* <Accordion defaultExpanded={false} elevation={0}
                  sx={{
                    "::before": {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' sx={{fontWeight:'bold'}}>Amenities</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {amenities.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={<Checkbox size='small' />}
                          label={<span style={{ fontSize: '13px' }}>{item.name}</span>}
                          sx={{ alignItems: 'center' }}
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion> */}

                {/* Rituals Accordion */}
                {/* <Accordion defaultExpanded={false} elevation={0}
                  sx={{
                    "::before": {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h6' sx={{fontWeight:'bold'}}>Ritualism</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {ritualism.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={<Checkbox size='small' />}
                          label={<span style={{ fontSize: '13px' }}>{item.name}</span>}
                          sx={{ alignItems: 'center' }}
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion> */}


                {/* Number of Bedrooms Accordion */}
                {/* <Accordion defaultExpanded={false} elevation={0}
                  sx={{
                    "::before": {
                      display: 'none'
                    }
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <h3>Available For</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormGroup>
                      {availableFor.map((item) => (
                        <FormControlLabel
                          key={item.id}
                          control={<Checkbox size='small' />}
                          label={<span style={{ fontSize: '13px' }}>{item.name}</span>}
                          sx={{ alignItems: 'center' }}
                        />
                      ))}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion> */}
                {/* Add more accordions as needed */}
              </CardContent>
            </Card>
          </Paper>
        </Stack>

        {/* Right-side apartment list */}
        <Stack direction="column" spacing={2}>
          <Stack spacing={3} >
            <Box display="flex" alignItems="center" justifyContent="space-between" > 
              
              {/* Results on the left end */}
              <Typography
                component="span"
                sx={{
                  fontSize: '20px',
                  lineHeight: '28px',
                  fontWeight: 'bold',
                  color: '#091e42',
                  textAlign: 'left',
                  verticalAlign: 'middle',
                }}
              >
                {totalItems} Results
              </Typography>

              {/*Rooms per page */}
              {/* <Stack direction="row" alignItems="center">
                <Typography variant="div" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component="span" 
                    sx={{
                      fontSize: '20px',
                      lineHeight: '28px',
                      fontWeight: 'bold',
                      color: '#091e42',
                      textAlign: 'left',
                      verticalAlign: 'middle',
                    }}
                  >
                    Rooms per Page
                  </Typography>
                  <FormControl sx={{ minWidth: 50, borderRadius: 0, borderBottom: 0 }}>
                    <Select
                      labelId="rooms-per-page-label"
                      id="rooms-per-page-select"
                      value={rowsPerPage}
                      onChange={(e) => setRowsPerPage(e.target.value)}
                      sx={{ padding: 0 }}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                    </Select>
                  </FormControl>
                </Typography>
              </Stack> */}

              {/* Sort dropdown on the right end */}
              <IconButton onClick={handleSortByClick} title='Sort By' aria-controls="sort-menu" aria-haspopup="true" sx={{backgroundColor: 'white'}}>
                <SortIcon/>
              </IconButton>
              <Menu
                id="sort-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={(e) => handleSortOrder('low')}>
                  Lowest
                </MenuItem>
                <MenuItem onClick={(e) => handleSortOrder('high')}>
                  Highest
                </MenuItem>
              </Menu>
              
            </Box>
              {loading === true? 
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '30vh' }}>
                <CircularProgress/>
              </div>
              
              // <Stack direction={{ xs: 'column', md: 'row' }}>
              //   <Skeleton animation="wave" variant='rectangle' height={'250px'} width="100%" />
              //   <Box sx={{ width: '100%' }}>
              //     <Skeleton />
              //     <Skeleton animation="wave" />
              //     <Skeleton animation={false} />
              //   </Box>
              // </Stack>
            
              :
              availableRooms.map((item) => (
                <Card sx={{
                  display: 'flex',
                  width: '100%', // Default to full width
              
                  '@media (min-width: 600px)': {
                    width: '70vw', // Set to 70% on screens wider than 600px
                  },
                }} 
                   elevation={10}>
                  <Stack direction={{ xs: 'column', md: 'row' }}>
                    <Carousel
                      showArrows={true}
                      showStatus={false}
                      showThumbs={false}
                      infiniteLoop
                      interval={3000}
                      transitionTime={1000}
                      autoPlay
                      width={300}
                    >
                      {item.src.map((itemImg) => (
                        <>
                          <div className='bg-image hover-zoom'>
                            <CardMedia
                              component="img"
                              alt={item.alt}
                              image={item.src.length > 0 ? `data:image/png;base64,${itemImg.image}` : ""}
                              sx={{ width: '100%',height:'250px' }} />
                          </div>
                        </>
                      ))}
                    </Carousel>
  
                    <CardContent sx={{ flex: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0px' }}>
                      {/* Left side with title */}
                      <Typography
                        variant="subtitle1"
                        component="div"
                        color={'#006ce4'}
                        sx={{ cursor: 'pointer', fontSize: '20px', fontWeight: 'bolder', ':hover': { color: '#173e68' } }}
                        onClick={() => handleDetails(item)}
                      >
                        {item.title}
                      </Typography>

                      {/* Right side with price */}
                      <Box>
                        <Button variant="body1" component="span" display="inline" sx={{fontSize: '20px', fontWeight: 'bolder', ':hover': { color: '#173e68' }}}>
                          ₹{parseInt(item.price)}
                        </Button>
                      </Box>
                    </Box>
  
                      <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          style={{
                            marginLeft: '0px',
                            fontSize: '14px',
                            color: '#006ce4',
                            cursor: 'pointer',
                          }}
                          onClick={()=>toggleMap(item)}
                        >
                          <LocationOnIcon style={{color:'#db2026'}}/>
                          <span style={{ marginRight: '10px', color:'#006ce4', fontSize: '14px', textDecoration: 'underline'}}>
                            {item.location.address},{item.location.city}
                          </span>
                          <span style={{ textDecoration: 'underline' }}>
                            Show on map
                          </span>                          
                        </Typography>
                        {/* <span style={{ marginLeft: '10px', fontSize: '14px' }}>9km away from center</span> */}
                      </Typography>
  
                      <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <LocationOnIcon sx={{ color: '#006ce4' }} /> */}
                        <span style={{ marginRight: '5px',cursor:'pointer' ,color:'#fff',backgroundColor:'#008234' , fontSize: '14px',}} onClick={()=>handleNeighbourhoodPOPup(item, null)}>Places nearby</span>
                        {neighbourhoodType.length > 0 && <>{neighbourhoodType.map((neighbour) => (
                          <Chip
                            key={neighbour.id}
                            label={neighbour.name}
                            variant="outlined"
                            icon={<DirectionsBusIcon sx={{ color: '#00bfff' }} />}
                            sx={{
                              marginRight: '5px',
                              '&:hover': {
                                backgroundColor: 'gray',
                              },
                              '&:hover .MuiChip-icon': {
                                color: 'navy',
                              },
                              '& .MuiChip-label': {
                                color: 'black',
                              },
                              '&:hover .MuiChip-label': {
                                color: 'navy',
                              },
                              fontWeight:'bold',
                              fontSize:'14px',
                            }}
                            onClick={()=>handleNeighbourhoodPOPup(item, neighbour.id)}
                          />
                        ))}
                        </>}
                      </Typography>
                      <Typography variant="body2" component="div" style={{fontWeight:700, color:'1A1A1A'}}>
                        <span>{item.category}</span>
                      </Typography>
                      <Typography variant="body2" component="div">                        
                        <ReadMoreReadLess text={item.detail === null? '': item.detail} maxLength={100} />
                      </Typography>
                      <Button variant='contained' onClick={() => handleDetails(item)}>View Details</Button>
                    </CardContent>
  
                  </Stack>
                </Card>
              ))
              }
            

            {availableRooms.length === 0 && <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '40vh',
                  color: '#BE132D'
                }}
              >
                <h1 style={{ textAlign: 'center' }}>{noRecords}</h1>
              </Box>
            </>}
          </Stack>
          <PaginateComp totalItems={totalItems} page={setPage} rowsPerPage={rowsPerPage}/>
        </Stack>
      </Stack>
    </>
  );
  //#endregion
}
