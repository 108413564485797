import React from 'react';
import { Box } from '@mui/material';

const RoomStatusColorCode = () => {
  return (
    <div style={{ display: 'flex' }}>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0px 15px' }}>
          <Box
            style={{
              //backgroundColor: `#d32f38`,
              border: `1px solid #d32f38`,
              width: '20px',
              height: '20px',
              marginRight: '5px',
            }}
          />
          <span>Booked</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0px 15px' }}>
          <Box
            style={{
              backgroundColor: `#e0e0e0`,
              //border: `1px solid #e0e0e0`,
              width: '20px',
              height: '20px',
              marginRight: '5px',
            }}
          />
          <span>Reserved</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0px 15px' }}>
          <Box
            style={{
              //backgroundColor: `#2e7d32`,
              border: `1px solid #2e7d32`,
              width: '20px',
              height: '20px',
              marginRight: '5px',
            }}
          />
          <span>Available</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', margin: '0px 15px' }}>
          <Box
            style={{
              backgroundColor: `#2e7d32`,
              //border: `1px solid #2e7d32`,
              width: '20px',
              height: '20px',
              marginRight: '5px',
            }}
          />
          <span>Selected</span>
        </div>
    </div>
  )
}

export default RoomStatusColorCode;
