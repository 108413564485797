import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    room: null,
}

export const roomSlice = createSlice({
    name:'room',
    initialState,
    reducers:{
        rSetRoom:(state, action)=>{
            state.room = action.payload
        },
    },
})

export const {rSetRoom} = roomSlice.actions;
export default roomSlice.reducer;