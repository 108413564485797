import React, { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';
import { manageCart } from '../ManageCart/ManageCart';
import { useSelector, useDispatch } from "react-redux";
// import HeaderCartIconCss from './HeaderCartIconCss.css'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

//FUNCTION
const CartIcon = () => {
  const [cartItemCount, setCartItemCount] = useState(0);
  const navigate = useNavigate();

  const cartCount = useSelector((state) => state.rGlobalVariable.cartCount);
    console.log("cartCount",cartCount)

  const handleCartIconClick = () => {
    navigate('/cart');
  };

  const getCartCountStyles = () => ({
    backgroundColor: cartCount > 0 ? 'red' : 'transparent',
    color: cartCount > 0 ? 'white' : 'transparent',
    borderRadius: '50%',
    padding: '2px',
    fontSize: '10px',
    position: 'absolute',
    width: '20px',
    height: '36%',
    top: '0',
    right: '11px',
  });

  return (
    <Tooltip title="CART">
      <IconButton
        id="basic-button2"
        sx={{
          color: 'white',
        }}
        onClick={handleCartIconClick}
      >
        <div style={getCartCountStyles()}>{cartCount}</div>
        <ShoppingCartIcon color='primary' fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

export default CartIcon;
