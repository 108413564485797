import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rooms: [],
    availableRooms:[],
}

export const roomListSlice = createSlice({
    name: 'roomList',
    initialState,
    reducers:{
        rSetAllRooms:(state, action)=>{
            state.rooms = action.payload
        },
        rSetAvailableRooms:(state, action)=>{
            state.availableRooms = action.payload
        },
    }
})

export const {rSetAllRooms, rSetAvailableRooms} = roomListSlice.actions;
export default roomListSlice.reducer;