import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { addItem, removeItem } from "../../Redux/Slice/cartSlice";
import { manageCart } from "../ManageCart/ManageCart";
import { message } from "../../Redux/Action/alertActions";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import {
  rSetCartCount,
  rSetCategoryIds,
} from "../../Redux/Slice/rGlobalVariableSlice";
import { useNavigate } from "react-router-dom";

const RitualDetails = ({ open, handleClose, ritual }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [inCart, setInCart] = useState(false);
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const cartCount = useSelector((state) => state.rGlobalVariable.cartCount);
  const categoryIds =
    useSelector((state) => state.rGlobalVariable.categoryIds) || [];

  const navigate = useNavigate();

  // useEffect(() => {
  // const categoryIds = manageCart.getCategoryIds();
  //   setInCart(categoryIds.includes(ritual.id));
  // }, [ritual.id]);

  const checkLoginAndDispatch = async () => {
    if (!isLogin) {
      dispatch(rSignInToggle());
      dispatch(message(true, "Please sign in to continue"));
      return false;
    }
    return true;
  };

  const handleBookNow = async () => {
    // Placeholder for initiating a payment request with the itemCode
    //console.log(`Initiating payment for item with code: ${ritual.item_code}`);
    // Replace the following line with your actual payment logic
    // window.location.href = `your_payment_api_url?itemCode=${itemCode}`;

    const loggedIn = await checkLoginAndDispatch();
    if (loggedIn) {
      //code to be done if user is logged in
      const result = await handleAddToCart();
      if(result){
        // setOpenConfPopup(true);
        navigate('/cart');
      }
    }
  };

  // const handleAddToCart = async() => {

  //   const loggedIn= await checkLoginAndDispatch();

  //   if (loggedIn) {
  //       const dataToSend = {
  //       ...formData,
  //       id: ritual.id,
  //       company_id: ritual.company_id,
  //       itemname: ritual.item_name,
  //       hsn_code: ritual.hsncode,
  //       Taxrate: ritual.tax_rate,
  //       rate: ritual.rate_unit,
  //       item_code: ritual.item_code,
  //       unit: ritual.unit,
  //       issizeAdded: Boolean(ritual.issizeAdded),
  //     };
  //     // // Check if the ritual is currently in the cart
  //     // const categoryIds = manageCart.getCategoryIds();
  //     const isCurrentlyInCart = categoryIds.includes(ritual.id);

  //     // Update the cart based on the current state
  //     if (!isCurrentlyInCart) {
  //       if (Boolean(ritual.issizeAdded) && !formData.item_size) {
  //         // handleOpenDialog('Please select a size for the item');
  //         return;
  //       }
  //       // Add to cart

  //       // manageCart.addCategoryId(ritual.id);
  //       const updatedCart = categoryIds.concat(ritual.id);
  //       dispatch(addItem(dataToSend));
  //      dispatch(rSetCartCount(cartCount + 1));
  //      dispatch(rSetCategoryIds(updatedCart))

  //     } else {
  //       // Remove from cart

  //       // manageCart.removeCategoryId(ritual.id);
  //       const updatedCart = categoryIds.filter(id => id !== ritual.id);

  //       dispatch(removeItem(ritual.id));
  //      dispatch(rSetCartCount(cartCount > 0 ? cartCount - 1 : 0));
  //      dispatch(rSetCategoryIds(updatedCart))

  //     }
  //     // Update the inCart state
  //     setInCart(!isCurrentlyInCart);
  //   }
  // };

  const handleAddToCart = async () => {
    try {
   
      const dataToSend = {
        ...formData,
        id: ritual.id,
        company_id: ritual.company_id,
        itemname: ritual.item_name,
        hsn_code: ritual.hsncode,
        Taxrate: ritual.tax_rate,
        rate: ritual.rate_unit,
        item_code: ritual.item_code,
        unit: ritual.unit,
        issizeAdded: Boolean(ritual.issizeAdded),
      };
      
      // Check if the ritual is currently in the cart
      const isCurrentlyInCart =
      categoryIds.length > 0 && categoryIds[0] && categoryIds[0].includes(ritual.id);
    

      // Update the cart based on the current state
      if (!isCurrentlyInCart) {
        if (Boolean(ritual.issizeAdded) && !formData.item_size) {
          // handleOpenDialog('Please select a size for the item');
          return;
        }

        // Add to cart
        const updatedCart = [
          (categoryIds[0] || []).concat(ritual.id),
          categoryIds[1],
        ];

        dispatch(addItem(dataToSend));
        dispatch(rSetCartCount(cartCount + 1));
        dispatch(rSetCategoryIds(updatedCart));
        setInCart(!isCurrentlyInCart);
        return true;
      } else {
        // Remove from cart
        const updatedCart = [
          categoryIds[0].filter((id) => id !== ritual.id),
          categoryIds[1],
        ];
        dispatch(removeItem(ritual.id));
        dispatch(rSetCartCount(cartCount > 0 ? cartCount - 1 : 0));
        dispatch(rSetCategoryIds(updatedCart));
        setInCart(!isCurrentlyInCart);
        return true;
      }
    } catch (error) {
      // Handle the error appropriately
      console.error("Error adding to cart:", error);

      // Set inCart to false if there was an error
      setInCart(false);
  
      // Return false if there was an error
      return false;
    }
  };
  // const handleAddbToCart = async () => {
  //   const loggedIn = await checkLoginAndDispatch();

  //   if (loggedIn) {
  //     const dataToSend = {
  //       ...formData,
  //       id: ritual.id,
  //       company_id: ritual.company_id,
  //       itemname: ritual.item_name,
  //       hsn_code: ritual.hsncode,
  //       Taxrate: ritual.tax_rate,
  //       rate: ritual.rate_unit,
  //       item_code: ritual.item_code,
  //       unit: ritual.unit,
  //       issizeAdded: Boolean(ritual.issizeAdded),
  //     };

  //     console.log(categoryIds);

  //     // Check if the ritual is currently in the cart
  //     const isCurrentlyInCart =
  //     categoryIds.length > 0 && categoryIds[0] && categoryIds[0].includes(ritual.id);
    

  //     // Update the cart based on the current state
  //     if (!isCurrentlyInCart) {
  //       if (Boolean(ritual.issizeAdded) && !formData.item_size) {
  //         // handleOpenDialog('Please select a size for the item');
  //         return;
  //       }

  //       // Add to cart
  //       const updatedCart = [
  //         (categoryIds[0] || []).concat(ritual.id),
  //         categoryIds[1],
  //       ];

  //       dispatch(addItem(dataToSend));
  //       dispatch(rSetCartCount(cartCount + 1));
  //       dispatch(rSetCategoryIds(updatedCart));
  //     } else {
  //       // Remove from cart
  //       const updatedCart = [
  //         categoryIds[0].filter((id) => id !== ritual.id),
  //         categoryIds[1],
  //       ];
  //       dispatch(removeItem(ritual.id));
  //       dispatch(rSetCartCount(cartCount > 0 ? cartCount - 1 : 0));
  //       dispatch(rSetCategoryIds(updatedCart));
  //     }

  //     // Update the inCart state
  //     setInCart(!isCurrentlyInCart);
  //   }
  // };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6" sx={{ mb: 1, backgroundColor: "#fcf8e3" }}>
          {ritual.item_name}
          <span style={{ float: "right", marginRight: "30px" }}>
            ₹{ritual.costprice}
          </span>
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: "white",
            backgroundColor: "red",
            borderRadius: 0,
            "&:hover": {
              color: "white",
              backgroundColor: "darkred",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1" sx={{ mb: 1, textAlign: "justify" }}>
          {ritual.description}
        </Typography>
        <Typography variant="caption" sx={{ color: "text.secondary" }}>
          {ritual.location.location_name},{ritual.location.city}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={handleAddToCart}>
          {inCart ? "Remove from Cart" : "Add to Cart"}
        </Button>
        <Button
          variant="contained"
          color="success"
          autoFocus
          onClick={handleBookNow}
        >
          Book Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RitualDetails;
