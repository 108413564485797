import React from 'react'
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar'
import Footer from '../../Layout/Footer/Footer'
import { useEffect } from 'react'
import StudioApartments1 from '../../Components/StudioApartments/StudioApartments1';

const StudioApartmentPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
        <ResponsiveAppBar />        
        <StudioApartments1/>
        <Footer />
    </>
  )
}

export default StudioApartmentPage
