import { Dialog, DialogTitle ,DialogContent, Stack,Typography,IconButton,Button} from '@mui/material'
import React from 'react'
import TabMap from '../../TabsDetail/TabMap'
import CloseIcon from '@mui/icons-material/Close';

const ShowOnMap = ({open,setOpen,mapData}) => {
    
    const handleClose = () => {
        setOpen(false);
    };
  return (
    <>
     <Dialog
       open={open}
       onClose={handleClose}
       fullWidth
       maxWidth='lg'
       >
         <Stack direction='row' justifyContent='space-between' alignItems='center' position={'absolute'} top={10} right={10}>
                    <Typography sx={{ mt: 2, ml: 2, color: 'text.secondary' }} variant='body2'></Typography>
                    <Button size='small' variant='contained' color='error' sx={{borderRadius:'0', zIndex:100}} onClick={handleClose}>
                    <CloseIcon color='white' sx={{
                            fontSize:'20px',
                            zIndex:100
                        }} />
                    </Button>
                </Stack>
        <DialogTitle  className='labels'></DialogTitle>
        <DialogContent>
            <TabMap mapData={mapData}/>
     </DialogContent>
     </Dialog>
    </>
  )
}

export default ShowOnMap