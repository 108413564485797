import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Loading = () => {
  return (
    <Box sx={{ display: 'flex', alignItems:'center', justifyContent:'center' , height:'50vh'}}>
      <CircularProgress />
    </Box>
  )
}

export default Loading;