import React from 'react';
import { Paper, Typography } from '@mui/material';

const disclaimerText = `
  Accuracy of Information:
  We make every effort to ensure the accuracy of hotel details, room rates, and other information provided on our platform. However, we cannot guarantee that all information is error-free, and we encourage users to verify details directly with the respective hotels.

  Third-Party Content:
  Our platform may contain links to third-party websites or content. We do not endorse or control these third-party sites and are not responsible for their content, privacy policies, or any services they may offer.

  Reservation Confirmations:
  While we facilitate hotel reservations, the confirmation of bookings ultimately depends on the hotel's availability and policies. We recommend verifying your reservation directly with the hotel and notifying them of any special requests.

  Cancellation and Refund Policies:
  Each hotel may have its own cancellation and refund policies. Users are responsible for understanding and adhering to these policies, which may vary depending on the hotel and the type of reservation made.

  User Responsibility:
  Users are responsible for providing accurate and complete information during the booking process. NoRoom is not liable for any issues arising from inaccurate or incomplete information provided by users.

  Changes and Updates:
  The information on our platform, including hotel details, rates, and policies, is subject to change without notice. We recommend checking for updates regularly or contacting the hotel directly for the latest information.

  Technical Issues:
  While we strive to provide a seamless booking experience, we are not responsible for any technical issues, interruptions, or downtime that may affect the availability or functionality of our platform.

  Liability Limitation:
  NoRoom and its affiliates shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising from the use of our platform, including but not limited to loss of data, revenue, or profits.

  By using NoRoom, you agree to these disclaimers and acknowledge that your use of the platform is at your own risk. If you do not agree with any part of these disclaimers, we advise refraining from using our services.

  NoRoom reserves the right to update these disclaimers at any time without prior notice. Users are encouraged to review this disclaimer periodically for changes.

  Last updated: [Date]
`;

const Disclaimer = () => {
  return (
    <Paper elevation={3} style={{ padding: '20px', whiteSpace: 'pre-wrap', margin: '20px' }}>
      <Typography variant='h6' style={{ marginBottom: '10px' }}>
        Disclaimer
      </Typography>
      <Typography variant='subtitle2' style={{ marginTop: '10px' }}>
        NoRoom is a platform designed to facilitate hotel reservations and enhance your travel experience. While we strive to provide accurate and up-to-date information, please be aware of the following disclaimers:
      </Typography>
      <Typography variant='body2' style={{ marginTop: '10px' }}>
        {disclaimerText}
      </Typography>
    </Paper>
  );
};

export default Disclaimer;
