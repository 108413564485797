import React from 'react'
import {
Button,
Dialog,
DialogActions,
DialogContent,
IconButton,
Stack,
TextField,
Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

const EmailVerification = ({ open, setOpen, verifyOtp, OTP }) => {
    const [otpInput, setOtpInput] = useState('');
    const [otpError, setOtpError] = useState('')

    const handleClose = () => {
        setOpen(false);
    };

    const handeOTP = () =>{
        
        if(otpInput === '' ){
            setOtpError('OTP is required.');
        }
        else if(otpInput.length !== 6 || otpInput !== OTP){
            setOtpError('Incorrect OTP.');
        }
        else if(OTP.length === 6 && otpInput === OTP){
            
            setOtpError('');
            verifyOtp(true);
            handleClose();
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth='sm'
            >
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Typography sx={{ mt: 2, ml: 2, color: 'text.secondary' }} variant='body2'></Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon color='primary' />
                    </IconButton>
                </Stack>
                <DialogContent>
                    <TextField
                        autoComplete="off"
                        name="emailOtpVerification"
                        required
                        fullWidth
                        id="emailOtpVerification"
                        label="Email OTP Verification"
                        value={otpInput}
                        onChange={(e)=>setOtpInput(e.target.value)}
                        onPaste={(e) => e.preventDefault()} // Prevent pasting
                        onCopy={(e) => e.preventDefault()} // Prevent copying
                        autoFocus
                        error={!!otpError}
                        helperText={otpError}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                     variant='contained' 
                     color='secondary'
                     onClick={handeOTP}
                     >
                       Submit OTP
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default EmailVerification
