
function generateRandomSixDigitNumber() {
    const min = 100000; // Minimum value (inclusive)
    const max = 999999; // Maximum value (inclusive)
    
    const randomSixDigitNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomSixDigitNumber;
  }

  function isValidEmail(email) {
    // You can implement a simple email validation regex or use a library like "validator.js" for more comprehensive validation.
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  function convertDateFormat(dateString){
    const [day, month, year] = dateString.split('/'); // Split the date into day, month, and year
  
    // Create a new Date object with the rearranged date parts (year, month - 1, day)
    const dateObject = new Date(`${year}-${month}-${day}`);
  
    // Get the year, month, and day from the date object
    const yearYYYY = dateObject.getFullYear();
    let monthMM = (dateObject.getMonth() + 1).toString().padStart(2, '0'); // Add padding zero if needed
    let dayDD = dateObject.getDate().toString().padStart(2, '0'); // Add padding zero if needed
  
    // Return the date in 'yyyy-mm-dd' format
    return `${yearYYYY}-${monthMM}-${dayDD}`;
  };

  const Common = {
    generateRandomSixDigitNumber,
    isValidEmail,
    convertDateFormat,
  }
  
  export default Common;