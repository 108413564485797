import { Divider, Stack, Typography, Grid, Card, CardMedia, CardContent, Box, Button, IconButton } from "@mui/material";
import React, { useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import './RoomList.css';
import RoomNavBar from "../Navigation/RoomNavBar";
import SubPageHead from "../../Layout/Header/SubPageHead";
import { useNavigate } from "react-router";
import RoomDetails from "../PopUp/RoomDetailsPopup/RoomDetails";
import configServ from "../../Services/Config/config";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import PaginateComp from "../PaginateComp/PaginateComp";
import { useSelector, useDispatch } from "react-redux";
import { rSetAllRooms } from "../../Redux/Slice/roomListSlice";
import { rSetAvailableRooms } from "../../Redux/Slice/roomListSlice";
import { rSetRoom } from "../../Redux/Slice/roomSlice";
import { rSetRoomsByHotel } from "../../Redux/Slice/roomsByHotelSlice";
import { rSetGroupByHotel } from "../../Redux/Slice/groupByHotelSlice";
import { rLoading } from "../../Redux/Slice/loadingSlice";
import { message } from "../../Redux/Action/alertActions";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import { rLockRoomList } from "../../Redux/Slice/lockRoomsSlice";
import Beach from '../../Assets/Images/Beaches1.jpg';
import HillStation from '../../Assets/Images/HillStations.png';
import jwtDecode from 'jwt-decode';
import crypto from "../../Utils/Crypto/Crypto";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

const HeaderImages = [
    {
      id:1,
      img:HillStation,
      imgTitle:'Hill Stations: Best Summer destinations'
    },
    {
      id:1,
      img:Beach,
      imgTitle:'Beaches: Best Winter destinations'
    }
  ];
  
const RoomList = () => {
//#region code
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const rooms = useSelector((state)=>state.rooms.rooms);
    const availableRooms = useSelector((state)=>state.rooms.availableRooms);
    const room = useSelector((state)=>state.room.room);
    const stayType = useSelector((state)=>state.stay.stay)
    const isLogin = useSelector((state)=>state.auth.isAuthenticated);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [roomData, setRoomData] = useState([]);
    const [roomList, setRoomList] = useState([]);
    const [open, setOpen] = useState(false);
    const [itemId, setItemId] = useState('');
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [noRecords, setNoRecords] = useState([]);

    useEffect(() => {
        if(!open){
            dispatch(rLoading(true));
            if(rooms.length === 0){
                fetchRoomsByCity();
            }
            else{
                fetchRoomListOnEntry();
            }
        }  
      },[open]);

      const fetchRoomListOnEntry = async() => {
        try{
            let availableRooms = rooms;
            if(room !== null){
                const unlockRoomsResponse = await configServ.unlockRoom(room.id);
                if(unlockRoomsResponse.status === 200){

                }
            }            
            const lockRoomsResponse = await fetchLockRooms();
            if (lockRoomsResponse !== undefined ) {
                availableRooms = rooms.filter(room => 
                    !lockRoomsResponse.some(booking => booking.item_id.toString() === room.id.toString())
                );             
            }
            dispatch(rSetAllRooms(availableRooms));
            const activeRooms = availableRooms.filter(x=>x.status === true);
            setTotalItems(activeRooms.length);
            dispatch(rSetAvailableRooms(activeRooms));
            setRoomList(activeRooms);
            dispatch(rLoading(false));
        }
        catch(error){
            console.log(error);
        }
      }

      const fetchLockRooms = async() => {
        try{
            const lockRoomsResponse = await configServ.LockRoomList();
                if(lockRoomsResponse.status === 200){
                    dispatch(rLockRoomList(lockRoomsResponse.data));
                }
            return lockRoomsResponse.data;
        }
        catch(error){
            console.log(error);
        }
      }      
    
    const fetchRoomsByCity = async () => {
        try {
            const encrptedCity = Cookies.get('noRoom_rememberedCity');
            const city = crypto.decryptText(encrptedCity);
            const data = {
                "city": city,
                "superCategory": "1",
                "hotelId": null,
                "checkIn": null,
                "checkOut": null
              }
            const res = await configServ.getRoomListByCity(data);
            if(res.status === 200){
                const roomDatas = res.data;
                const roomListData = roomDatas.map((item)=>({
                    id:item.id,
                    hotelId:item.company_id,
                    src: item.image,
                    alt: item.item_code,
                    brand: item.brand,
                    brandId:item.brand_id,
                    category: item.category,
                    categoryId:item.category_id,
                    group: item.group_name,
                    groupId:item.group_id,
                    roomNo: item.item_code,
                    roomType: item.category,
                    caption: item.category,
                    price: item.costprice,
                    title: item.item_name,
                    detail: item.description,
                    locationId: item.location_id,
                    status: item.status,
                    createdAt: item.created_at,
                    facilities: item.associated_item,
                    neighbourhood: item.company.neighbourhood,
                }));
                let availableRooms = roomListData;
                const lockRoomsResponse = await fetchLockRooms();                              
                if (lockRoomsResponse !== undefined ) {
                    availableRooms = roomListData.filter(room => 
                        !lockRoomsResponse.some(booking => booking.item_id.toString() === room.id.toString())
                    );                                      
                }
                dispatch(rSetAllRooms(availableRooms));
                const activeRooms = availableRooms.filter(x=>x.status === true);
                setTotalItems(activeRooms.length);
                dispatch(rSetAvailableRooms(activeRooms));
                setRoomList(activeRooms);
                dispatch(rLoading(false));
            }
            else{
                setNoRecords("No Data Found.");
                dispatch(rSetAvailableRooms([]));
                dispatch(rLoading(false));
            }
        } 
        catch(error) {
            dispatch(rLoading(false));
            console.log(error);
        }
    }

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    const handleRoomType = (roomtype) => {
        try{
            let filteredRooms = roomList;
            if (roomtype.toLowerCase() !== 'all') {
                filteredRooms = roomList.filter(x => x.roomType.toLowerCase().includes(roomtype.toLowerCase()));
            }
            setTotalItems(filteredRooms.length)
            dispatch(rSetAvailableRooms(filteredRooms));
            dispatch(rLoading(false));
        }
        catch(error){
            dispatch(rLoading(false));
            console.log(error);
        }
    };

    const breadcrumbItem = {
        Home: "/",
        RoomList: "/rooms",
    };
    
    const handleDetails = async(item) => {
        try{
            if(!isLogin){
                dispatch(rSignInToggle());
                dispatch(message(true, "Please sign in to continue"));
                return false;
            }
            const token = localStorage.getItem('noRoom_token');
            const decode = jwtDecode(token);

            dispatch(rSetRoom(item));
            const filteredByHotelId = rooms.filter(x=>x.hotelId === item.hotelId);
            dispatch(rSetRoomsByHotel(filteredByHotelId));
            setItemId(item);
            setOpen(!open);
            const data = {
                "hotelId": parseInt(item.hotelId),
                "roomId": parseInt(item.id),
                "userId": parseInt(decode.user_id),
                "userEmail": decode.email
            }
            const res = configServ.lockRoom(data);
            if(res.status === 200){
                alert('locked room');
            }
            await fetchGroup(item.hotelId);
        }
        catch(error){
            console.log(error);
        }        
    };

    const fetchGroup = async(hotelId) => {
        try{
          const res = await configServ.getGroup(hotelId);
          if(res.status === 200){
            rSetGroupByHotel(res.data);
          }
        }
        catch(error){
          console.log(error);
        }
    }

//#endregion

    return (
        <div className="RoomList">
            <SubPageHead breadcrumbItems={breadcrumbItem} HeaderImages={HeaderImages} />
            <RoomNavBar handleRoomType={handleRoomType} />
            <RoomDetails open={open} setOpen={setOpen} roomDetails={itemId}/>
            {(availableRooms.length > 0) ?             
            <>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    justifyContent="space-around"
                    alignItems="center"
                    mx='50px'
                >
                    <Grid container spacing={4}
                        sx={{
                            marginTop: -2,
                            marginLeft: 2,
                            marginRight: 2,
                            marginBottom: 2, // Bottom margin of 4 spacing units
                        }}
                    >
                        {availableRooms.slice(start, end).map((item, index) => (
                            <Grid item sm={6} md={4} xs={12} key={index}>
                                <Card sx={{ alignItems: "center" }}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                    <Carousel
                                        showArrows={false}
                                        showStatus={false}
                                        showThumbs={false}
                                        infiniteLoop
                                        interval={3000} 
                                        transitionTime={1000}
                                        autoPlay
                                    >
                                    {item.src.map((itemImg)=>(
                                    <div className='bg-image hover-zoom'>
                                        <CardMedia
                                            component="img"
                                            alt={item.alt}
                                            height="300"
                                            image={item.src.length > 0 ? `data:image/png;base64,${itemImg.image}` : ""}
                                            sx={{
                                                width: '100%',
                                                alignItems: "center",
                                                justifyContent: 'center',
                                            }}
                                        />
                                    </div>
                                    ))}
                                </Carousel>
                                        <CardContent>
                                            <Typography variant="body2" component="div">
                                                {item.category}
                                            </Typography>
                                        </CardContent>
                                        <Box
                                            sx={{
                                                background: '#eeeeeeee',
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                height: '100%',
                                                color: 'black',
                                                opacity: `${hoveredIndex === index ? 1 : 0}`
                                            }}
                                        >
                                            <Typography variant="h6" component="div">
                                            {item.roomNo}-{item.caption}
                                                <Typography variant="h6" component="span" style={{ float: 'right' }}>
                                                    ₹{item.price}
                                                </Typography>
                                            </Typography>
                                            <hr />
                                            <Typography variant="body" component="div" style={{ color: '#68b7db ' }}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body" component="div">
                                                Floor: {item.group}
                                            </Typography>
                                            <Typography variant="body" component="div">
                                                {item.detail}
                                            </Typography>
                                            {item.facilities && (
                                            <Grid container justifyContent="center">
                                                <ul className="facility-list">
                                                {item.facilities.map((facility, fIndex) => (
                                                    <li key={fIndex}>
                                                    <Typography variant="body2">
                                                        {facility.item_name}
                                                    </Typography>
                                                    </li>
                                                ))}
                                                </ul>
                                            </Grid>
                                            )}
                                            <Stack direction={'row'} gap={4} sx={{ alignContent: 'center', justifyContent: 'center', display: 'flex' , mt:'15px' }}>
                                                <Button variant="contained" onClick={() => handleDetails(item)}>View Details</Button>
                                                <Button variant="contained" onClick={() => handleDetails(item)}>Book Now</Button>
                                            </Stack>

                                        </Box>
                                    </Box>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Stack><PaginateComp totalItems={totalItems} start={setStart} end={setEnd} />
            </>
            : <Box
                    sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    color:'#BE132D',
                    height:'60vh'
                    }}
                >
                    <h1 style={{ textAlign: 'center' }}>No Data Found.</h1>
                </Box>
            }
        </div>
        
    );
};
export default RoomList;