import CryptoJS from 'crypto-js';

const secretKey = 'my-secret-key';

const encryptText = (text) => {
    const ciphertext = CryptoJS.AES.encrypt(text, secretKey).toString();
    return ciphertext;
  }

  const decryptText = (encryptedText) => {
    const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }

  const crypto = {
    encryptText,
    decryptText,
  }

  export default crypto;