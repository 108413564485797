import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { message } from '../../Redux/Action/alertActions';

const SimpleAlert = () => {
  const dispatch = useDispatch();
  const isAlert = useSelector((state)=> state.message.isAlert); 
  const imcomingMessage = useSelector((state)=> state.message.message); 
  const handleDialogClose = () => {
    dispatch(message(false,''));
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isAlert}
      onClose={handleDialogClose}
      PaperProps={{
        elevation: 3,
        sx: {
          backgroundColor: '#fff',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle
        sx={{ backgroundColor: '#d0342c', color: 'white', padding: '5px', margin: '5px', textAlign: 'center' }}
      >
        <h3>Alert</h3>
      </DialogTitle>
      <DialogContent sx={{ padding: '16px' }}>
        <Typography variant="body1" align="center" sx={{color: 'black'}}>
          {imcomingMessage}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', padding: '8px 16px' }}>
        <Button variant="contained" color="primary" onClick={handleDialogClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleAlert;
