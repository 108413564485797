import React from 'react';
import { Typography } from '@mui/material';
import { keyframes } from '@emotion/react';

const colorChangeAnimation = keyframes`
  0% {
    background-clip: text;
    color: 	#fe6b40;
  }

  30% {
    background-clip: text;
    color: #ff8829;  // Change to your desired color
  }

  60% {
    background-clip: text;
    color: 	#ffb366;  // Change to your desired color
  }

  100% {
    background-clip: text;
    color: #fe6b40;  // Change to your desired color
  }
`;

const AnimatedTitle = ({ title }) => {
  return (
    <Typography
      variant="h4"
      sx={{
        animation: `${colorChangeAnimation} 1s infinite`, // Adjust the duration as needed
        backgroundImage: 'linear-gradient(45deg, #660000, #003300, #000066)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)',
        margin: '60px',
        display: 'inline-block',
        fontWeight: 'Bolder',
      }}
    >
      {title.toUpperCase()}
    </Typography>
  );
};

export default AnimatedTitle;
