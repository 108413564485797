import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Stack,
  Button,
  TextField,
  Paper,
  Typography,
  Divider,
  Popover,
  anchorEl,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import SimpleAlert from "../Alert/SimpleAlert";
import Common from "../../Utils/Common/Common";
import { rLoading } from "../../Redux/Slice/loadingSlice";
import { message } from "../../Redux/Action/alertActions";
import jwtDecode from "jwt-decode";
import ConfirmationPopUp from "../PopUp/Confirmation/ConfirmationPopUp";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import Cookies from "js-cookie";
import crypto from "../../Utils/Crypto/Crypto";
import { rSetReserVation } from "../../Redux/Slice/reservationSlice";
import {
  rSetCartCount,
  rSetCategoryIds,
} from "../../Redux/Slice/rGlobalVariableSlice";

const TabCheckIn = ({ roomData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedRoom = useSelector((state) => state.room.room);
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const cartCount = useSelector((state) => state.rGlobalVariable.cartCount);
  const categoryIds =
    useSelector((state) => state.rGlobalVariable.categoryIds);
  const initialFormData = {
    email: "",
    checkInDate: null,
    checkOutDate: null,
    guests: 0,
  };
  const [formData, setFormData] = useState(initialFormData);
  const initialFormError = {
    email: "",
    checkInDate: null,
    checkOutDate: null,
    guests: "",
  };
  const [formError, setFormError] = useState(initialFormError);
  const [clicked, setClicked] = useState(false);
  const [openConfPopup, setOpenConfPopup] = useState(false);
  const today = new Date().toISOString().split("T")[0]; // Get the current date in ISO format (YYYY-MM-DD)
  const [minDate, setMinDate] = useState(today); // Set the minimum date to today
  const [subscriptionId, setSubscriptionId] = useState("");
  const [checkInDetails, setCheckInDetails] = useState("");
  const [daysDiff, setDaysDiff] = useState("");
  const [adults, setAdults] = useState(formData.adults || 1);
  const [children, setChildren] = useState(formData.children || 0);
  const [anchorEl, setAnchorEl] = useState(null);

  const getEmail = async () => {
    // const storedEmail = Cookies.get('noRoom_rememberedEmail');
    // let email_address = '';
    // if (storedEmail) {
    //   email_address = crypto.decryptText(storedEmail)
    // }
    const token = localStorage.getItem("noRoom_token");
    const decode = jwtDecode(token);
    const email_address = decode.email;
    setFormData((prev) => ({
      ...prev,
      email: email_address,
    }));
  };

  useEffect(() => {
    const setEmailFormData = async () => {
      await getEmail();
    };
    setEmailFormData();
  }, []);

  const handleInputChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    const errors = {
      email: formData.email
        ? Common.isValidEmail(formData.email)
          ? ""
          : "Invalid email format."
        : "Email is required.",
      checkInDate: formData.checkInDate ? "" : "CheckIn date is required.",
      checkOutDate: formData.checkOutDate ? "" : "CheckOut date is required.",
      // guests:   formData.guests === undefined || formData.guests === null || formData.guests === ''
      // ? 'No. of guests is required.'
      // : formData.guests > 0 && formData.guests <= 4
      // ? ''
      // : 'No. of guests must be between 1 and 4.',
    };
    return errors;
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!isLogin) {
        dispatch(rSignInToggle());
        dispatch(message(true, "Please sign in to book rooms."));
        return false;
      }
      // if(!isSignedIn){
      //   dispatch(message(true,'Please sign in to book rooms.'));
      //   return false;
      // }

      const validationErrors = validate();
      setFormError(validationErrors);
      if (Object.values(validationErrors).every((error) => error)) {
        return false;
      }
      if (Object.values(validationErrors).every((error) => !error)) {
        const token = localStorage.getItem("noRoom_token");
        const decode = jwtDecode(token);
        const checkin = new Date(formData.checkInDate);
        const checkout = new Date(formData.checkOutDate);
        const timeDifference = checkout - checkin;
        const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));
        const bookingDetails = await createBookingDetail(
          decode,
          daysDifference
        );
        setCheckInDetails(bookingDetails);
        dispatch(rSetReserVation(bookingDetails));
        setDaysDiff(daysDifference);
        const result = await handleAddToCart();
        if(result){
          // setOpenConfPopup(true);
          handleReset();
          navigate('/cart');
        }
      }
    } catch (error) {
      dispatch(rLoading(false));
      console.log(error);
    }
  };

  const createBookingDetail = async (decode, daysDifference) => {
    const bookingDetails = {
      email: formData.email,
      userId: parseInt(decode.user_id),
      customerCode: decode.customer_code,
      hotelId: parseInt(roomData.hotelId),
      roomId: roomData.id,
      billingType: "daily",
      serviceType: "service",
      billToAddress: decode.address,
      checkIn: formData.checkInDate,
      checkOut: formData.checkOutDate,
      extraValidDays: true,
      price: parseInt(roomData.price),
      status: true,
      orderNo: "",
      customerId: decode.customer_id,
      orderDate: new Date(),
      customerName: decode.name,
      contactAddress: decode.address,
      totalAmount: parseFloat(roomData.price) * daysDifference,
      isApproved: true,
      isSyncToTally: false,
      orderType: "service",
      roomName: roomData.title,
      hsnCode: "0",
      taxRate: 0,
      quantity: parseInt(formData.guests),
      rate: 0,
      amount: parseFloat(roomData.price) * daysDifference,
      roomCode: roomData.alt,
    };

    return bookingDetails;
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setClicked(true);
  };
  const handleFinalClose = () => {
    setOpenConfPopup(false);
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const updateAdults = (newAdults) => {
    if (newAdults >= 1 && newAdults <= 4) {
      setAdults(newAdults);
      handleInputChange({ target: { name: "adults", value: newAdults } });
    }
  };

  const updateChildren = (newChildren) => {
    if (newChildren >= 0 && newChildren <= 4) {
      setChildren(newChildren);
      handleInputChange({ target: { name: "children", value: newChildren } });
    }
  };

  const incrementAdults = () => {
    if (adults < 4) {
      updateAdults(adults + 1);
    }
  };

  const decrementAdults = () => {
    if (adults > 1) {
      updateAdults(adults - 1);
    }
  };

  const incrementChildren = () => {
    if (children < 4) {
      updateChildren(children + 1);
    }
  };

  const decrementChildren = () => {
    if (children > 0) {
      updateChildren(children - 1);
    }
  };
  // const handleAddToCart = async () => {
  //   try {
  //     if (!isLogin) {
  //       dispatch(rSignInToggle());
  //       dispatch(message(true, "Please sign in to book rooms."));
  //       return false;
  //     }

  //     const validationErrors = validate();
  //     setFormError(validationErrors);

  //     if (Object.values(validationErrors).some((error) => error)) {
  //       return false;
  //     }

  //     const token = localStorage.getItem("noRoom_token");
  //     const decode = jwtDecode(token);

  //     const checkin = new Date(formData.checkInDate);
  //     const checkout = new Date(formData.checkOutDate);
  //     const timeDifference = checkout - checkin;
  //     const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

  //     const bookingDetails = await createBookingDetail(decode, daysDifference);

  //     const isCurrentlyInCart = categoryIds.includes(roomData.id);

  //     if (!isCurrentlyInCart) {
  //       // Add to cart
  //       const updatedCart = [...categoryIds, roomData.id];
  //       dispatch(rSetCartCount(cartCount + 1));
  //       dispatch(rSetCategoryIds(updatedCart));
  //     } else {
  //       // Remove from cart
  //       const updatedCart = categoryIds.filter((id) => id !== roomData.id);
  //       dispatch(rSetCartCount(Math.max(cartCount - 1, 0)));
  //       dispatch(rSetCategoryIds(updatedCart));
  //     }
  //        // Update the inCart state
  //        setInCart(!isCurrentlyInCart);
  //     dispatch(rSetReserVation(bookingDetails));
  //   } catch (error) {
  //     // Handle the error appropriately
  //     console.error("Error adding to cart:", error);
  //   }
  // };

  const handleAddToCart = async () => {
    try {
      const isCurrentlyInCart =
        categoryIds.length > 0 &&
        categoryIds[1] &&
        categoryIds[1].includes(roomData.id);
      if (!isCurrentlyInCart) {    
        const updatedCart = [
          categoryIds[0],
          [roomData.id],
        ];
    
        (!categoryIds[1] || categoryIds[1].length === 0) && dispatch(rSetCartCount(cartCount + 1));

      dispatch(rSetCategoryIds(updatedCart));
      return true;
      } else {
        // Remove from cart
        const updatedCart = [
          categoryIds[0],
          categoryIds[1].filter((id) => id !== roomData.id),
        ];
        dispatch(rSetCartCount(Math.max(cartCount - 1, 0)));
        dispatch(rSetCategoryIds(updatedCart));
        return true;
      }
    } catch (error) {
      // Handle the error appropriately
      console.error("Error adding to cart:", error);  
      // Return false if there was an error
      return false;
    }
  };

  return (
    <>
      <Stack sx={{ justifyContent: "center", width: "100%" }}>
        <Stack
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            my: "0px",
          }}
        >
          <Divider
            component={"p"}
            sx={{
              py: 0,
              width: "100%",
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            FILL YOUR CHECK-IN-DETAILS
          </Divider>
        </Stack>
        <Paper
          sx={{
            backgroundColor: "#eeeeeeee",
            padding: "20px",
            width: "90%",
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
          }}
          elevation={2}
        >
          <>
            <form style={{ width: "100%" }} onSubmit={handleSubmit}>
              <Stack
                alignItems="center"
                justifyContent="space-around"
                spacing={2}
                sx={{ width: "100%" }}
              >
                <TextField
                  name="email"
                  fullWidth
                  label="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  margin="small"
                  size="small"
                  error={!!formError.email}
                  helperText={formError.email}
                  sx={{ backgroundColor: "white" }}
                />
                <TextField
                  name="checkInDate"
                  fullWidth
                  label="Check In"
                  type="date"
                  value={formData.checkInDate}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: minDate,
                  }}
                  size="small"
                  error={!!formError.checkInDate}
                  helperText={formError.checkInDate}
                  sx={{ backgroundColor: "white" }}
                />
                <TextField
                  name="checkOutDate"
                  fullWidth
                  label="Check Out"
                  type="date"
                  value={formData.checkOutDate}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: formData.checkInDate,
                  }}
                  size="small"
                  error={!!formError.checkOutDate}
                  helperText={formError.checkOutDate}
                  sx={{ backgroundColor: "white" }}
                />
                {/* <TextField
                  name='guests'
                  fullWidth
                  type="number"
                  label="No. of Guests"
                  value={formData.guests > 0 ? formData.guests: ''}
                  onChange={handleInputChange}
                  size="small"
                  error={!!formError.guests}
                  helperText={formError.guests}
                  sx={{backgroundColor:'white'}}
                /> */}
                {/* <FormControl fullWidth margin="normal" size="small">
              <InputLabel>No. of Guests</InputLabel>
                <Select
                  name="guests"
                  fullWidth
                  placeholder='No. of Guests'
                  label="No. of Guests"
                  value={formData.guests}
                  onChange={handleInputChange}
                  size="small"
                  error={!!formError.guests}
                  sx={{ backgroundColor: 'white' }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {[...Array(4)].map((_, index) => (
                    <MenuItem key={index} value={index + 1}>
                      {index + 1} Adult{index + 1 > 1 ? 's' : ''}{' '}
                      {4 - (index + 1) > 0 ? `+ ${4 - (index + 1)} Child${4 - (index + 1) > 1 ? 'ren' : ''}` : ''}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl> */}
                <TextField
                  name="guests"
                  fullWidth
                  label="No. of Guests"
                  value={`${adults} Adult${adults !== 1 ? "s" : ""} ${
                    children > 0
                      ? `+ ${children} Child${children !== 1 ? "ren" : ""}`
                      : ""
                  }`}
                  size="small"
                  error={!!formError.guests}
                  helperText={formError.guests}
                  sx={{ backgroundColor: "white", cursor: "pointer" }}
                  onClick={handlePopoverOpen}
                />
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Box
                    p={2}
                    sx={{
                      width: "500px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      borderRadius: 15,
                    }}
                  >
                    {/* <Typography variant="subtitle1">No. of Guests</Typography> */}
                    <Stack direction={"row"} gap={12}>
                      <Stack>
                        <Typography
                          sx={{
                            fontFamily: "Times New Roman",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Adult{adults !== 1 ? "s" : ""}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <IconButton
                          onClick={incrementAdults}
                          sx={{ color: "navy" }}
                        >
                          <AddCircleOutlineIcon fontSize="medium" />
                        </IconButton>
                        <Typography variant="h6">{adults}</Typography>
                        <IconButton
                          onClick={decrementAdults}
                          sx={{ color: "navy" }}
                        >
                          <RemoveCircleOutlineIcon fontSize="medium" />
                        </IconButton>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} gap={10}>
                      <Stack>
                        <Typography
                          sx={{
                            fontFamily: "Times New Roman",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          Child{children !== 1 ? "ren" : ""}
                        </Typography>
                      </Stack>
                      <Stack direction={"row"}>
                        <IconButton
                          onClick={incrementChildren}
                          sx={{ color: "navy" }}
                        >
                          <AddCircleOutlineIcon fontSize="medium" />
                        </IconButton>
                        <Typography variant="h6">{children}</Typography>
                        <IconButton
                          onClick={decrementChildren}
                          sx={{ color: "navy" }}
                        >
                          <RemoveCircleOutlineIcon fontSize="medium" />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Box>
                </Popover>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ minWidth: "100px" }}
                    >
                      Book Now
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </>
          {clicked === true && (
            <>
              <ConfirmationPopUp
                open={openConfPopup}
                onClose={handleFinalClose}
                roomData={selectedRoom}
                formDetails={checkInDetails}
                nights={daysDiff}
              />
              {/* <Stack>
              <Typography variant="h3" sx={{color:'darkgreen'}}>Reservation sent successfully!</Typography>
              <Typography variant="body"  sx={{color:'darkgreen'}}>Thank you, your reservation has been submitted to us and we'll contact you as quickly as possible to complete your booking.</Typography>
            </Stack> */}
            </>
          )}
        </Paper>
      </Stack>
    </>
  );
};

export default TabCheckIn;
