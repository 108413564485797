import React, { useEffect, useState } from "react";
import { Typography, TextField, Button, Grid, Box } from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import configServ from "../../Services/Config/config";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import VerifiedIcon from "@mui/icons-material/Verified";

const FormPasswordReset = () => {
  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const token = localStorage.getItem("noRoom_token");
  const decode = jwtDecode(token);
  const userEmail = decode.email;
  const isLogin = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();

  console.log("email here->>>>>", userEmail);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswords((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const isValidPassword = async (passwords) => {
    if (!passwords.newPassword) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }

    if (!passwords.confirmPassword) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }

    if (passwords.newPassword.length < 8) {
      setErrors({ newPassword: "New password must be at least 8 characters" });
      return false;
    }

    if (passwords.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Confirm password must be at least 8 characters",
      });
      return false;
    }

    // Password strength check
    // const hasUpperCase = /[A-Z]/.test(passwords.newPassword);
    // const hasLowerCase = /[a-z]/.test(passwords.newPassword);
    // const hasDigit = /\d/.test(passwords.newPassword);
    // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwords.newPassword);

    //   if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar)) {
    //     setErrors({
    //         newPassword: "New password must include at least one uppercase letter, one lowercase letter, one digit, and one special character"
    //     });
    //     return false;
    // }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrors({
        newPassword: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return false;
    }

    // Password is valid
    return true;
  };

  const handlePassword = async () => {
    try {
      // Validation
      const isValid = await isValidPassword({
        newPassword: passwords.newPassword,
        confirmPassword: passwords.confirmPassword,
      });

      if (!isValid) {
        return; // Exit if validation fails
      }

      setErrors({ currentPassword: "", newPassword: "", confirmPassword: "" });

      const data = {
        email: userEmail,
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword,
        confirmPassword: passwords.confirmPassword,
      };

      const { response } = await configServ.resetPassword(data);
      console.log("response", response);

      if (response.status === "200") {
        setSuccess(true)
        setPasswords({
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        console.log("password reset success");
      } else {
        console.log(response);
        setErrors({
          currentPassword: "Error with Current Password",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({
        currentPassword: "Some error occurred. Try again.",
        newPassword: "Error submitting form. Try again later.",
        confirmPassword: "Some error occurred. Try again.",
      });
    }
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Validate the form
  //   const validationErrors = {};
  //   if (!passwords.currentPassword) {
  //     validationErrors.currentPassword = "Current password is required";
  //   }
  //   if (!passwords.newPassword) {
  //     validationErrors.newPassword = "New password is required";
  //   }
  //   if (passwords.newPassword !== passwords.confirmPassword) {
  //     validationErrors.confirmPassword = "Passwords do not match";
  //   }
  //   setErrors(validationErrors);

  //   // If there are no validation errors, you can proceed with your password reset logic here
  //   if (Object.keys(validationErrors).length === 0) {
  //     // Perform password reset logic
  //     console.log("Password reset logic goes here");
  //   }
  // };

  useEffect(() => {
    if (!isLogin) {
      setPasswords({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      navigate("/ashram");
    }
  }, [isLogin]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextField
            required
            fullWidth
            label="Current Password"
            type="password"
            name="currentPassword"
            variant="standard"
            sx={{ backgroundColor: "white" }}
            value={passwords.currentPassword}
            onChange={handleChange}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword}
          />
        </Grid>
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            label="New Password"
            type="password"
            name="newPassword"
            variant="standard"
            sx={{ backgroundColor: "white" }}
            value={passwords.newPassword}
            onChange={handleChange}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
        </Grid>
        <Grid item xs={6} md={6}>
          <TextField
            fullWidth
            label="Confirm New Password"
            type="password"
            name="confirmPassword"
            variant="standard"
            sx={{ backgroundColor: "white" }}
            value={passwords.confirmPassword}
            onChange={handleChange}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Button on the Left */}
            <Grid item xs={12} sm={6}>
              <Box mt={2}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handlePassword}
                >
                  <RotateLeftIcon />
                  Reset Password
                </Button>
              </Box>
            </Grid>
            {/* Text Box on the Right */}
            <Grid item xs={12} sm={6}>
              {success && (
                <Box p={2} bgcolor="#e0f7fa" borderRadius="5px">
                  <Typography variant="subtitle1" component="div">
                    Password Reset Successful
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>

          {/* <Box mt={2}>
            <Button
              type="button"
              variant="contained"
              color="primary"
              onClick={handlePassword}
            >
              <RotateLeftIcon />
              Reset Password
            </Button>
          </Box> */}
        </Grid>
      </Grid>
    </form>
  );
};

export default FormPasswordReset;
