import React, { lazy, Suspense } from "react";
import HomeBody from "../../Components/Home/HomeBody";
import { Box } from "@mui/material";
import Footer from "../../Layout/Footer/Footer";
import { useEffect } from "react";
import { useState } from "react";
import Cities from "../../Components/PopUp/City/Cities";
import configServ from "../../Services/Config/config";
import Cookies from 'js-cookie';
import { useDispatch } from "react-redux";
import { rSetRoomCategory } from "../../Redux/Slice/roomCategorySlice";
import crypto from "../../Utils/Crypto/Crypto";
import ResponsiveAppBar from "../../Layout/Header/ResponsiveAppBar";
import SubPageHead from "../../Layout/Header/SubPageHead";
import Loading from "../../Components/Loading/Loading";
import { CircularProgress } from '@mui/material';
import Katha from "../../Components/katha/Katha.js";

const BestDestinations = lazy(() => import('../../Components/BestDestinations/BestDestinations.jsx'));

const initialCategory = [
  'all', 'single room', 'double room', 'luxury room', 'executive room', 'deluxe roomm'
]

const HomePage = () => {
  //#region code
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(null);
  const [roomData, setRoomData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [city, setCity] = useState('');

  useEffect(() => {
    const encrptedCity = Cookies.get('noRoom_rememberedCity');
    if (encrptedCity == null) {
      setOpen(true);
    }
    else {
      const city = crypto.decryptText(encrptedCity);
      setCity(city);
      //getLocality(city);
      //handleRoomData(city);
    }
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setTimeout(() => {
      // Your loading logic or data fetching
    }, 1000); // Simulate 1 second loading delay
  }, []);

  useEffect(() => {
    //handleLocality();
    window.scrollTo(0, 0);
  }, [city]);

  const fetchCategory = async () => {
    try {
      const hotelId = 11;
      const res = await configServ.getCategory(hotelId);
      if (res.status === 200) {
        const result = res.data;
        const menu = result.filter(item => {
          const categoryNameLower = item.categoryname.toLowerCase();
          if (initialCategory.includes(categoryNameLower)) {
            return true;
          }
          return false;
        });
        dispatch(rSetRoomCategory(menu));
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCityChange = (data) => {
    const city = data.toString();
    setCity(city);
    setSelectedCity(city);
    handleRoomData(city);
  }

  const handleRoomData = (city) => {
    try {
      setCity(city);
      fetchCategory();
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (roomtype) => {
    let filteredRooms = roomList;
    if (roomtype.toLowerCase() !== 'all') {
      filteredRooms = roomList.filter(x => x.roomType.toLowerCase().includes(roomtype.toLowerCase()));
    }
    const slicedList = filteredRooms.slice(0, 6);
    setRoomData(slicedList);
    // Update the selectedRoomType state when a menu item is clicked
  };

  const breadcrumbItem = {
    Homepage: "/",
  };

  //#endregion

  return (
    <>
      <Cities open={open} onClose={handleClose} city={handleCityChange} />

      <ResponsiveAppBar />
      <SubPageHead breadcrumbItems={breadcrumbItem} />
      {/* <Box sx={{position:'relative',mb:{sm:'100px',xs:'270px'} }}> */}
      {/* <Box sx={{position:'relative',mb:{sm:'20px',md:'20px'} }}>
      <HomeBody/>
      <SearchStays />
      </Box> */}
      {/* {city && <FavouriteRooms city={city}/>} */}
      <Suspense fallback={<CircularProgress />}>
      {/*<Katha /> */}
        <BestDestinations />
        <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4034247929670709"
          crossorigin="anonymous"></script>
        <ins class="adsbygoogle"
          style={{display:'block', textAlign:'center'}}
          data-ad-layout="in-article"
          data-ad-format="fluid"
          data-ad-client="ca-pub-4034247929670709"
          data-ad-slot="3253510413"></ins>
        <script>
          (adsbygoogle = window.adsbygoogle || []).push({ });
        </script>
      </Suspense>
      {/* <PropertyType /> */}
      {/* <UspSelection/> */}
      <Footer />
    </>
  );
};
export default HomePage;