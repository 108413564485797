import axios from "axios";
import configData from '../config.json';

//const apiUrl = configData.SERVER_URL;
export const appServiceName = 'https://land.d-crm.in/admin-api';
//export const appServiceName = 'https://localhost:7107';

class RestfulProvider {
    constructor() {
        this.setCommonHeaders();
    }
    setCommonHeaders = () => {
        const token = localStorage.getItem("noRoom_token");
        const serverToken = localStorage.getItem("noRoom_serverToken");
    
        if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }
    
        if (serverToken) {
            axios.defaults.headers.common["ServerToken"] = `Bearer ${serverToken}`;
        }
    };
    

    makeCall = (url, data, axiosMethod) => {
        const header = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        if (localStorage.getItem("noRoom_token")) {
            this.setCommonHeaders();
        }

        return new Promise((resolve, reject) => {
            const response = axiosMethod(`${appServiceName}${url}`, data, header);
          
            response
            .then((res) => {
                resolve(res.data);
            })
            .catch((error) => {
                console.log("Error message:", error?.message);
                const errorMessage =
                error?.response?.data?.message || "An error occurred. Please try again later.";
                reject(errorMessage);
            });

          });
          
          
    };

    put = (url, data) => {
        return this.makeCall(url, data, axios.put);
    };

    post = (url, data) => {
        return this.makeCall(url, data, axios.post);
    };

    get = (url) => {
        return this.makeCall(url, undefined, axios.get);
    };

    delete = (url, request) => {
        return this.makeCall(url, { data: request }, axios.delete);
    };
}

export default new RestfulProvider();