import { Button, Card, CardMedia, Dialog, DialogContent, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import AccountBalanceSharpIcon from '@mui/icons-material/AccountBalanceSharp';
import { useState } from 'react';
import Autocomplete from "@mui/material/Autocomplete";
import AllCities from './AllCities';
import configServ from '../../../Services/Config/config';
import Cookies from 'js-cookie';
import crypto from '../../../Utils/Crypto/Crypto';
import { useSelector, useDispatch } from "react-redux";
import { rSetAllRooms } from "../../../Redux/Slice/roomListSlice";
import { rLockRoomList } from "../../../Redux/Slice/lockRoomsSlice";
import HotelNotAvailaibleInCity from './HotelNotAvailaibleInCity';

const citiesIndia = [
    {
        id: 1,
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Noida",
        population: 16787941,
        state: "Delhi",
        latitude: 28.6139,
        longitude: 77.2090,
    },
    {
        id: 2,
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Delhi NCR",
        population: 16787941,
        state: "Delhi",
        latitude: 28.6139,
        longitude: 77.2090,
    },
    {
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Delhi",
        population: 16787941,
        state: "Delhi",
        latitude: 28.6139,
        longitude: 77.2090,
    },
    {
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Mumbai",
        population: 18414288,
        state: "Maharashtra",
        latitude: 19.0760,
        longitude: 72.8777,
    },
    {
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Bangalore",
        population: 12442373,
        state: "Karnataka",
        latitude: 12.9716,
        longitude: 77.5946,
    },
    {
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Kolkata",
        population: 14787000,
        state: "West Bengal",
        latitude: 22.5726,
        longitude: 88.3639,
    },
    {
        img: <IconButton><AccountBalanceSharpIcon fontSize='large' /></IconButton>,
        city: "Chennai",
        population: 8696010,
        state: "Tamil Nadu",
        latitude: 13.0827,
        longitude: 80.2707,
    },
];


const Cities = ({ open, onClose, city }) => {
    const dispatch = useDispatch();
    const [allCities, setAllCities] = useState(false);
    const [cityData, setCityData] = useState([]);
    const [notAvailable, setNotAvailable] = useState(false)

    useEffect(() => {
        getCities();
    }, []);

    const getCities = async () => {
        try {
            const res = await configServ.getCities();
            if (res.status === 200) {
                setCityData(res.data.filter(x=>x.status === true));
            }
            else {
                alert("Failed to load cities.");
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCitySelect = async (event, newValue) => {
        try {
            if (newValue.city_name) {
                city(newValue.city_name);
                Cookies.set('noRoom_rememberedCity', crypto.encryptText(newValue.city_name), { expires: 30 });
                window.location.reload();
                onClose();
            }
            else {
                setNotAvailable(true)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleNotAvailable = () => {
        setNotAvailable(false)
    }

    return (
        <>
            {<HotelNotAvailaibleInCity open={notAvailable} onClose={handleNotAvailable} />}
            <Dialog
                open={open}
                // onClose={onClose}
                fullWidth
                maxWidth='lg'
            >
                {/*<Button onClick={onClose}>close</Button> */}
                <DialogContent
                    sx={{
                        // background: 'linear-gradient(to top right, #ccffff 0%, #ccccff 100%)',
                        backgroundColor: '#F0FFF0',
                        padding: '24px',
                        borderRadius: '8px',
                    }}>
                    <Autocomplete
                        options={cityData}
                        //value={selectedCity}
                        onChange={handleCitySelect}
                        getOptionLabel={(option) => option.city}
                        renderInput={(city) => (
                            <TextField
                                {...city}
                                label="Search For Your City"
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    ...city.InputProps,
                                    endAdornment: (
                                        <>
                                            {city.InputProps.endAdornment}
                                            <SearchIcon />
                                        </>
                                    ),
                                }}
                            />
                        )}
                        fullWidth
                    />
                    <Stack justifyContent={'center'} alignItems="center" sx={{ width: '100%', my: '15px' }}><Typography alignItems={'center'}>Popular Cities</Typography></Stack>
                    <Stack
                        // direction={'row'}
                        justifyContent={'center'}
                        alignItems="center"
                    // sx={{ width: '100%'}}
                    >
                        {/* <Typography sx={{width:'100%'}}>Popular City</Typography> */}
                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
                            {cityData.map((item) => (
                                <Grid item xs={6} sm={4} md={1.7} key={item.id}>
                                    <Stack sx={{ width: '100%' }}>
                                        <IconButton><AccountBalanceSharpIcon fontSize='large' onClick={() => handleCitySelect(null, item)}/>
                                        <Typography sx={{ textAlign: 'center', cursor: 'pointer' }} onClick={() => handleCitySelect(null, item)}>{item.city_name}</Typography>
                                        </IconButton>
                                    </Stack>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Cities
