import React from 'react'
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar';
import Rituals from '../../Components/Rituals/Rituals';
import Footer from '../../Layout/Footer/Footer';


const RitualsPage = () => {
  return (
    <>
    <ResponsiveAppBar/>
    <Rituals/>
    <Footer/>
    </>
  )
}

export default RitualsPage