import { Accordion, AccordionDetails, AccordionSummary, Divider, Stack, Typography } from '@mui/material'
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = React.memo(() => {
  //#region code
  const faqData = [
    {
      question: 'What is the standard check-in/out time in the hotels?',
      answer: 'The standard check-in time in hotels is usually around 3:00 PM, and the check-out time is around 12:00 PM.'
    },
    {
      question: 'What is the difference between a ‘Twin room’ and a ‘Double room’?',
      answer: 'A Twin room typically has two separate beds, while a Double room usually has one larger bed.'
    },
    {
      question: 'Will there be any additional charges for our children staying with us in the same hotel room?',
      answer: 'The policy regarding charges for children varies by hotel. It is advisable to check with the specific hotel for their policies on children staying in the same room.'
    },
    {
      question: 'Can I book more than three adults in one room?',
      answer: 'The maximum occupancy for a hotel room is typically set by the hotel. It is recommended to check with the hotel directly to inquire about the possibility of accommodating more than three adults in one room.'
    },
    {
      question: 'I want to book a hotel which is not listed on Musafir.com India. What should I do?',
      answer: 'If the desired hotel is not listed on Musafir.com India, you may need to explore alternative booking platforms or contact the hotel directly to make a reservation.'
    },
    {
      question: 'I need a specific type of hotel room (smoking, wheelchair friendly, etc).',
      answer: 'When making a reservation, you can specify your preferences, such as a smoking or wheelchair-friendly room. It is recommended to contact the hotel in advance to ensure your specific requirements are met.'
    },
    {
      question: 'How can I make group travel booking?',
      answer: 'For group travel bookings, you may need to contact the hotel directly or use group booking services provided by some online travel agencies. Group bookings often come with specific terms and conditions, so its advisable to inquire in advance.'
    },
    {
      question: 'I want to book a hotel in multiple destinations. How can I book a multi-sector itinerary?',
      answer: 'Booking a hotel in multiple destinations may require separate reservations for each location. You can use online booking platforms to make individual reservations for each destination.'
    },
    {
      question: 'Can I make a hotel booking over the phone?',
      answer: 'Some hotels may allow you to make reservations over the phone, while others prefer online bookings. It is recommended to check with the specific hotel for their preferred booking method.'
    },
  ];
  //#endregion
  return (
    <>
   <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'0px' }}>
   <Divider component={'p'} sx={{
          py: 0,
          width:'100%',
          fontWeight:'bold',
          fontSize:'18px'
        }}
        >
          FAQ
        </Divider>
        </Stack>
        <Stack sx={{ justifyContent: 'center', width: '100%',mx:'10px' }}>
          {faqData.map((faq, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="body2" component="p">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{backgroundColor:'#efefef'}}>
              <Typography variant="body2" component="p">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
          ))}
        </Stack>      
    </>
  )
});

export default FAQ
