import React, { useState } from "react";
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import SendIcon from "@mui/icons-material/Send";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import EmailVerification from "../PopUp/Verification/EmailVerification";
import Common from "../../Utils/Common/Common";
import Cookies from "js-cookie";
import configServ from "../../Services/Config/config";
import SimpleAlert from "../Alert/SimpleAlert";
import { useSelector, useDispatch } from "react-redux";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import { rSignUpToggle } from "../../Redux/Slice/signInSlice";
import { message } from "../../Redux/Action/alertActions";
import crypto from "../../Utils/Crypto/Crypto";
// import Copyright from '../copyright/Copyright';

const SignUp = React.memo(() => {
  //#region code
  const dispatch = useDispatch();
  const isSignUp = useSelector((state) => state.toggleSignIn.toggleSignUp);
  const [genOTP, setGenOTP] = useState("");
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [formData, setFormData] = useState(initialFormData);
  const initialErrors = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const [open, setOpen] = useState(false);
  const [sendOtp, setSendOtp] = useState(false);
  const [verifyOtp, setVerifyOtp] = useState(false);

  const handleInputChange = (event) => {
    try {
      const { name, value } = event.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      const validationErrors = validate();
      setErrors(validationErrors);

      if (Object.values(validationErrors).every((error) => !error)) {
        if (verifyOtp === false) {
          dispatch(
            message(true, "Please verify your Email address before submitting.")
          );
          return false;
        }
        const data = {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          isEmailConfirmed: verifyOtp,
          phone: formData.phone,
          password: formData.password,
        };
        var res = await configServ.signUp(data);
        if (res.status === 200) {
          // Perform your sign-up logic here...
          const response = res.data;
          Cookies.set("noRoom_rememberedEmail", formData.email, {
            expires: 30,
          }); // Expires in 30 days (you can adjust as needed)
          Cookies.set(
            "noRoom_rememberedPassword",
            crypto.encryptText(formData.password),
            { expires: 30 }
          );
          dispatch(rSignInToggle()); // Redirect to the sign-in after successful sign-up
        } else if (res.status === 400) {
          dispatch(message(true, res.message));
        } else {
          dispatch(message(true, "Registration failed."));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendOTP = async () => {
    const email = formData.email;
    const emailError = !email
      ? "Email is required."
      : !isValidEmail(email)
      ? "Invalid email format."
      : "";
    if (emailError === "") {
      const generatedOTP = Common.generateRandomSixDigitNumber();
      setGenOTP(generatedOTP.toString());
      setVerifyOtp(false);
      setSendOtp(true);
      const data = {
        toEmail: email,
        subject: "Verify your new account",
        body: `To verify your email address, please use the following One Time Password (OTP):
        ${generatedOTP}
        
        Do not share this OTP with anyone.
        Thank you!`,
      };
      dispatch(
        message(
          true,
          "OTP sent to your Email. Please verify your email address."
        )
      );
      await configServ.sendEmail(data);
    } else {
      setErrors({ email: emailError });
    }
  };

  const handleVerifyOTP = () => {
    setOpen(!open);
  };

  const isValidEmail = (email) => {
    // You can implement a simple email validation regex or use a library like "validator.js" for more comprehensive validation.
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validate = () => {
    const errors = {
      firstName: formData.firstName ? "" : "First Name is required.",
      lastName: formData.lastName ? "" : "Last Name is required.",
      email: formData.email
        ? isValidEmail(formData.email)
          ? ""
          : "Invalid email format."
        : "Email is required.",
      password: formData.password ? "" : "Password is required.",
      confirmPassword: formData.confirmPassword
        ? formData.confirmPassword === formData.password
          ? ""
          : "Passwords do not match."
        : "Confirm Password is required.",
    };

    return errors;
  };

  const handleClose = () => {
    handleReset();
    dispatch(rSignUpToggle());
  };

  const handleReset = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    setGenOTP("");
    setOpen(false);
    setSendOtp(false);
    setVerifyOtp(false);
  };

  //#endregion
  return (
    <Dialog
      open={isSignUp}
      onClose={handleClose}
      sx={{
        overflow: "hidden", // Prevent scroll
      }}
    >
      <EmailVerification
        open={open}
        setOpen={setOpen}
        verifyOtp={setVerifyOtp}
        OTP={genOTP}
      />
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px 40px",
          borderRadius: "10px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleInputChange}
                autoFocus
                error={!!errors.firstName}
                helperText={errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                autoComplete="family-name"
                error={!!errors.lastName}
                helperText={errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                autoComplete="email"
                error={!!errors.email}
                helperText={errors.email}
                disabled={sendOtp}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 0, mb: 0 }}
                size="small"
                color="secondary"
                endIcon={<SendIcon />}
                onClick={verifyOtp === false ? handleSendOTP : undefined}
              >
                {sendOtp === true ? "Resend" : "Send OTP"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Button
                type="button"
                fullWidth
                variant="contained"
                color="success"
                endIcon={<DoneOutlineIcon />}
                sx={{ mt: 0, mb: 0 }}
                size="small"
                onClick={verifyOtp === false ? handleVerifyOTP : undefined}
                disabled={genOTP.length === 6 ? false : true}
              >
                {verifyOtp === false ? "Verify" : "Verified"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                value={formData.password}
                onChange={handleInputChange}
                autoComplete="new-password"
                error={!!errors.password}
                helperText={errors.password}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                size="small"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                autoComplete="new-password"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link
                onClick={() => dispatch(rSignInToggle())}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#4587ec",
                  "&:hover": {
                    color: "#9400d3",
                  },
                }}
              >
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
});

export default SignUp;
