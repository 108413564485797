import React, { useEffect, useState } from "react";
import { Box,Typography, Divider, Stack, Card, CardMedia, CardContent, Slide } from "@mui/material";
import Breadcrumb from '../../Components/Breadcrumb/Breadcrumb';
import Carousel from 'react-material-ui-carousel'
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
//import 'react-responsive-carousel/lib/styles/carousel.min.css';
import configServ from "../../Services/Config/config";
import { useDispatch, useSelector } from "react-redux";
import { rLoading } from "../../Redux/Slice/loadingSlice";
import { rSetAllRooms } from "../../Redux/Slice/roomListSlice";
import { rSetAvailableRooms } from "../../Redux/Slice/roomListSlice";
import { rLockRoomList } from "../../Redux/Slice/lockRoomsSlice";
import { rSetCatalog } from "../../Redux/Slice/rCatalogSlice";
import { useNavigate } from "react-router-dom";
import AnimatedTitle from "../../Components/Animations/AnimatedTitle";

const SubPageHead = ({breadcrumbItems}) =>{
    const dispatch = useDispatch();
    const rCatalog = useSelector((state)=> state.rCatalog.catalog);
    const navigate = useNavigate();
    const [catalog, setCatalog] = useState([]);

    useEffect(()=>{
      if(rCatalog.length === 0){
        fetchCatalog();
      }
      else{
        setCatalog(rCatalog);
      }
    },[]);

    const fetchCatalog = async () => {
      try{
        const res = await configServ.getCatalog();
        if(res.status === 200){
          const catalog_items = res.data.catalog_items;
          const data = catalog_items.map((x)=>({
            id: x.catalog_id,
            title: x.item.item_name,
            city: x.item.city,
            image: x.item.image[0].image,
            locationId: x.item.location_id,
          }));
          setCatalog(data);
          dispatch(rSetCatalog(data));
        }
      }
      catch(error){
        console.log(error);
      }
    }
    

    const handleSubHeader = (item) => {
      try{
        fetchRoomsByCity(item.city);
      }
      catch(error){
        console.log(error);
      }
    }

    const fetchRoomsByCity = async (city) => {
      try {
        let superCategory = {};
      const stayData = {
          "serviceName": "",
          "createdBy": ""
        }
      const stay = await configServ.stayTypes(stayData);
      if(stay.status === 200){
          const result = stay.data.filter(x=>x.status === true);
          const staysData = result.map((item)=>({
              id: item.id.toString(),
              label: item.name,
          }))
          superCategory = staysData.filter(x=>x.label.toLowerCase() === "ashram");
      }
          //const encrptedCity = Cookies.get('noRoom_rememberedCity');
          //const city = crypto.decryptText(encrptedCity);
          const data = {
              "city": city,
              "superCategory": superCategory[0].id,
              "hotelId": null,
              "checkIn": null,
              "checkOut": null
          }
          dispatch(rLoading(false));
          const res = await configServ.getRoomListByCity(data);
          if(res.status === 200){
              const roomDatas = res.data;
              const roomListData = roomDatas.map((item)=>({
                  id:item.id,
                  hotelId:item.company_id,
                  src: item.image,
                  alt: item.item_code,
                  brand: item.brand,
                  brandId:item.brand_id,
                  category: item.category,
                  categoryId:item.category_id,
                  group: item.group_name,
                  groupId:item.group_id,
                  roomNo: item.item_code,
                  roomType: item.category,
                  caption: item.category,
                  price: item.costprice,
                  title: item.item_name,
                  detail: item.description,
                  locationId: item.location_id,
                  status: item.status,
                  createdAt: item.created_at,
                  facilities: item.associated_item,
              }));
              let availableRooms = roomListData;
              const lockRoomsResponse = await fetchLockRooms();                              
              if (lockRoomsResponse !== undefined ) {
                  availableRooms = roomListData.filter(room => 
                      !lockRoomsResponse.some(booking => booking.item_id.toString() === room.id.toString())
                  );                                      
              }
              dispatch(rSetAllRooms(availableRooms));
              const activeRooms = availableRooms.filter(x=>x.status === true);
              dispatch(rSetAvailableRooms(activeRooms));
              dispatch(rLoading(false));
              navigate('/ashram')
          }
          else{
            dispatch(rSetAvailableRooms([]));
              dispatch(rLoading(false));
          }
      } 
      catch(error) {
          dispatch(rLoading(false));
          console.log(error);
      }
  }
    
  const fetchLockRooms = async() => {
    try{
        const lockRoomsResponse = await configServ.lockRoomList();
            if(lockRoomsResponse.status === 200){
                dispatch(rLockRoomList(lockRoomsResponse.data));
            }
        return lockRoomsResponse.data;
    }
    catch(error){
        console.log(error);
    }
  } 

return(
    <>
      {catalog.length > 0 && (
        <Carousel
        autoPlay
        infiniteLoop
        transitionTime={1000}
        showThumbs={false}
        showArrows={false}
          showStatus={false}
          showIndicators={false}
        NextIcon={<NavigateNextOutlinedIcon />}
        PrevIcon={<ArrowBackIosNewOutlinedIcon />}
        sx={{
          //width: '100%',
          height: '200px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
          mt:'70px'
        }}
        >
          {catalog.map((item) => (
            <Box
              key={item.id}
              sx={{
                backgroundImage: `url(${`data:image/png;base64,${item.image}`})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center center', // Center the background image
                //width: '100%',
                height:'200px'
              }}
              onClick={() => handleSubHeader(item)}
            >
              <Stack sx={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
              <AnimatedTitle title={item.title} />
              </Stack>
              <Breadcrumb items={breadcrumbItems} />
            </Box>
          ))}
        </Carousel>
      )}
    </>
)
} 
export default SubPageHead;