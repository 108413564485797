import {
    Card,
    CardMedia,
    Dialog,
    DialogContent,
    IconButton,
    Stack,
    Typography,
    Button
} from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

const RoomImg = ({ open, setOpen, imgData }) => {

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth='md'
            >
                <Stack direction='row' justifyContent='space-between' alignItems='center' position={'absolute'} top={10} right={10}>
                    <Typography sx={{ mt: 2, ml: 2, color: 'text.secondary' }} variant='body2'></Typography>
                    <Button size='small' variant='contained' color='error' sx={{borderRadius:'0', zIndex:100}} onClick={handleClose}>
                    <CloseIcon color='white' sx={{
                            fontSize:'20px',
                            zIndex:100
                        }} />
                    </Button>
                </Stack>
                <DialogContent>
                    <Carousel
                        showArrows={true}
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop
                        interval={3000} 
                        transitionTime={1000}
                        autoPlay
                    >
                        {imgData.src.map((item,index)=>(
                            <div className='bg-image hover-zoom'>
                                <img src={imgData.src.length > 0 ? `data:image/png;base64,${item.image}`: ""} 
                                    alt={imgData.alt}
                                    height={'100%'}
                                    width={'100%'}
                                />
                        </div>
                        ))}                                    
                    </Carousel>
                    
                </DialogContent>

            </Dialog>
        </>
    )
}

export default RoomImg
