import React from 'react';
import { Box, CircularProgress } from '@mui/material';


const Loader = () => {
  return (
    <Box sx={{
        position:'absolute',
        height:'100%',
        width:'100%',
        backgroundColor:'#00000099',
        zIndex:9999,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
    }}
    >
        <Box>
            <CircularProgress/>
        </Box>
    </Box>
  )
}

export default Loader
