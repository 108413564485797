import React from "react";
import {
	BrowserRouter,
	Routes,
	Route,
} from "react-router-dom";
import HomePage from "../../Pages/Home/HomePage";
import Room from "../../Pages/Rooms/RoomPage";
import SignIn from "../../Components/SignIn/SignIn";
import SignUp from "../../Components/SignUp/SignUp";
import Loader from "../../Components/Loading/Loader";
import { useSelector } from "react-redux";
import SimpleAlert from "../../Components/Alert/SimpleAlert";
import StudioApartmentPage from "../../Pages/StudioApartmentPage/StudioApartmentPage";
import AshramsPage from "../../Pages/Ashrams/AshramsPage";
import TermsOfUse from "../../Pages/TermsOfUse/TermsOfUse";
import Account from "../../Pages/Account/Account";
import Forgot from "../../Components/Forgot/Forgot";
import Rituals from "../../Pages/Rituals/RitualsPage";
import Cart from "../../Pages/Cart/CartPage";

const AppRouter = () =>{
	const isLoading = useSelector((state)=> state.rLoading.isLoading);
    return(
        <>
        <BrowserRouter>
		<SignIn/>
		<SignUp/>
		<Forgot/>
		{isLoading && <Loader />}
		<SimpleAlert />
			<Routes>
				<Route path="/" element={<HomePage/>} />
				<Route path="/rooms" element={<Room />} />
				<Route path="/studio-apartments" element={<StudioApartmentPage/>} />
				<Route path="/ashram" element={<AshramsPage/>} />
				<Route path="/rituals" element={<Rituals/>} />
				<Route path="/cart" element={<Cart/>} />
				<Route path="/terms-of-use" element={<TermsOfUse />}/>
				<Route path="/account" element={<Account/>}/>
			</Routes>
		</BrowserRouter>
        </>
    );
};
export default AppRouter;