import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    group: []
}

export const groupByHotelSlice = createSlice({
    name:'groupByHotel',
    initialState,
    reducers:{
        rSetGroupByHotel:(state,action)=>{
            state.group = action.payload
        },
    },
})

export const {rSetGroupByHotel} = groupByHotelSlice.actions;
export default groupByHotelSlice.reducer;