import React, { useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector, useDispatch } from "react-redux";

const CartItems = ({
  cart,
  handleRemoveFromCart,
  //   handleIncreaseQty,
  //   handleDecreaseQty,
  formatCurrency,
}) => {
  //   return (
  //     <Grid item xs={6} sm={6} md={8}>
  //       <Typography variant="h2" style={{ margin: "1rem 0" }}>
  //         Cart
  //       </Typography>
  //       {cart && cart.length > 0 ? (
  //         cart.map((product) => (
  //           <Card key={product.id} style={{ margin: "1rem" }}>
  //             <CardContent>
  //               <Typography variant="h6">{product.itemName}</Typography>
  //               <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
  //                 <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
  //                   <Typography variant="body1">
  //                     Total: {formatCurrency(product.costprice * product.quantity)}
  //                   </Typography>
  //                   {/* <div style={{ display: "flex", alignItems: "center" }}>
  //                     <IconButton onClick={() => handleDecreaseQty(product)}>
  //                       <RemoveIcon />
  //                     </IconButton>
  //                     <Typography variant="body1">{product.quantity}</Typography>
  //                     <IconButton onClick={() => handleIncreaseQty(product)}>
  //                       <AddIcon />
  //                     </IconButton>
  //                   </div> */}
  //                 </div>
  //                 <Button
  //                   variant="contained"
  //                   color="secondary"
  //                   onClick={() => handleRemoveFromCart(product)}
  //                 >
  //                   Remove
  //                 </Button>
  //               </div>
  //             </CardContent>
  //           </Card>
  //         ))
  //       ) : (
  //         <Typography variant="body1">No items in the cart.</Typography>
  //       )}
  //     </Grid>
  //   );
  const [viewDetailsDialogOpen, setViewDetailsDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const reservationDetail = useSelector(
    (state) => state.reservation.bookingDetails
  );
  const handleViewDetailsClick = (product) => {
    setSelectedProduct(product);
    setViewDetailsDialogOpen(true);
    console.log("reservationDetail", reservationDetail);
    console.log(product)
  };

  return (
    <>
      <Dialog
        open={viewDetailsDialogOpen}
        onClose={() => setViewDetailsDialogOpen(false)}
      >
        {/* Dialog content here, using the selectedProduct state */}
        {selectedProduct && (
          <DialogContent>
            <form>
              <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Details
        </Typography>
      </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Customer Name"
                    value={reservationDetail.customerName}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Customer Email"
                    value={reservationDetail.email}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Hotel"
                    value={reservationDetail.roomName}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Room Number"
                    value={reservationDetail.roomCode}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Check-in"
                    value={reservationDetail.checkIn}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Check-out"
                    value={reservationDetail.checkOut}
                    disabled
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    label="Price"
                    value={reservationDetail.price}
                    disabled
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>

            {/* Close button */}
            <Grid
              container
              justifyContent="flex-end"
              style={{ marginTop: "16px" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setViewDetailsDialogOpen(false)}
              >
                Close
              </Button>
            </Grid>
          </DialogContent>
        )}
      </Dialog>

      <Grid item xs={6} sm={6} md={8}>
        <Typography variant="h2" style={{ margin: "1rem 0" }}>
          Cart
        </Typography>
        {cart && cart.length > 0 ? (
          cart.map((product) => (
            <Card key={product.id} style={{ margin: "1rem" }}>
              <CardContent>
                <Typography variant="h6">{product.itemName}</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">
                      Total:{" "}
                      {formatCurrency(product.costprice * product.quantity)}
                    </Typography>
                  </div>
                  <div style={{ display: "grid", gap: "1rem" }}>
                    {product.hotel && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleViewDetailsClick(product)}
                      >
                        View Details
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveFromCart(product)}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))
        ) : (
          <Typography variant="body1">No items in the cart.</Typography>
        )}
      </Grid>
    </>
  );
};

export default CartItems;
