import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
}

export const loadingSlice = createSlice({
    name: 'rLoading',
    initialState,
    reducers:{
        rLoading:(state,action)=>{
            state.isLoading = action.payload;
        },
    },
});

export const {rLoading} = loadingSlice.actions;
export default loadingSlice.reducer;