import React from 'react'
import {
     Stack, 
    Typography,
    IconButton,
    Divider,
    Box,
    CardMedia,
    Grid,
 } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import RoomImg from '../PopUp/ImageView/RoomImg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReadMoreReadLess from '../ReadMoreReadLess/ReadMoreReadLess';

const TabRoomDetails = ({ roomData }) => {
    const [openImage, setOpenImage] = useState(false);
    const handleImage = () => {
        setOpenImage(!openImage)
      }
    return (
        <>
        <RoomImg open={openImage} setOpen={setOpenImage} imgData={roomData} />
        <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'0px' }}>
        <Divider component={'p'} sx={{
          py: 0,
          width:'100%',
          fontWeight:'bold',
          fontSize: '18px'
        }}
        >
         KNOW YOUR ROOM
        </Divider>
        </Stack>
        <Stack sx={{my:'10px'}}>
        <Carousel
          showArrows={true}
          showStatus={false}
          showThumbs={false}
          infiniteLoop
          interval={3000}
          transitionTime={1000}
          autoPlay
          width={530}
        >
          {roomData.src.map((itemImg) => (
            <>
              <div className='bg-image hover-zoom' onClick={handleImage}>
                <CardMedia
                  component="img"
                  alt={roomData.alt}
                  image={roomData.src.length > 0 ? `data:image/png;base64,${itemImg.image}` : ""}
                  sx={{ width: '100%',height:'250px' }} />
              </div>
            </>
          ))}
        </Carousel>
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
            <Typography display="inline" sx={{ fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>
                Room No:
            </Typography>
            <Typography variant="body1" component="p" display="inline">
                {roomData.roomNo}
            </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
            <Typography display="inline" sx={{ fontWeight: 'bold', fontSize: '14px', marginRight: '10px' }}>
                Position:
            </Typography>
            <Typography variant="body1" component="p" display="inline">
                {roomData.group}
            </Typography>
        </Grid>

        <Grid item xs={12}>
            {roomData.facilities.length > 0 && (
                <>
                <Typography display="inline" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Amenities And Ritualism
                </Typography>
                <ul className="facility-list" style={{ paddingLeft: 0, listStyle: 'none', display: 'flex', flexWrap: 'wrap' }}>
                    {roomData.facilities.map((facility, fIndex) => (
                    <li key={fIndex} style={{ width: '50%' }}>
                        <Typography variant="body2"><CheckCircleIcon style={{ color: 'green' }}/>{facility.item_name}</Typography>
                    </li>
                    ))}
                </ul>
                </>
            )}
        </Grid>

        <Grid item xs={12}>
            <Typography display="inline" sx={{ fontWeight: 'bold', fontSize: '14px' }}>
                Description
            </Typography>
            <Typography variant="body2" component="p">
                <ReadMoreReadLess text={roomData.detail === null? '': roomData.detail} maxLength={100} />
            </Typography>
        </Grid>
       </Grid>
        </>
    )
}

export default TabRoomDetails;
