import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingDetails: {
    email: "",
    userId: 0,
    customerCode: "",
    hotelId: 0,
    roomId: "",
    billingType: "daily",
    serviceType: "service",
    billToAddress: "",
    checkIn: "",
    checkOut: "",
    extraValidDays: true,
    price: 0,
    status: true,
    orderNo: "",
    customerId: 0,
    orderDate: new Date(),
    customerName: "",
    contactAddress: "",
    totalAmount: 0,
    isApproved: true,
    isSyncToTally: false,
    orderType: "service",
    roomName: "",
    hsnCode: "0",
    taxRate: 0,
    quantity: 0,
    rate: 0,
    amount: 0,
    roomCode: "",
  },
};

const Reservation = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    rSetReserVation:(state,action)=>{
        state.bookingDetails=action.payload
    }
  },
});

export const {rSetReserVation}=Reservation.actions;
export default Reservation.reducer;
