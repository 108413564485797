import React, { useState } from 'react';
import './RoomNavBar.css'; // Import the CSS file
import configServ from '../../Services/Config/config';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { rSetRoomCategory } from '../../Redux/Slice/roomCategorySlice';

const initialCategory = [
  'all','single room','double room','luxury room','executive room','deluxe roomm'
]

const RoomNavBar = ({ handleRoomType }) => {
  //#region code
  const dispatch = useDispatch();
  const category = useSelector((state)=>state.roomCategory.roomCategory);
  const [roomType, setRoomType] = useState('All'); // Set 'All' as the default active state
  const [menuItems, setMenuItems] = useState([]);

  useEffect(()=>{
    if(category.length === 0){
      fetchCategory();
    }
    else{
      setMenuItems(category);
    }
  },[]);

  const fetchCategory = async () =>{
    try{
      const hotelId = 11;
      const res = await configServ.getCategory(hotelId);
      if(res.status === 200){
        const result = res.data;
        const menu = result.filter(item => {
          const categoryNameLower = item.categoryname.toLowerCase();
          if (initialCategory.includes(categoryNameLower)) {
            return true;
          }
          return false;
        });
        setMenuItems(menu);
        dispatch(rSetRoomCategory(menu));
      }
    }
    catch(error){
      console.log(error)
    }
  }

  const handleLinkClick = (item) => {
    if(item === null){
      setRoomType('all');
      handleRoomType('all');
    }
    else{
      const categoryname = item.categoryname.includes("Room")
      ? item.categoryname.replace("Room", "")
      : item.categoryname;
      setRoomType(categoryname);
      handleRoomType(categoryname);
    }    
  };
//#endregion

  return (
    <div className='RoomNavBar'>
      {menuItems.length > 0 && 
      <>
          <nav>
            <ul className="navbar"> {/* Add the "navbar" class */}
                <li className="navItem"> 
                    <a
                        onClick={() => handleLinkClick(null)} // Pass null or a specific value for "All"
                        className={`navLink${roomType === 'all' ? ' active' : ''}`}
                    >
                    ALL
                    </a>
                </li>
                {menuItems.map((item) => (
                <li key={item.category_id} className="navItem"> {/* Add the "navItem" class */}
                    <a
                    onClick={() => handleLinkClick(item)}
                    className={`navLink${item.categoryname.includes(roomType) ? ' active' : ''}`}
                    >
                    {item.categoryname.toLowerCase().includes("room")
                    ? item.categoryname.toLowerCase().replace("room", "").toUpperCase()
                    : item.categoryname.toUpperCase()}
                    </a>
                </li>
                ))}
            </ul>
          </nav>
      </>}        
    </div>
    
  );
};

export default RoomNavBar;
