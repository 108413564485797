// import React from 'react'
// import GoogleMapReact from 'google-map-react';
// import { Divider, Stack, Typography } from '@mui/material';
// // import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
// const AnyReactComponent = ({ text }) => <div>{text}</div>;

// const TabMap = React.memo(() => {
//   // const mapContainerStyle = {
//   //   width: '100%',
//   //   height: '400px',
//   // };

//   // const center = {
//   //   lat: 23.344101,
//   //   lng: 85.309563,
//   // };
//     const defaultProps = {
//         center: {
//           lat: 23.344101,
//           lng: 85.309563,
//         },
//         zoom: 15
//       };
//   return (
//     <>
//      {/* <LoadScript googleMapsApiKey="AIzaSyCSbn1M66hrDfFprjnbEC344tWP7DEHPqQ">
//       <GoogleMap
//         mapContainerStyle={mapContainerStyle}
//         center={center}
//         zoom={15}
//       >
//         <Marker position={center} />
//       </GoogleMap>
//     </LoadScript> */}
//      <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'10px' }}>
//      <Divider component={'h3'} sx={{
//           py: 2,
//           width:'80%'
//         }}
//         >
//          YOUR LOCATION
//         </Divider>
//             {/* <Typography variant='h5'>YOUR LOCATION</Typography> */}
//         </Stack>
//     <div style={{ height: '400px', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: 'AIzaSyCSbn1M66hrDfFprjnbEC344tWP7DEHPqQ' }} // Replace with your Google Maps API key
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       ></GoogleMapReact>
//     </div>
//     {/* <div style={{ height: '100vh', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: "AIzaSyCSbn1M66hrDfFprjnbEC344tWP7DEHPqQ" }}
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       >
//         <AnyReactComponent
//           lat={23.344101}
//           lng={85.309563}
//           text="My Marker"
//         />
//       </GoogleMapReact>
//     </div> */}
      
//     </>
//   )
// });

// export default TabMap
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Divider, Stack } from '@mui/material';

const TabMap = ({mapData}) => {
  // const position = [23.344101, 85.309563]; // Latitude and longitude
  const position = [mapData.latitude !== undefined && mapData.latitude !== null?mapData.latitude:'', mapData.longitude !== undefined && mapData.latitude !== null?mapData.longitude:''];

  return (
    <>
    <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'0px' }}>
    <Divider component={'p'} sx={{
         py: 0,
         width:'100%',
         fontWeight:'bold',
         fontSize:'18px'
       }}
       >
        LOCATION
       </Divider>
       </Stack>
    <MapContainer center={position} zoom={15} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
      {/* Add a tile layer */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Add a marker at the specified position */}
      <Marker position={position}>
        <Popup>
          Latitude: {position[0]} <br />
          Longitude: {position[1]}
        </Popup>
      </Marker>
    </MapContainer>
    </>
  );
};

export default TabMap;
