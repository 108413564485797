import React from "react";

import SubPageHead from "../../Layout/Header/SubPageHead";
import RitualTabs from "./RitualTabs";

const Rituals = () => {
  const breadcrumbItem = {
    Homepage: "/",
    Ashram: "/ashram",
  };

  return (
    <>
      <SubPageHead breadcrumbItems={breadcrumbItem} />
      <RitualTabs />
    </>
  );
};

export default Rituals;
