import http from "../../Middleware/http";

const signUp = (data) => {
    return http.post('/api/Account/register', data);
}

const signIn = (data) => {
    return http.post('/api/Account/login', data);
}

const sendEmail = (data) => {
    return http.post('/api/Email/Send', data);
}

const getCities = () => {
    return http.get('/api/Location/all-cities')
}

const getCategory = (data) => {
    return http.get('/api/Category/'+data);
}

const getHotelsByCity = () => {
    return http.get('/api/Hotel/hotel/7');
}

const getHotelDetailById = (data) => {
    return http.get('/api/Hotel/hotel/'+data);
}

const getRoomListByCity = (data) => {
    return http.post('/api/rooms/Rooms', data);
}

const getRoomsByCompanyId = (data) => {
    return http.post('/api/rooms/RoomsByHotel', data);
}

// const getRoomsByCity = (data) => {
//     return http.post('/api/rooms/RoomsByCity', data);
// }

const getRoomImage = (data) => {
    return http.get('/api/rooms/images?id='+data);
}

const getRoomFacility = (data) => {
    return http.get('/api/rooms/facility/'+data);
}

const bookRoom = (data) => {
    return http.post('/api/Booking/book', data);
}

const deactivateRoom = (id) => {
    return http.put('/api/rooms/de-activate/'+id);
}

const bookingOrder = (data) => {
    return http.post('/api/Booking/booking-order', data);
}

const bookingOrderItem = (data) => {
    return http.post('/api/Booking/booking-order-item', data);
}

const getGroup = (data) => {
    return http.get('/api/Group/'+data);
}

const lockRoom = (data) => {
    return http.post('/api/rooms/lock-room', data);
}

const unlockRoom = (id) => {
    return http.delete('/api/rooms/unlock-rooms/'+ id);
}

const lockRoomList = () => {
    return http.get('/api/rooms/lock-room-list');
}

const stayTypes = (data) => {
    return http.post('/api/SuperCategory/GetCategoryList', data);
}

const locality = (data) => {
    return http.post('/api/Location/locality', data);
}

const getCatalog = () => {
    return http.get('/api/Catalog/get-catalog');
}
const getNeighbourhoodType = () => {
    return http.get('/api/Location/get-neighbourhood-type');
}

const getNeighbourhood = (data) => {
    return http.post('/api/Location/get-neighbourhood', data);
}

const getRoomDetailById = (data) => {
    return http.get(`/api/rooms/${data}`)
}

const getRoomBudgetRange = (data) => {
    return http.get(`/api/rooms/budget-range/${data}`)
}
const isEmailExist = (data) => {
    return http.post(`/api/Account/is-email-exists`,data);
}
const forgotPassword = (data) => {
    return http.post(`/api/Account/forgot-password`,data)
}
const resetPassword = (data) => {
    return http.post(`/api/Account/reset-password`,data)
}
const getcategoryBySuperCategory = (data) => {
    return http.get(`/api/Category/category-by-super-category/${data}`)
}
const getRitualDataByCategoryId = (data) => {
    return http.post('/api/rooms/Rooms', data);
}

const configServ = {
    signUp,
    signIn,
    sendEmail,
    getCities,
    getCategory,
    getHotelsByCity,
    getHotelDetailById,
    getRoomListByCity,
    getRoomsByCompanyId,
    //getRoomsByCity,
    getRoomImage,
    getRoomFacility,
    bookRoom,
    deactivateRoom,
    bookingOrder,
    bookingOrderItem,
    getGroup,
    lockRoom,
    unlockRoom,
    lockRoomList,
    stayTypes,
    locality,
    getCatalog,
    getNeighbourhoodType,
    getNeighbourhood,
    getRoomDetailById,
    getRoomBudgetRange,
    isEmailExist,
    forgotPassword,
    resetPassword,
    getcategoryBySuperCategory,
    getRitualDataByCategoryId,

}

export default configServ;