import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    toggleSignIn: false,
    toggleSignUp: false,
    toggleForgot: false,

}

export const signInSlice = createSlice({
    name:'signIn',
    initialState,
    reducers:{
        rSignInToggle:(state)=>{
            state.toggleSignIn = !state.toggleSignIn;
            state.toggleSignUp = false;  
            state.toggleForgot = false;
        },
        rSignUpToggle:(state)=>{
            state.toggleSignUp = !state.toggleSignUp;
            state.toggleSignIn = false;
            state.toggleForgot = false;
        },
        rForgotToggle:(state)=>{
            state.toggleForgot = !state.toggleForgot;
            state.toggleSignIn = false;
            state.toggleSignUp = false;            
        },
    },
});

export const {rSignInToggle, rSignUpToggle,rForgotToggle} = signInSlice.actions;
export default signInSlice.reducer;