import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Switch,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Dialog,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import configServ from "../../Services/Config/config";
import Common from "../../Utils/Common/Common";
import Cookies from "js-cookie";
import SimpleAlert from "../Alert/SimpleAlert";
import jwtDecode from "jwt-decode";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../Redux/Action/authActions";
import { rSignUpToggle } from "../../Redux/Slice/signInSlice";
import { rSignInToggle } from "../../Redux/Slice/signInSlice";
import { rForgotToggle } from "../../Redux/Slice/signInSlice";
import { login } from "../../Redux/Slice/authSlice";
import { message } from "../../Redux/Action/alertActions";
import crypto from "../../Utils/Crypto/Crypto";
import { rSetUserEmail } from "../../Redux/Slice/rGlobalVariableSlice";

const SignIn = React.memo(() => {
  //#region code
  //const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isSignIn = useSelector((state) => state.toggleSignIn.toggleSignIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let count = 59; //number of seconds for countdown
  let initialFormData = {
    email: "",
    password: "",
    otp: "",
  };
  let initialErrors = {
    email: "",
    password: "",
    otpError: "",
  };
  const [genOTP, setGenOTP] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [countdown, setCountdown] = useState(count);
  // const [verified, setVerified] = useState(false);
  const [otpLogin, setOtpLogin] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  // const [loginMethod, setLoginMethod] = useState("password"); // 'password' or 'otp'

  useEffect(() => {
    // Check if there is any previously stored data in cookies
    const storedEmail = Cookies.get("noRoom_rememberedEmail");
    const storedPassword = Cookies.get("noRoom_rememberedPassword");
    if (storedEmail && storedPassword) {
      setFormData({
        email: crypto.decryptText(storedEmail),
        password: crypto.decryptText(storedPassword),
      });
      setRememberMe(true);
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSwitchChange = () => {
    // Toggle the otpLogin state
    setOtpLogin((prev) => !prev);
    setFormData(initialFormData);
    setErrors(initialErrors);
    setOtpSent(false);
    setGenOTP("");
    setIsFirstTime(true);
  };

  const handleSendOtp = async () => {
    const email = formData.email;
    console.log(email);

    const emailError = await isValidEmail(email);
    console.log(emailError);

    if (emailError) {
      setErrors({ ...errors, email: "" });
      const generatedOTP = Common.generateRandomSixDigitNumber();
      setGenOTP(generatedOTP.toString());
      setOtpSent(true);
      setIsFirstTime(false);
      const data = {
        toEmail: email,
        subject: "Verify your account",
        body: `To verify your email address, please use the following One Time Password (OTP):
        ${generatedOTP}
        
        Do not share this OTP with anyone.
        Thank you!`,
      };
      await configServ.sendEmail(data);
    }
  };

  const handleVerifyOtp = () => {
    const otpInput = formData.otp;
    if (otpInput === "") {
      setErrors({ ...errors, otpError: "OTP is required." });
    } else if (genOTP !== otpInput || otpInput.length !== 6) {
      setErrors({ ...errors, otpError: "Incorrect OTP." });
    } else if (otpInput.length === 6 && genOTP === otpInput) {
      setErrors({ ...errors, otpError: "" });
      console.log("verified");
      let data = {
        email: formData.email,
        password: "",
        otpVerification: true,
      };
      handleSignIn(data);
    }
  };

  const handleSignIn = async (data) => {
    const res = await configServ.signIn(data);
    if (res.status === 200) {
      const laravelToken = res.response;
      localStorage.setItem("noRoom_token", res.token);
      const decode = jwtDecode(res.token);
      localStorage.setItem("noRoom_serverToken", laravelToken);
      const user = decode.name;
      const token = res.token;
      console.log("email here-->>", formData.email);
      dispatch(rSetUserEmail(formData.email));
      dispatch(loginUser(user, token));
      handleClose();
    } else if (res.status === 400) {
      dispatch(message(true, res.message));
    } else {
      dispatch(message(true, "Unable to Login. Please try again."));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const email = formData.email;
    const password = formData.password;

    // Validation logic for email, password,
    const emailError = await isValidEmail(email);

    const passwordError = await isValidPassword(password);

    if (emailError && passwordError) {
      setErrors({ email: "", password: "" });

      const data = {
        email: email,
        password: password,
        otpVerification: false,
      };

      handleSignIn(data);

      if (rememberMe) {
        // Store email and password in cookies if "Remember me" is checked
        Cookies.set("noRoom_rememberedEmail", crypto.encryptText(email), {
          expires: 30,
        });
        Cookies.set("noRoom_rememberedPassword", crypto.encryptText(password), {
          expires: 30,
        });
      }
    }
  };

  const isValidEmail = async (email) => {
    if (!email) {
      setErrors({ ...errors, email: "Email is required." });
      return false;
    }

    // You can implement a simple email validation regex or use a library like "validator.js" for more comprehensive validation.
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrors({ ...errors, email: "Invalid email format." });

      return false;
    }

    try {
      const res = await configServ.isEmailExist({ email });

      if (res.status === 200) {
        return true;
      } else {
        setErrors({ ...errors, email: "Email doesn't exist." });
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  const isValidPassword = async (password) => {
    if (!password) {
      setErrors({ password: "Password is required." });
      return false;
    }
    return true;
  };

  const handleClose = () => {
    console.log("here");
    setFormData(initialFormData);
    setErrors(initialErrors);
    setOtpSent(false);
    setGenOTP("");
    setIsFirstTime(true);
    dispatch(rSignInToggle());
  };

  useEffect(() => {
    if (otpSent) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval);
            setOtpSent(false);
            return count;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      // Cleanup the interval on component unmount or when otpSent becomes false
      return () => clearInterval(interval);
    }
  }, [otpSent]);

  //#endregion
  return (
    <Dialog open={isSignIn} onClose={handleClose}>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "20px 40px",
          borderRadius: "10px",
          backgroundColor: "white",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container alignItems="flex-start" justifyContent="center">
            <Grid item xs={12} sm={4}>
              <Typography
                component="div"
                variant="body1"
                sx={{ textAlign: "center" }}
              >
                Login with:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                control={
                  <Switch checked={otpLogin} onChange={handleSwitchChange} />
                }
                label={"OTP"}
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={12} sm={otpLogin ? 9 : 12}>
                <TextField
                  margin="normal"
                  size="small"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  inputProps={{
                    shrink: formData.email ? true : false,
                  }}
                  autoComplete="email"
                  autoFocus
                  error={!!errors.email}
                  helperText={
                    otpSent
                      ? `Resend OTP in ${countdown} Seconds`
                      : errors.email
                  }
                  disabled={!isFirstTime}
                />
              </Grid>
              {otpLogin && (
                <Grid item xs={12} sm={3}>
                  <Button
                    type="button"
                    variant="contained"
                    sx={{ mt: 2, mb: 2 }}
                    onClick={handleSendOtp}
                    fullWidth
                    disabled={otpSent}
                  >
                    {isFirstTime ? "Send OTP" : "Resend"}
                  </Button>
                </Grid>
              )}
            </Grid>
            {otpLogin && (
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid item xs={12} sm={9}>
                  <TextField
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="otp"
                    label="OTP"
                    type="text"
                    id="otp"
                    value={formData.otp}
                    onChange={handleInputChange}
                    onPaste={(e) => e.preventDefault()} // Prevent pasting
                    onCopy={(e) => e.preventDefault()} // Prevent copying
                    error={!!errors.otpError}
                    helperText={errors.otpError}
                    disabled={isFirstTime}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    sx={{ mt: 2, mb: 3 }}
                    onClick={handleVerifyOtp}
                    disabled={isFirstTime}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>
            )}

            {!otpLogin && (
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Grid item xs={12} sm={12}>
                  <TextField
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    inputProps={{
                      shrink: formData.password ? true : false,
                    }}
                    error={!!errors.password}
                    helperText={errors.password}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          {!otpLogin && (
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={rememberMe}
                  onChange={handleRememberMeChange}
                />
              }
              label="Remember me"
            />
          )}

          {!otpLogin && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          )}
          <Grid container>
            <Grid item xs>
              <Link
                onClick={() => dispatch(rForgotToggle())}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#4587ec",
                  "&:hover": {
                    color: "#9400d3",
                  },
                }}
              >
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link
                onClick={() => dispatch(rSignUpToggle())}
                variant="body2"
                sx={{
                  cursor: "pointer",
                  textDecoration: "none",
                  color: "#4587ec",
                  "&:hover": {
                    color: "#9400d3",
                  },
                }}
              >
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
});

export default SignIn;
