import React, { useState } from "react";
import {
  Divider,
  Paper,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  IconButton,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RoomAvailability from "../../RoomDetail/RoomAvailability";
import RoomDeatilScrollble from "../../RoomDetail/RoomDeatilScrollble";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

const RoomDetails = ({ open, close, mapData }) => {
  const handleClose = () => {
    close(false);
  };

  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
        sx={{
          maxHeight: "100vh",
          overflowY: {
            xs: "scroll",
            md: "hidden",
          },
          zIndex: 300,
        }}
      >
        <Paper style={{ overflowY: "hidden" }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Stack direction="row" spacing={2} alignItems="center">

              <Button variant="outlined" onClick={handleToggleDrawer}>
                {drawerOpen ? (
                  <>
                    <KeyboardDoubleArrowLeftIcon />
                  </>
                ) : (
                  <>
                    <KeyboardDoubleArrowRightIcon />
                  </>
                )}
              </Button>
              <Typography
                sx={{ mt: 2, ml: 2, color: "text.secondary" }}
                variant="body2"
              ></Typography>
            </Stack>

            <Button
              size="small"
              variant="contained"
              color="error"
              sx={{ borderRadius: "0", zIndex: 100 }}
              onClick={handleClose}
            >
              <CloseIcon
                color="white"
                sx={{
                  fontSize: "20px",
                  zIndex: 100,
                }}
              />
            </Button>
          </Stack>
          <Grid container alignContent={"space-around"}>
            <Grid item xs={12}>
              {drawerOpen && (
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={5}>
                    <RoomDeatilScrollble mapData={mapData} />
                  </Grid>
                  <Grid item xs={7}>
                    <RoomAvailability />
                  </Grid>
                </Grid>
              )}
              {!drawerOpen && <RoomAvailability />}
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};
export default RoomDetails;
