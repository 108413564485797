import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import Cities from '../../Components/PopUp/City/Cities';
import { useSelector, useDispatch } from 'react-redux';
import { rSignInToggle } from '../../Redux/Slice/signInSlice';
import { logoutUser } from '../../Redux/Action/authActions';
import { useEffect } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';
import StyleIcon from '@mui/icons-material/Style';
import Rituals from '../../Pages/Rituals/RitualsPage'
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LoginIcon from '@mui/icons-material/Login';
import Person4Icon from '@mui/icons-material/Person4';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';
import HotelIcon from '@mui/icons-material/Hotel';
import VillaIcon from '@mui/icons-material/Villa';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DomainIcon from '@mui/icons-material/Domain';
import configServ from '../../Services/Config/config';
import GoogleTranslate from '../../Components/Translations/GoogleTranslate';
import FormPasswordReset from '../../Components/ResetPassword/FormPasswordReset';
import { rSetAccountTask, rSetCartCount} from '../../Redux/Slice/rGlobalVariableSlice';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HeaderCartIcon from '../../Components/Cart/HeaderCartIcon'
const menu = [
    {
        "id": 1,
        "src": <HomeIcon />,
        "label": "Homepage",
        "url": "/"
    },
       {
         "id": 2,
         "src": <LocationCityIcon/>,
         "label": "City",
        "url": ""
       },
    {
        "id": 3,
        "src": <RoomPreferencesIcon />,
        "label": "Lands",
        "url": "/ashram"
    },
    //{
    //   "id": 4,
    //    "src": <StyleIcon />,
    //    "label": "Rituals",
    //    "url": "/rituals"
    //}
]

const settings = [
    {
        "id": 1,
        "src": <Person4Icon />,
        "label": "Profile",
    },
    {
        "id": 2,
        "src": <ManageAccountsIcon />,
        "label": "Account",
    },
    {
        "id": 3,
        "src": <DashboardIcon />,
        "label": "Dashboard",
    },
    {
        "id": 4,
        "src": <LogoutIcon />,
        "label": "Logout",
    },
];

const stayTypes = [
    {
        "id": 1,
        "src": <HotelIcon />,
        "label": "Hotels",
        "url": "/rooms"
    },
    {
        "id": 2,
        "src": <ApartmentIcon />,
        "label": "Apartments",
        "url": ""
    },
    {
        "id": 3,
        "src": <DomainIcon />,
        "label": "Studio Apartments",
        "url": "/studio-apartments"
    },
];

// START FUNCTION
function ResponsiveAppBar() {
    //#region code
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.auth.user);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [anchorElStay, setAnchorElStay] = useState(null);
    const [cityPopupOpen, setCityPopupOpen] = useState(false);
    const [staysMenuOpen, setStaysMenuOpen] = useState(false);
    const [staysMenu, setStaysMenu] = useState([]);
    const [showAccount, setShowAccount] = useState(false);

    useEffect(() => {
        // getCartCount();
        fetchStayList();
    }, []);

    useEffect(() => {
        handleCloseUserMenu();
    }, [user]);

    // const getCartCount=()=>{
    //     const storedArray = localStorage.getItem("categoryIds");
    //     if (storedArray) {
    //       const parsedArray = JSON.parse(storedArray);
    //       if (Array.isArray(parsedArray)) {
    //         dispatch(rSetCartCount(parsedArray.length));
    //       }
    //     }
    // }

    const fetchStayList = async () => {
        try {
            const data = {
                "serviceName": "",
                "createdBy": ""
            }
            const res = await configServ.stayTypes(data);
            if (res.status === 200) {
                const result = res.data.filter(x => x.status === true);
                const staysData = result.map((item) => ({
                    id: item.id,
                    src: getStaysIcon(item.name),
                    label: item.name,
                    url: getStaysRoute(item.name),
                }))
                setStaysMenu(staysData);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getStaysIcon = (data) => {
        try {
            if (data.toLowerCase().includes("hotel")) {
                return <HotelIcon />;
            }
            else if (data.toLowerCase().includes("villa")) {
                return <VillaIcon />;
            }
            else if (data.toLowerCase() === "apartment") {
                return <ApartmentIcon />;
            }
            else if (data.toLowerCase() === "studio apartment") {
                return <DomainIcon />;
            }
            else if (data.toLowerCase() === "ashram") {
                return <DomainIcon />;
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const getStaysRoute = (data) => {
        try {
            if (data.toLowerCase().includes("hotel")) {
                return "/rooms";
            }
            else if (data.toLowerCase().includes("villa")) {
                return "";
            }
            else if (data.toLowerCase() === "apartment") {
                return "";
            }
            else if (data.toLowerCase() === "studio apartment") {
                return "/studio-apartments";
            }
            else if (data.toLowerCase() === "ashram") {
                return "/ashram";
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenu = (item) => {
        try {
            if (item.label.toLowerCase() === "city") {
                handleCityPopupOpen();
            }
            else if (item.label.toLowerCase() === "stays") {
                setStaysMenuOpen(!staysMenuOpen);
            }
            else {
                const route = item.url;
                navigate(route);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSettings = (item) => {
        try {
            if (item.label.toLowerCase() === "logout") {
                dispatch(logoutUser())
                navigate('/');

            }
            else if (item.label.toLowerCase() === "account") {
                dispatch(rSetAccountTask('1'));
                navigate('/account');
            }
            else if (item.label.toLowerCase() === "profile") {
                dispatch(rSetAccountTask('2'));
                navigate('/account');
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleCityPopupOpen = () => {
        setCityPopupOpen(true);
    };

    const handleCityPopupClose = () => {
        setCityPopupOpen(false);
    };

    const handleCityChange = (city) => {
        setSelectedCity(city);
    };
    //#endregion

    return (
        <><Cities open={cityPopupOpen} onClose={handleCityPopupClose} city={handleCityChange} value={selectedCity} />
            <AppBar position="fixed" elevation={0} sx={{ top: 0, color: 'white', backgroundColor: '#173E68', zIndex: 200 }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 'bolder',
                                fontSize: '24px',
                                //   letterSpacing: '.3rem',
                                color: '#ff9016',
                                textDecoration: 'none',
                                '&:hover': {
                                    // color: '#ff9016',
                                    color: '#f25805',
                                },
                            }}
                            onClick={() => navigate('/')}
                        >
                            LAND SALE RECORD
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    '&:hover': {
                                        color: '#ff9016',
                                    },
                                }}
                            >
                                {menu.map((item) => (
                                    <>
                                        <MenuItem key={item.id} onClick={() => handleMenu(item)}>
                                            <Typography textAlign="center" sx={{ display: 'flex', alignItems: 'center' }}>
                                                {item.src}
                                                {item.label}
                                            </Typography>
                                        </MenuItem></>
                                ))}
                            </Menu>
                        </Box>

                        {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontSize: '18px',
                                //   fontFamily: 'monospace',
                                fontWeight: 700,
                                //   letterSpacing: '.3rem',
                                color: '#ff9016',
                                textDecoration: 'none',
                                '&:hover': {
                                    color: '#ff9016',
                                },
                            }}
                            onClick={() => navigate('/')}
                        >
                            LAND SALE RECORD
                        </Typography>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {menu.map((item) => (
                                <Button
                                    key={item.id}
                                    onClick={() => handleMenu(item)}
                                    sx={{
                                        my: 2,
                                        ml: 2,
                                        fontWeight: 700,
                                        fontSize: '15px',
                                        color: 'white',
                                        display: 'flex',
                                        alignItems: 'center',
                                        '&:hover': {
                                            color: 'orange',
                                        },
                                    }}
                                >
                                    <span style={{ marginRight: '8px' }}>{item.src}</span>
                                    {item.label}
                                </Button>
                            ))}

                            {staysMenuOpen && (
                                <Menu
                                    anchorReference="anchorPosition"
                                    anchorPosition={{ top: 60, left: 400 }}
                                    anchorEl={anchorElStay}
                                    // anchorOrigin={{
                                    // vertical: 'top', 
                                    // horizontal: 'center',
                                    // }}
                                    // transformOrigin={{
                                    // vertical: 'top',
                                    // horizontal: 'center',
                                    // }}
                                    open={staysMenuOpen}
                                    onClose={() => setStaysMenuOpen(false)}
                                    sx={{
                                        '&:hover': {
                                            color: '#ff9016',
                                        },
                                    }}
                                >
                                    {staysMenu.map((item) => (
                                        <MenuItem key={item.id} onClick={() => handleMenu(item)}>
                                            <Typography textAlign="center" sx={{ display: 'flex', alignItems: 'center' }}>
                                                {item.src}
                                                {item.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            )}

                            {/* <Button
                            key={11}
                            onClick={() => handleMenu(item)}
                            sx={{
                                my: 2,
                                ml: 2,
                                fontWeight: 700,
                                fontSize: '15px',
                                color: 'white',
                                display: 'flex', 
                                alignItems: 'center',
                                '&:hover': {
                                color: 'orange',
                                },
                            }} 
                        > */}

                            {/* </Button> */}
                        </Box>
                        {/* <GoogleTranslate/> */}
                        <Box sx={{ flexGrow: 0 }}>
                            {user ? (<>
                            <HeaderCartIcon/>
                                {/* <Tooltip title="CART"> */}
                                    {/* <IconButton */}
                                        {/* id="basic-button2" */}
                                        {/* //onClick={handleCart} */}
                                        {/* sx={{
                                            color: 'white',
                                            //filter:'invert(0)'
                                            // width: '5%',
                                            // border:'1px solid red'
                                        }} */}
                                    {/* > */}
                                        {/* <div className={cartValue.length > 0 ? "red-dot" : "no-dot"}>{cartValue.length}</div> */}
                                        {/* <ShoppingCartIcon fontSize='large' onClick={() => navigate('/cart')} /> */}
                                    {/* </IconButton> */}
                                {/* </Tooltip> */}
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{
                                        p: 0, '&:hover': {
                                            color: '#ff9016',
                                        },
                                    }}>

                                        <Avatar alt={user.toUpperCase()} src="/static/images/avatar/2.jpg" sx={{ fontSize: '14px', backgroundColor: '#ff9016', mx: '10px' }} />
                                        <span style={{ fontSize: '12px', color: 'white', cursor: 'pointer' }}>{user.toUpperCase()}</span>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '45px' }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >

                                    {settings.map((item) => (
                                        <MenuItem key={item.id} onClick={() => handleSettings(item)}>
                                            <Typography textAlign="center" sx={{ display: 'flex', alignItems: 'center' }}>
                                                {item.src} {/* Adjust margin as needed */}
                                                {item.label}
                                            </Typography>
                                        </MenuItem>
                                    ))}

                                </Menu>
                            </>) : (
                                <MenuItem onClick={() => dispatch(rSignInToggle())}>
                                    <Typography textAlign="center"
                                        sx={{ display: 'flex', alignItems: 'center', fontWeight: 700, fontSize: '15px' }}>
                                        <LoginIcon sx={{ marginRight: '8px' }} /> {/* Adjust margin as needed */}
                                        SIGN IN
                                    </Typography>
                                </MenuItem>
                            )}
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </>
    );
}
export default ResponsiveAppBar;
