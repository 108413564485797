import { Dialog, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import  notAvailable from '../../../Assets/Images/notAvailable.png';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

const HotelNotAvailaibleInCity = ({open,onClose}) => {
    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth='sm'
            >
                <DialogContent>
                    <DialogContentText>
                        {/* Your hotel is not available in this city. */}
                    </DialogContentText>
                        <div className='bg-image hover-zoom'>
                            <img 
                                src={notAvailable} 
                                alt="Your hotel is not available in this city."  
                                height={'100%'}
                                width={'100%'} 
                            />
                        </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default HotelNotAvailaibleInCity
