import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    openCityPop: false,
  }
  
  export const CityPopUpSlice = createSlice({
    name: 'popupTrigger',
    initialState,
    reducers: {
      trigger: (state) => {
        state.openCityPop = !state.openCityPop;
      },
    },
  })
  
  
  export const { trigger} = CityPopUpSlice.actions
  
  export default CityPopUpSlice.reducer