import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RitualBox from "./RitualBox";
import configServ from "../../Services/Config/config";
import { CircularProgress } from "@mui/material";

const RitualTabs = () => {
// State to keep track of the selected tab index
const [value, setValue] = useState(0);
// Initialize categories as an array
const [categories, setCategories] = useState([]);
const [loading, setLoading] = useState(true);

// Function to handle tab change
const handleChange = (event, newValue) => {
  setValue(newValue);
};

const fetchData = async () => {
  try {
    let superCategory = {};
      const stayData = {
          "serviceName": "",
          "createdBy": ""
        }
      const stay = await configServ.stayTypes(stayData);
      if(stay.status === 200){
          const result = stay.data.filter(x=>x.status === true);
          const staysData = result.map((item)=>({
              id: item.id.toString(),
              label: item.name,
          }))
          superCategory = staysData.filter(x=>x.label.toLowerCase().includes("ritual"));
      }
    const response = await configServ.getcategoryBySuperCategory(superCategory[0].id);
    if (response.status === 200) {
      if(response.data.length > 0){
        setValue(response.data[0].categoryid)
      }
      setCategories(response.data);
    } else {
      console.error("Error fetching data. Status:", response.status);
    }
    setLoading(false);
  } 
  catch (error) {
    setLoading(false);
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchData(); 
}, []);

if(loading === true){
  return (<>
  <h3 style={{display: 'flex', alignItems:'center', justifyContent:'center', marginTop:'20px'}}>List of Pooja/Darshan</h3>
    <div style={{display: 'flex', alignItems:'center', justifyContent:'center', minHeight:'20vh'}}>
      <CircularProgress/>
    </div>
  </>)
}
else{
  return (
    <>
    <h3 style={{display: 'flex', alignItems:'center', justifyContent:'center', marginTop:'20px'}}>List of Pooja/Darshan</h3>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "90%",
            height: "100%",
            typography: "body1",
            //marginTop: "100px",
            border: "1px solid #ccc",
            //padding: "20px",
          }}
        >
          {categories.length > 0 && (
            <TabContext value={value}>
              <Box
                sx={{
                  bgcolor: "background.paper",
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{backgroundColor:'#fc9404'}}
                >
                  {categories.map((category) => (
                    <Tab
                      key={category.categoryid}
                      label={category.categoryname}
                      value={category.categoryid}
                      sx={{color:'white',fontWeight:700}}
                    />
                  ))}
                </TabList>
              </Box>
              {categories.map((category) => (
                <TabPanel key={category.categoryid} value={category.categoryid}>
                <RitualBox
                  categoryId={category.categoryid}
                  categoryName={category.categoryname}
                  categoryDesc={category.description}
                />
              </TabPanel>
              ))}
            </TabContext>
          )}
        </Box>
      </div>
    </>
  );
}
};

export default RitualTabs;
