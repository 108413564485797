import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    stay: "1",
}

export const stayTypeSlice = createSlice({
    name: 'stay',
    initialState,
    reducers:{
        rSetStayType:(state, action)=>{
            state.stay = action.payload
        },
    }
})

export const {rSetStayType} = stayTypeSlice.actions;
export default stayTypeSlice.reducer;