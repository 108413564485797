import { createSlice } from "@reduxjs/toolkit";
import jwtDecode from 'jwt-decode';


const decodeToken = () => {
    const token = localStorage.getItem('noRoom_token');
    const decode = jwtDecode(token);
    return decode.name;
}


const initialState = {
    isAuthenticated: localStorage.getItem('noRoom_token') == null? false: true,
    user: localStorage.getItem('noRoom_token') == null? null: decodeToken(),
    token: localStorage.getItem('noRoom_token'),
  };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        login(state, action){
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout(state){
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
            localStorage.clear();
        }
    }
});

export const {login, logout} = authSlice.actions;
export default authSlice.reducer;