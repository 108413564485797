import React from 'react';
import Footer from '../../Layout/Footer/Footer';
import { useEffect } from 'react';
import Ashram from '../../Components/Ashram/Ashram';
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar';

const Ashrams = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
        <ResponsiveAppBar />        
        <Ashram/>
        <Footer />
    </>
  )
}

export default Ashrams;
