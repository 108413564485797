import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roomsByHotel: [],
}

export const roomsByHotelSlice = createSlice({
    name:'room',
    initialState,
    reducers:{
        rSetRoomsByHotel:(state, action)=>{
            state.roomsByHotel = action.payload
        },
    },
})

export const {rSetRoomsByHotel} = roomsByHotelSlice.actions;
export default roomsByHotelSlice.reducer;