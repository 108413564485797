import React from 'react'
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar'
import SubPageHead from '../../Layout/Header/SubPageHead';
import Footer from '../../Layout/Footer/Footer';
import Disclaimer from '../../Components/Disclaimer/Disclaimer';

const TermsOfUse = () => {
    const breadcrumbItem = {
        Homepage: "/",
      };

  return (
    <>
      <ResponsiveAppBar />
      <SubPageHead breadcrumbItems={breadcrumbItem} />
      <Disclaimer />
      <Footer />
    </>
  )
}

export default TermsOfUse
