import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rLocationId: null,
    rLocationName: null,
};

const rLocationSlice = createSlice({
    name: 'rLocation',
    initialState,
    reducers: {
        rSetLocation:(state,action)=>{
            state.rLocationId = action.payload.rLocationId;
            state.rLocationName = action.payload.rLocationName;
        },
    }
});

export const {rSetLocation} = rLocationSlice.actions;
export default rLocationSlice.reducer;