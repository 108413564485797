// import React from 'react'
// import GoogleMapReact from 'google-map-react';
// import { Divider, Stack, Typography } from '@mui/material';

// const TabNeighbourhood = React.memo(() => {
//     const defaultProps = {
//         center: {
//           lat: 23.344101,
//           lng: 85.309563,
//         },
//         zoom: 15
//       };
//   return (
//     <>
    //  <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'10px' }}>
    //  <Divider component={'h3'} sx={{
    //       py: 2,
    //       width:'80%'
    //     }}
    //     >
    //       HOT SPOTS AROUND
    //     </Divider>
//             {/* <Typography variant='h5'>HOT SPOTS AROUND</Typography> */}
//         </Stack>
//        <div style={{ height: '400px', width: '100%' }}>
//       <GoogleMapReact
//         bootstrapURLKeys={{ key: 'AIzaSyCSbn1M66hrDfFprjnbEC344tWP7DEHPqQ' }} // Replace with your Google Maps API key
//         defaultCenter={defaultProps.center}
//         defaultZoom={defaultProps.zoom}
//       ></GoogleMapReact>
//     </div>
//     </>
//   )
// });

// export default TabNeighbourhood
import React from 'react';
import 'leaflet/dist/leaflet.css';
import { Divider, Stack } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

const TabNeighbourhood = () => {
  const positions = [
    [23.344101, 85.309563], // kisorgauj chowk
    [23.35456723, 85.296013], // Ranchi lake
    [23.360987, 85.3108], // Pahari mandir
    // Add more positions as needed
  ];
  const bounds = L.latLngBounds(positions);

  return (
    <>
    <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'0px' }}>
    <Divider component={'p'} sx={{
         py: 0,
         width:'100%',
         fontWeight:'bold',
         fontSize:'18px'
       }}
       >
         HOT SPOTS AROUND
       </Divider>
       </Stack>
    <MapContainer bounds={bounds} zoom={15} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
      {/* Add a tile layer */}
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {/* Add a marker at the specified position */}
      {positions.map((position, index) => (
        <Marker key={index} position={position}>
          <Popup>
            Marker {index + 1} <br /> Latitude: {position[0]} <br /> Longitude: {position[1]}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
    </>
  );
};

export default TabNeighbourhood;

