import React, { useEffect, useState, useCallback } from "react";
import { Paper, Typography, Grid, Button, CircularProgress } from "@mui/material";
import RitualDetails from "./RitualDetails";
import configServ from "../../Services/Config/config";
import ReadMoreLess from "../../Utils/Common/ReadMoreLess";

const RitualBox = ({ categoryId, categoryName, categoryDesc }) => {
  const [open, setOpen] = useState(false);
  const [ritualData, setRitualData] = useState([]);
  const [selectedRitual, setSelectedRitual] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleClickOpen = useCallback((ritual) => {
    setSelectedRitual(ritual);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const fetchData = async () => {
    try {
      let superCategory = {};
      const stayData = {
        "serviceName": "",
        "createdBy": ""
      }

      const stay = await configServ.stayTypes(stayData);

      if (stay.status === 200) {
        const result = stay.data.filter(x => x.status === true);
        const staysData = result.map((item) => ({
          id: item.id.toString(),
          label: item.name,
        }))
        superCategory = staysData.filter(x => x.label.toLowerCase().includes("ritual"));
      }
      const data = {

        "city": "Ayodhya",
        "superCategory": superCategory[0].id,
        "hotelId": "",
        "category": `${categoryName}`,
        "locationId": [

        ],
        "sortOrder": "low",
        "budgetStart": "",
        "budgetEnd": "",
        "checkIn": null,
        "checkOut": null,
        "page": 1,
        "rowsPerPage": 5

      }
      try {
        const response = await configServ.getRitualDataByCategoryId(data);
        if (response.status === 200) {
          setRitualData(response.result.data);
        } else {
          console.error("Error fetching data. Status:", response.status);
        }
        setLoading(false);
      }
      catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
    catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [categoryId]);

  if (loading === true) {
    return (<>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    </>)
  }
  else {
    return (
      <>
        {open && selectedRitual && (
          <RitualDetails
            open={open}
            handleClose={handleClose}
            ritual={selectedRitual}
          />
        )}
        <Grid container spacing={2} sx={{ backgroundColor: '#fcf8e3' }}>
          {ritualData.map((ritual, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
              <Paper elevation={3} sx={{ p: 2, mb: 2, minHeight: '100%' }}>
                <Typography variant="h6" sx={{ mb: 1, backgroundColor: '#fcf8e3' }}>
                  {ritual.item_name}<span style={{ float: 'right' }}>₹{ritual.costprice}</span>
                </Typography>
                <Typography variant="body1" sx={{ mb: 1, textAlign: 'justify' }}>
                  <ReadMoreLess limit={150}>
                    {ritual.description}
                  </ReadMoreLess>
                </Typography>
                <Typography component="div" sx={{ mb: 1, color: 'text.secondary' }}>
                  {ritual.location.location_name},{ritual.location.city}
                </Typography>
                <Button variant="contained" size="medium" onClick={() => handleClickOpen(ritual)}>
                  View Details
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
};

export default RitualBox;
