import React, { useState } from 'react';

const ReadMoreLess = ({ children, limit }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
    <>
      {(text !== null && text.length > 0 && text !== undefined) ?
        <span style={{opacity: 1}}>
        {isReadMore ? text.slice(0, limit) : text}
        <span onClick={toggleReadMore} style={{cursor:'pointer'}}>
          <strong>{isReadMore ? "...read more" : "...show less"}</strong>
        </span>
        </span> : <span></span>
      }
    </>
    );
  };

export default ReadMoreLess;
