import { createTransform } from 'redux-persist';
import CryptoJS from 'crypto-js';

const secretKey = 'ashram-noroom-and-more'; // Replace with your secret key

const encryptTransformConfig = createTransform(
  (inboundState, key) => {
    // Encrypt the inbound state
    return CryptoJS.AES.encrypt(JSON.stringify(inboundState), secretKey).toString();
  },
  (outboundState, key) => {
    // Decrypt the outbound state
    const bytes = CryptoJS.AES.decrypt(outboundState, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  },
);

// Optional: You can export the encryption and decryption functions if needed
const encrypt = (data) => CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export { encryptTransformConfig, encrypt, decrypt };
