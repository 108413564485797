import React, { useState, useEffect } from "react";
import {
  CssBaseline,
  Dialog,
  Box,
  Avatar,
  Typography,
  Grid,
  Link,
  TextField,
  Button,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { rForgotToggle, rSignInToggle } from "../../Redux/Slice/signInSlice";
import configServ from "../../Services/Config/config";
import Common from "../../Utils/Common/Common";
import VerifiedIcon from "@mui/icons-material/Verified";

const Forgot = () => {
  const initialErrors = {
    email: "",
    otpError: "",
    newPassword: "",
    confirmPassword: "",
  };
  const initialFormData = {
    email: "",
    emailOtpVerification: "",
    newPassword: "",
    confirmPassword: "",
  };

  let count = 59; //number of seconds for countdown
  const [errors, setErrors] = useState(initialErrors);
  const [formData, setFormData] = useState(initialFormData);
  const [otpSent, setOtpSent] = useState(false);
  const [genOTP, setGenOTP] = useState("");
  const [verified, setVerified] = useState(false);
  const [countdown, setCountdown] = useState(count);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [success, setSuccess] = useState(false);

  const isForgot = useSelector((state) => state.toggleSignIn.toggleForgot);

  const dispatch = useDispatch();

  const handleDataChange = (e) => {
    try {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const isValidEmail = async (email) => {
    if (!email) {
      setErrors({ ...errors, email: "Email is required." });
      return false;
    }

    // You can implement a simple email validation regex or use a library like "validator.js" for more comprehensive validation.
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setErrors({ ...errors, email: "Invalid email format." });

      return false;
    }

    try {
      const res = await configServ.isEmailExist({ email });

      if (res.status === 200) {
        return true;
      } else {
        setErrors({ ...errors, email: "Email doesn't exist." });
        return false;
      }
    } catch (error) {
      console.error("Error checking email existence:", error);
      return false;
    }
  };

  const handleVerifyOtp = () => {
    const otpInput = formData.emailOtpVerification;
    if (otpInput === "") {
      setErrors({ ...errors, otpError: "OTP is required." });
    } else if (genOTP !== otpInput || otpInput.length !== 6) {
      setErrors({ ...errors, otpError: "Incorrect OTP." });
    } else if (otpInput.length === 6 && genOTP === otpInput) {
      setErrors({ ...errors, otpError: "" });
      setVerified(true);
      console.log("verified");
    }
  };

  const handleSendOtp = async () => {
    const email = formData.email;
    console.log(email);

    const emailError = await isValidEmail(email);
    console.log(emailError);

    if (emailError) {
      setErrors({ ...errors, email: "" });
      const generatedOTP = Common.generateRandomSixDigitNumber();
      setGenOTP(generatedOTP.toString());
      setOtpSent(true);
      setIsFirstTime(false);
      const data = {
        toEmail: email,
        subject: "Verify your new account",
        body: `To verify your email address, please use the following One Time Password (OTP):
        ${generatedOTP}
        
        Do not share this OTP with anyone.
        Thank you!`,
      };
      await configServ.sendEmail(data);
    }
  };

  const isValidPassword = async (passwords) => {
    if (!passwords.newPassword) {
      setErrors({ newPassword: "New password is required" });
      return false;
    }
    
    if (passwords.newPassword.length < 8) {
      setErrors({ newPassword: "New password must be at least 8 characters" });
      return false;
    }
    if (!passwords.confirmPassword) {
      setErrors({ confirmPassword: "Confirm password is required" });
      return false;
    }

    if (passwords.confirmPassword.length < 8) {
      setErrors({
        confirmPassword: "Confirm password must be at least 8 characters",
      });
      return false;
    }

    // Password strength check
    // const hasUpperCase = /[A-Z]/.test(passwords.newPassword);
    // const hasLowerCase = /[a-z]/.test(passwords.newPassword);
    // const hasDigit = /\d/.test(passwords.newPassword);
    // const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(passwords.newPassword);

    //   if (!(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar)) {
    //     setErrors({
    //         newPassword: "New password must include at least one uppercase letter, one lowercase letter, one digit, and one special character"
    //     });
    //     return false;
    // }

    if (passwords.newPassword !== passwords.confirmPassword) {
      setErrors({
        newPassword: "Passwords do not match",
        confirmPassword: "Passwords do not match",
      });
      return false;
    }

    // Password is valid
    return true;
  };

  const handlePassword = async () => {
    try {
      // Validation
      const isValid = await isValidPassword({
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      });

      if (!isValid) {
        return; // Exit if validation fails
      }

      setErrors({ newPassword: "", confirmPassword: "" });

      const data = {
        email: formData.email,
        newPassword: formData.newPassword,
        confirmPassword: formData.confirmPassword,
      };

      const response = await configServ.forgotPassword(data);

      if (response.status === 200) {
        setSuccess(true);
      } else {
        console.log(response);
        setErrors({
          newPassword: "Some error occurred. Try again.",
          confirmPassword: "Some error occurred. Try again.",
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({
        newPassword: "Error submitting form. Try again later.",
        confirmPassword: "Some error occurred. Try again.",
      });
    }
  };

  const handleSignIn = () => {
    setFormData(initialFormData);
    setGenOTP("");
    setIsFirstTime(true);
    setSuccess(false);
    setVerified(false);
    dispatch(rSignInToggle());
  };

  const handleClose = () => {
    setFormData(initialFormData);
    setErrors(initialErrors);
    setGenOTP("");
    setIsFirstTime(true);
    setSuccess(false);
    setVerified(false);
    dispatch(rForgotToggle());
  };

  useEffect(() => {
    if (otpSent) {
      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
            clearInterval(interval);
            setOtpSent(false);
            return count;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      // Cleanup the interval on component unmount or when otpSent becomes false
      return () => clearInterval(interval);
    }
  }, [otpSent]);

  return (
    <>
      <Dialog open={isForgot} onClose={handleClose}>
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "20px 40px",
            borderRadius: "10px",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <VpnKeyIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            {!success && !verified && (
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} sm={9}>
                    <TextField
                        margin="normal"
                        size="small"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        type="email"
                      value={formData.email}
                      onChange={handleDataChange}
                      autoComplete="email"
                      error={!!errors.email}
                      helperText={
                        otpSent
                          ? `Resend OTP in ${countdown} Seconds`
                          : errors.email
                      }
                      disabled={!isFirstTime}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      type="button"
                      variant="contained"
                      sx={{ mt: 2, mb: 3 }}
                      onClick={handleSendOtp}
                      fullWidth
                      disabled={otpSent}
                    >
                      {isFirstTime ? "Send OTP" : "Resend"}
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} sm={9}>
                    <TextField
                      margin="normal"
                      size="small"
                      autoComplete="off"
                      name="emailOtpVerification"
                      required
                      fullWidth
                      id="emailOtpVerification"
                      label="Email OTP Verification"
                      value={formData.emailOtpVerification}
                      onChange={handleDataChange}
                      onPaste={(e) => e.preventDefault()} // Prevent pasting
                      onCopy={(e) => e.preventDefault()} // Prevent copying
                      error={!!errors.otpError}
                      helperText={errors.otpError}
                      disabled={isFirstTime}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Button
                      fullWidth
                      type="button"
                      variant="contained"
                      sx={{ mt: 2, mb: 3 }}
                      onClick={handleVerifyOtp}
                      disabled={isFirstTime}
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      onClick={handleSignIn}
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#4587ec",
                        "&:hover": {
                          color: "#9400d3",
                        },
                        mt: 5,
                        mb: 3,
                      }}
                    >
                      <Typography variant="body2">Return to Sign In</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!success && verified && (
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                direction="coloumn"
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    margin="normal"
                    autoComplete="off"
                    name="newPassword"
                    required
                    fullWidth
                    id="newPassword"
                    label="New Password"
                    type="password"
                    sx={{ backgroundColor: "white" }}
                    value={formData.newPassword}
                    onChange={handleDataChange}
                    error={!!errors.newPassword}
                    helperText={errors.newPassword}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    required
                    fullWidth
                    margin="normal"
                    label="Confirm New Password"
                    type="password"
                    name="confirmPassword"
                    sx={{ backgroundColor: "white" }}
                    value={formData.confirmPassword}
                    onChange={handleDataChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={12} sx={{ textAlign: "right" }}>
                  <Box mt={2} ml="auto">
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      onClick={handlePassword}
                    >
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            )}
            {success && (
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                direction="column"
              >
                <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
                  <VerifiedIcon fontSize="large" sx={{ mt: 2, mb: 2 }} />
                  <Typography variant="h5" component="div">
                    Password Reset Successfully
                  </Typography>
                </Grid>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link
                      onClick={handleSignIn}
                      variant="body2"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "none",
                        color: "#4587ec",
                        "&:hover": {
                          color: "#9400d3",
                          mt: 5,
                          mb: 3,
                        },
                      }}
                    >
                      <Typography variant="body2">Return to Sign In</Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default Forgot;
