import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar';
import Footer from '../../Layout/Footer/Footer';
import FormPasswordReset from '../../Components/ResetPassword/FormPasswordReset';
import { useSelector, useDispatch } from 'react-redux';
import { rSetAccountTask } from '../../Redux/Slice/rGlobalVariableSlice';

const Account = () => {
  const dispatch = useDispatch();
  const account = useSelector(state=>state.rGlobalVariable.account);
  const [value, setValue] = React.useState(account);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(rSetAccountTask(newValue))
  };
  return (
    <>
      <ResponsiveAppBar />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ minWidth: '70%', minHeight: '50vh', typography: 'body1', marginTop: '100px', border: '1px solid #ccc', padding: '20px' }}>
                <TabContext value={value}>
                <Box sx={{ bgcolor: 'background.paper',borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="Change Password" value="1" />
                    <Tab label="Profile" value="2" />
                    <Tab label="Account" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1" sx={{backgroundColor:'#f9f9f9'}}>
                    <FormPasswordReset/>
                </TabPanel>
                <TabPanel value="2">Item Two</TabPanel>
                <TabPanel value="3">Item Three</TabPanel>
                </TabContext>
            </Box>
        </div>
      <Footer/>
    </>
  )
}

export default Account
