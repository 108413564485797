// CartSummary.js
import React from "react";
import { Typography, Button, Grid } from "@mui/material";

const CartSummary = ({ totalItems,totalAmount,handleEmptyCart, formatCurrency }) => {
  
  return (
    <Grid item xs={12} sm={6} md={4} style={{ textAlign: "left" }}>
      <Typography variant="h4" style={{ margin: "1rem 0" }}>
        Summary
      </Typography>
      <Typography variant="body1">
        Total items: {totalItems}
      </Typography>
      <Typography variant="body1">
        Total price: {formatCurrency(totalAmount)}
      </Typography>
      <Grid container spacing={1} style={{ marginTop: "1rem" }}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleEmptyCart}
            size="small"
            fullWidth
          >
            Empty Cart
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            disabled={totalItems === 0}
            size="small"
            fullWidth
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartSummary;
