import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ReadMoreReadLess = ({ text, maxLength }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div>
      <Typography variant="body2" component="p">
        {showMore ? text : `${text.slice(0, maxLength)}${showMore ? '.' : '...'}`}
        {text.length > maxLength && (
        <span onClick={toggleReadMore} style={{color:'#1976d2', cursor:'pointer'}}>
          {showMore ? 'Read Less' : 'Read More'}
        </span>
      )}
      </Typography>
      
    </div>
  );
};

export default ReadMoreReadLess;