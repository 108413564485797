import Cart from "../../Components/Cart/Cart";
import ResponsiveAppBar from "../../Layout/Header/ResponsiveAppBar";
import Footer from "../../Layout/Footer/Footer";

const CartPage = () => {
  return (
    <>
      <ResponsiveAppBar />
      <Cart />
      <Footer />
    </>
  );
};

export default CartPage;
