import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip placement='Top' {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      //backgroundColor: '#f5f5f9',
      backgroundColor: theme.palette.common.black,
      //color: 'rgba(0, 0, 0, 0.87)',
      color: '#fff',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  export default CustomTooltip;