import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    lockRooms: []
}

export const lockRoomsSlice = createSlice({
    name: 'lockRooms',
    initialState,
    reducers:{
        rLockRoomList:(state, action)=>{
            state.lockRooms = action.payload;
        },
    }
});

export const {rLockRoomList} = lockRoomsSlice.actions;
export default lockRoomsSlice.reducer;