import { Box, Divider, Stack, Typography } from '@mui/material'
import React from 'react'

const ContactUs = React.memo(() => {
    return (
        <>
         <Stack sx={{ justifyContent: 'center',alignItems:'center', width: '100%',my:'0px' }}>
         <Divider component={'p'} sx={{
          py: 0,
          width:'100%',
          fontWeight:'bold',
          fontSize:'18px'
        }}
        >
         GET IN TOUCH
        </Divider>
        </Stack>
            <Stack sx={{mx:'10px'}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" component="p">
                        +91 11 67854312,
                    </Typography>
                    <Typography variant="body2" component="p">
                        +91 11 67890543
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2" component="p">
                    info@bookmyhotel.com,
                    </Typography>
                    <Typography variant="body2" component="p">
                    bookmyhotel.in
                    </Typography>
                </Box>
                <Typography variant="body2" component="p">
                UG-7, Kishoregunj Chowk, Ranchi, Jharkhand, India
                </Typography>                
            </Stack>

        </>
    )
});

export default ContactUs
