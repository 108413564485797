import storage from 'redux-persist/lib/storage';
import { encryptTransformConfig } from './encryption';

const persistConfig = {
  key: 'noroom',
  storage,
  transforms: [encryptTransformConfig],
  whitelist: ['rGlobalVariable', 'reservation'], 
  // Other configuration options can be added here
};

export default persistConfig;
