import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    roomCategory: [],
}

export const roomCategorySlice = createSlice({
    name: 'roomCategory',
    initialState,
    reducers:{
        rSetRoomCategory:(state, action)=>{
            state.roomCategory = action.payload
        },
    }
})

export const {rSetRoomCategory} = roomCategorySlice.actions;
export default roomCategorySlice.reducer;