import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Typography } from "@mui/material";
import CartItems from "./CartItems";
import CartSummary from "./CartSummary";
import configServ from "../../Services/Config/config";
// import { manageCart } from "../ManageCart/ManageCart";
import { useSelector, useDispatch } from "react-redux";
import {
  rSetCartCount,
  rSetCategoryIds,
} from "../../Redux/Slice/rGlobalVariableSlice";

const Cart = () => {
  //#region code
  const dispatch = useDispatch();
  const cartCount = useSelector((state) => state.rGlobalVariable.cartCount);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const categoryIds = useSelector((state) => state.rGlobalVariable.categoryIds);

  useEffect(() => {
    const fetchCartItemsFromApi = async () => {
      try {
        let allGroupItems = []; // Declare an array to store all groupItems
        for (let i = 0; i < categoryIds.length; i++) {
          if (categoryIds[i]) {
            const groupItems = await Promise.all(
              categoryIds[i].map((categoryId) => fetchCartItems(categoryId))
            );
            // Add hotel field for the second array (index 1)
            const modifiedGroupItems = groupItems.map((item) => {
              return i === 1 ? { ...item, hotel: true } : item;
            });

            allGroupItems = [...allGroupItems, ...modifiedGroupItems];
          }
        }

        setCart((prevCart) => [
          ...prevCart,
          ...allGroupItems.map(({ costprice, id, item_name, hotel }) => ({
            costprice,
            id,
            itemName: item_name,
            quantity: 1,
            hotel: hotel || false, // Add the hotel field with a default value
          })),
        ]);
      }
      catch (error) {
        console.log("Error fetching cart items:", error);
        setError("Error fetching cart items. Please try again later.");
      }
      finally {
        setLoading(false);
      }
    };

    fetchCartItemsFromApi();
  }, []);

  const fetchCartItems = async (categoryId) => {
    try {
      const response = await configServ.getRoomDetailById(categoryId);
      const { data } = response; // Assuming the cart items are under the "data" key in the response
      return data;
    }
    catch (error) {
      console.log("Error fetching cart items:", error);
    }
  };

  // const handleIncreaseQty = (product) => {
  //   setCart((prevCart) =>
  //     prevCart.map((item) =>
  //       item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
  //     )
  //   );
  // };

  // const handleDecreaseQty = (product) => {
  //   setCart((prevCart) =>
  //     prevCart.map((item) =>
  //       item.id === product.id && item.quantity > 1
  //         ? { ...item, quantity: item.quantity - 1 }
  //         : item
  //     )
  //   );
  // };

  const handleRemoveFromCart = (product) => {
    try {
      const updatedCart = cart.filter((item) => item.id !== product.id);
      setCart(updatedCart);
      // Decrease cartCount by one, but ensure it doesn't go below zero
      dispatch(rSetCartCount(cartCount > 0 ? cartCount - 1 : 0));
      dispatch(rSetCategoryIds(updatedCart));
      // manageCart.removeCategoryId(product.id);
    }
    catch (e) {
      console.log(e);
    }
  };

  const handleEmptyCart = () => {
    try {
      setCart([]);
      // Set cartCount = 0 when the whole cart is dropped
      dispatch(rSetCartCount(0));
      dispatch(rSetCategoryIds([[], []]));
      // manageCart.clearCategoryIds();
    }
    catch (e) {
      console.log(e);
    }
  };

  const calculateTotalItems = () => {
    return cart.reduce((total, item) => total + item.quantity, 0);
  };

  const calculateTotalAmount = () => {
    return cart.reduce(
      (total, item) => total + item.quantity * item.costprice,
      0
    );
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(amount);
  };
  //#endregion

  return (
    <div style={{ padding: "5rem" }}>
      {loading && <CircularProgress />}
      {error && (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      )}
      {!loading && !error && (

        <Grid container spacing={3}>
          <CartItems
            cart={cart}
            handleRemoveFromCart={handleRemoveFromCart}
            // handleIncreaseQty={handleIncreaseQty}
            // handleDecreaseQty={handleDecreaseQty}
            formatCurrency={formatCurrency}
          />{" "}
          <CartSummary
            totalItems={calculateTotalItems()}
            totalAmount={calculateTotalAmount()}
            handleEmptyCart={handleEmptyCart}
            formatCurrency={formatCurrency}
          />
        </Grid>
      )}
    </div>
  );
};

export default Cart;
