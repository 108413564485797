import React, { useEffect, useState } from 'react'
import RoomList from '../../Components/RoomList/RoomList';
import Footer from '../../Layout/Footer/Footer';
import ResponsiveAppBar from '../../Layout/Header/ResponsiveAppBar';

const RoomPage = () => {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  return (
    <>
      <ResponsiveAppBar/>
      <RoomList />
      <Footer/>
    </>
  )
}

export default RoomPage
