import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hotelId: null,
  roomId: null,
  brandId: null,
  account: "1",
  email: null,
  cartCount: 0,
  // categoryIds:[]
  categoryIds: [[], []], // 0th for rituals ,1st one for hotels
};

export const rGlobalVariableSlice = createSlice({
  name: "rGlobalVariable",
  initialState,
  reducers: {
    rSetHotelId: (state, action) => {
      state.hotelId = action.payload;
    },
    rSetRoomId: (state, action) => {
      state.roomId = action.payload;
    },
    rSetBrandId: (state, action) => {
      state.brandId = action.payload;
    },
    rSetAccountTask: (state, action) => {
      state.account = action.payload;
    },
    rSetUserEmail: (state, action) => {
      state.email = action.payload;
    },
    rSetCartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    rSetCategoryIds:(state, action) =>{
        state.categoryIds = action.payload;
    },
  },
});

export const {
  rSetHotelId,
  rSetRoomId,
  rSetBrandId,
  rSetAccountTask,
  rSetUserEmail,
  rSetCartCount,
  rSetCategoryIds,
} = rGlobalVariableSlice.actions;
export default rGlobalVariableSlice.reducer;
