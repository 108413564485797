import React, { useState, useEffect } from 'react';
import { Stack, Pagination } from '@mui/material';

//const itemsPerPage = 1; // Number of items per page

const PaginateComp = ({totalItems, start, end, page, rowsPerPage}) => {
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(()=>{
    window.scrollTo(0,0);
    // Calculate the starting index of items for the current page
    //const startIndex = (currentPage - 1) * itemsPerPage;
    // Calculate the ending index of items for the current page
    //const endIndex = startIndex + itemsPerPage;
    //start(startIndex);
    //end(endIndex);
    page(currentPage);
  },[currentPage]);
  
  return (
    <Stack spacing={2} alignItems="center" justifyContent="center" padding={2}>
            <Pagination
                count={Math.ceil(totalItems / rowsPerPage)} // Calculate total number of pages
                page={currentPage}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                variant="outlined" 
                color="secondary"
            />
            </Stack>
  )
}

export default PaginateComp
