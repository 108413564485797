import React, { useState, useEffect, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabRoomDetails from '../TabsDetail/TabRoomDetails';
import TabCheckIn from '../TabsDetail/TabCheckIn';
import TabNeighbourhood from '../TabsDetail/TabNeighbourhood';
import TabMap from '../TabsDetail/TabMap';
import ContactUs from '../ContactUs/ContactUs';
import FAQ from '../FAQ/FAQ';
import { Divider, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

const RoomDeatilScrollble = React.memo(({mapData}) => {
  //#region code
  const selectedRoom = useSelector((state)=> state.room.room);
  const [activeTab, setActiveTab] = useState(0);
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    scrollToSection(`section${newValue}`);
  };
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  //#endregion

  return (
    <>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', my: '10px' }}>
        <Divider component={'h5'} sx={{
          py: 0,
          width:'100%',
          fontWeight:'bold'
        }}
        >
          RESERVATION
        </Divider>
      </Stack>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          position: 'sticky',
          top: 0,
          backgroundColor: 'skyblue',
          color: 'white',
          zIndex: '1'
        }}
      >
        <Tab label="Room Details " value={0} sx={{ color: 'white' , fontWeight:'bold'}} />
        <Tab label="Check In Details" value={1} sx={{ color: 'white',fontWeight:'bold'}} />
        <Tab label="Neighbourhood" value={2} sx={{ color: 'white',fontWeight:'bold' }} />
        <Tab label="Map" value={3} sx={{ color: 'white' ,fontWeight:'bold'}} />
        <Tab label="Contact Us" value={4} sx={{ color: 'white' ,fontWeight:'bold'}} />
        <Tab label="FAQ" value={5} sx={{ color: 'white' ,fontWeight:'bold'}} />
      </Tabs>
      <Stack direction={'row'} justifyContent='center' sx={{ width: '100%', mt:'10px' }}>
          <Stack direction={'row'} sx={{ width: '50%', justifyContent: 'center', border: '1px blue dotted' }}>
            <Typography variant="body1" >
              {selectedRoom.caption} :
            </Typography>
            <Typography variant="body1" sx={{ color: 'darkblue' }}>
              ₹{selectedRoom.price} per Night
            </Typography>
          </Stack>
        </Stack>
      <Stack sx={{ height: '410px', overflowY: 'scroll', overflowX: 'hidden' }}>
        <div
          id="section0"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <TabRoomDetails roomData={selectedRoom} />
        </div>

        <div
          id="section1"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <TabCheckIn roomData={selectedRoom}/>
        </div>

        <div
          id="section2"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <TabNeighbourhood />
        </div>
        <div
          id="section3"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <TabMap mapData={mapData}/>
        </div>
        <div
          id="section4"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <ContactUs />
        </div>
        <div
          id="section5"
          style={{ margin: '10px 5px 10px 5px' }}
        >
          <FAQ />
        </div>
      </Stack>
    </>
  )
});

export default RoomDeatilScrollble
