import React,{useEffect, useState} from 'react'
import { Dialog, DialogTitle ,DialogContent, Stack,Typography,IconButton,Button,Card, 
  CardMedia, CardContent, AccordionSummary, Accordion, AccordionDetails} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import configServ from '../../../Services/Config/config';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ShowOnMap from '../StudioApartment/ShowOnMap';


const TransportsPopUp = ({open,setOpen,location,neighbourType}) => {
   const [neighbourhoodData, setNeighbourhoodData] = useState([]);
   const [mapData , setMapData] = useState('');
   const [isMapOpen, setIsMapOpen] = useState(false)
    useEffect(() => {
        fetchNeighbourhood();
      }, []);

    const fetchNeighbourhood = async () =>{
        try{
            const data = {
                "locationId": location.toString()
             }
           const neighbourhoodResponse = await configServ.getNeighbourhood(data);
           console.log('nn',neighbourhoodResponse.data)
           if(neighbourhoodResponse.status === 200) {
            if(neighbourType === null){
              setNeighbourhoodData(neighbourhoodResponse.data)
            }
            else{
              setNeighbourhoodData(neighbourhoodResponse.data.filter(x=>x.type === neighbourType));
            }
           }
           }
        catch (error) {
          console.log(error)
        }
      }
      
    const handleClose = () => {
        setOpen(false);
        setIsMapOpen(false);
    };
    const handleShowOnMap = (item) =>{
      console.log('item',item)
      setMapData(item)
      setIsMapOpen(true)
    }
  return (
    <>
     {isMapOpen === true && <ShowOnMap open={isMapOpen} setOpen={handleClose} mapData={mapData}/>}
    <Dialog
       open={open}
       onClose={handleClose}
       fullWidth
       maxWidth='md'
       >
         <Stack direction='row' justifyContent='space-between' alignItems='center' position={'absolute'} top={10} right={10}>
                    <Typography sx={{ mt: 2, ml: 2, color: 'text.secondary' }} variant='body2'></Typography>
                    <Button size='small' variant='contained' color='error' sx={{borderRadius:'0', zIndex:100}} onClick={handleClose}>
                    <CloseIcon color='white' sx={{
                            fontSize:'20px',
                            zIndex:100
                        }} />
                    </Button>
                </Stack>
        <DialogTitle  className='labels'></DialogTitle>
        <DialogContent sx={{height:'400px'}}>
            {/* {neighbourhoodData.length > 0 && 
            <><Stack direction={{ sm: 'column', md: 'row' }} gap={4}>
              {neighbourhoodData.map((item, index) => (
                <Card key={index} sx={{ border: '1px red solid', height: '300px', width: { sm: '80%', md: '30%' } }}>
                  <CardContent>
                    <Typography variant='h6' sx={{}}>{item.place}</Typography>
                    <Typography>{item.description}</Typography>
                    <Typography>{item.place}</Typography>
                    <Typography>{item.place}</Typography>
                  </CardContent>
                </Card>
              ))}
            </Stack> */}
            {neighbourhoodData.length > 0 && 
              neighbourhoodData.map((item, index) => (
            <Accordion key={index}>
                
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Stack gap={2}>
                  <Typography variant="h6">{item.place}</Typography>
                  <Typography variant="body">{item.description}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack>
                  <Typography variant="body">
                  Distance:{!item.distance?'':item.distance+'km'}
                  </Typography>
                  <Typography variant="body" onClick={()=>handleShowOnMap(item)}
                  sx={{color:'rgb(0, 108, 228)', cursor:'pointer'}}
                  >
                   Show on map
                  </Typography>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              ))}
              {/* </> 
}*/}
     </DialogContent>
     </Dialog>
    </>
  )
}

export default TransportsPopUp