import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import './Breadcrumb.css';

const Breadcrumb = ({ items }) => {
  return (
    <div className="breadcrumbs">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {Object.keys(items).map((label, index) => (
            <Link
            key={index}
            underline="hover"
            color="white"
            href={items[label]}
            sx={{
              fontWeight:'bold',
              ":hover": {
                color: "blue",
              }
            }}
          >
            {label}
          </Link>
        ))}
        </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
