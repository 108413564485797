import React, { useEffect } from 'react'
import RoomLayout from './RoomLayout';
import Moveable from './TabDateScroll';
import { Divider, Stack, Box, Button, Paper, Typography, List, ListItem, ListItemText, Tooltip, Chip, Popover, CircularProgress, Grid  } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import roomSlice, { rSetRoom } from '../../../Redux/Slice/roomSlice';
import { useState } from 'react';
import dayjs from 'dayjs';
import configServ from '../../../Services/Config/config';
import jwtDecode from 'jwt-decode';
import { rLockRoomList } from '../../../Redux/Slice/lockRoomsSlice';
import Common from '../../../Utils/Common/Common';
import Loading from '../../Loading/Loading';
import { rSetRoomId } from '../../../Redux/Slice/rGlobalVariableSlice';
import RoomStatusColorCode from '../RoomStatusColorCode';
import LightTooltip from '../../CustomizeTooltips/LightTooltip';
import CustomTooltip from '../../CustomizeTooltips/CustomTooltip';
import DarkTooltip from '../../CustomizeTooltips/DarkTooltip';

const TabsAvailability = React.memo(() => {
  //#region code
  const dispatch = useDispatch();
  const lockedRooms = useSelector((state)=>state.lockRooms.lockRooms);
  const hotelId = useSelector((state)=> state.rGlobalVariable.hotelId);
  const roomId = useSelector((state)=> state.rGlobalVariable.roomId);
  const brandId = useSelector((state)=> state.rGlobalVariable.brandId);

  const currentDate = dayjs();
  const [requestDate, setRequestDate] = useState(currentDate);
  const [rows, setRows] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  const [loading, setLoading] = useState(true);
  //const data = useSelector((state)=>state.roomsByHotel.roomsByHotel);

  useEffect(()=>{
    const formattedDate = requestDate.format('YYYY-MM-DD');
    const fetchData = async() =>{
      setLoading(true);
      await fetchRoomsByCompany(formattedDate);
    }
    fetchData();
  },[]);

  useEffect(()=>{
    if(rows.length > 0){
      // Using Array.reduce to restructure the data
      const restructuredData = rows.reduce((acc, current) => {
        const existingGroup = acc.find(item => item.group === current.group);

        if (!existingGroup) {
            acc.push({
                group: current.group,
                rooms: [current
                ]
            });
        } else {
            existingGroup.rooms.push(current
            );
        }
        return acc;
      }, []);
      setRoomsData(restructuredData);
    }
  },[rows]);

  const fetchRoomsByCompany = async(formattedDate) =>{
    try{
      const data = {
        "company_id": hotelId.toString(),
        "brand_id": brandId.toString(),
        "super_category": "5",
        "request_date": formattedDate
      }
      
      const res = await configServ.getRoomsByCompanyId(data);
      if(res.status === 200){
        const room = res.data.map((item)=>({
          id: item.id,
          roomNo: item.item_code,
          roomTitle: item.item_name,
          brand: item.brand,
          categoryId: item.category_id,
          category: item.category,
          groupId: item.group_id,
          group: item.group_name,
          costPrice: item.costprice,
          locationId: item.location_id,
          city: item.city,
          superCategory: item.superCategory,
          orderId: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? item.order_item[0].order.id : null) : null) : null,
          status: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? true : false) : false) : false,
          checkIn: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? dayjs(item.order_item[0].order.validity_start, { format: 'YYYY-MM-DD' }).format('ddd, DD, MMM YYYY') : 'NA') : 'NA'): 'NA',
          checkOut: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? dayjs(item.order_item[0].order.validity_end, { format: 'YYYY-MM-DD' }).format('ddd, DD, MMM YYYY') : 'NA') : 'NA') : 'NA',
          customerId: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? item.order_item[0].order.customer_id : null) : null) : null,
          customerName: (item.order_item !== undefined && item.order_item !== null)? (item.order_item.length > 0? ((item.order_item[0].order !== undefined && item.order_item[0].order !== null)? item.order_item[0].order.partyname : null) : null) : null,
          lock: lockedRooms.some((x) => x.item_id === item.id),
          selected: (item.id === roomId? true : false)
        })
        );
        setRows(room);
      }
      else{
        setRows([]);
      }
      setLoading(false);
    }
    catch(error){
      setLoading(false);
      console.log(error);
    }
  }

  const handleRoomChange = async(params) => {
    try{
      if(params.orderId === null){
        await HandleLockChange(params.id);
        dispatch(rSetRoomId(params.id));
        const res = await configServ.getRoomDetailById(params.id);
        const item = res.data;
        const roomdata = {
          id:item.id,
          hotelId:item.company_id,
          src: item.image,
          alt: item.item_code,
          brand: item.brand,
          brandId:item.brand_id,
          category: item.category,
          categoryId:item.category_id,
          group: item.group_name,
          groupId:item.group_id,
          roomNo: item.item_code,
          roomType: item.category,
          caption: item.category,
          price: item.costprice,
          title: item.item_name,
          detail: item.description,
          locationId: item.location_id,
          status: item.status,
          createdAt: item.created_at,
          facilities: item.associated_item,
          neighbourhood: item.company.neighbourhood,
        };
        dispatch(rSetRoom(roomdata));
        
      }
    }
    catch(error){
      console.log(error);
    }
  }

  const HandleLockChange = async(params) => {
    try{
      const unlockRoomsResponse = await configServ.unlockRoom(parseInt(roomId));
      if(unlockRoomsResponse.status === 200){
      }
      const token = localStorage.getItem('noRoom_token');
      let user_id = 0;
      let user_email = "guest@gmail.com";
      if(token !== null){
        const decode = jwtDecode(token);
        user_id = parseInt(decode.user_id);
        user_email = decode.email;
      }
      const data = {
        "hotelId": parseInt(hotelId),
        "roomId": parseInt(params),
        "userId": user_id,
        "userEmail": user_email
      }
      const res = await configServ.lockRoom(data);
      if (res.status === 200) {
        //alert('locked room');
      }
    }
    catch(error){
      console.log(error);
    }
  }

  const displaySelectedDate = async(selectedDate) => {
    // Handle the selected date in this function (e.g., display it)
    const formattedDate = Common.convertDateFormat(selectedDate);
    setLoading(true);
    await fetchRoomsByCompany(formattedDate);
    // You can update state or perform any action with the selected date
  };

  //#endregion

  //#region UI
  return (
    <>
      <Stack sx={{ justifyContent: 'center', alignItems: 'center', width: '100%', my: '10px' }}>
        <Divider component={'h5'} sx={{
          py: 0,
          width:'100%',
          fontWeight:'bold'
        }}
        >
          AVAILABLE ROOMS
        </Divider>
      </Stack>
      {/* <h1>Room Layout</h1> */}
      <Moveable numDaysToShow={40} handleDateSelection={displaySelectedDate}/>
      {/* <RoomLayout numRows={numRows} numCols={numCols} vacantSeats={vacantSeats} /> */}
      {/* <RoomLayout floorData={floorData} occupiedRooms={occupiedRooms} roomTypes={roomTypes} /> */}
      
      <RoomStatusColorCode />
    
      <div style={{marginTop: '10px'}}>
        {loading === true && <>
          <Loading />
        </>}
        {loading === false && roomsData.map((item, index) => (
            <Paper key={index} elevation={0}>
                <Divider component={'h6'} sx={{
                  py: 0,
                  width:'100%',
                  fontWeight:'bold'
                }}
                >
                  {item.group}
                </Divider>
                <List>
                    {item.rooms.map((room, index) => (
                        <Box key={index} display="inline-block" sx={{ padding: '0px', width:'70px' }}>
                        { room.lock === true && room.selected === false ?
                          <Tooltip title={room.category} placement="top" arrow>
                            <Button
                              display="inline"
                              variant={'contained'}
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '12px',
                                fontFamily: 'Times New Roman',
                              }}
                              disabled
                            >
                              {room.roomNo}
                            </Button>
                          </Tooltip>
                          :
                          room.orderId !== null? 
                            (<CustomTooltip title={
                              <React.Fragment>
                                <Typography color="inherit">Room booked</Typography>
                                <b>{'Checkin:'}{' '}{room.checkIn}</b>
                                <br/>
                                <b>{'Checkout:'}{' '}{room.checkOut}</b>
                              </React.Fragment>
                              } arrow placement="top"
                            >
                            <Button
                              display="inline"
                              variant={(room.id === roomId)? 'contained' : 'outlined'}
                              color={'error'}
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '12px',
                                fontFamily: 'Times New Roman',
                              }}
                              //onMouseEnter={(e)=>handlePopoverOpen(e,room)}
                              //onMouseLeave={handlePopoverClose}
                              onClick={()=>handleRoomChange(room)}
                            >
                              {room.roomNo}
                            </Button>
                          </CustomTooltip>)
                          :
                          (<DarkTooltip title={room.category} arrow placement="top">
                            <Button
                              display="inline"
                              variant={(room.id === roomId)? 'contained' : 'outlined'}
                              color={'success'}
                              sx={{
                                fontWeight: 'bold',
                                fontSize: '12px',
                                fontFamily: 'Times New Roman',
                              }}
                              //onMouseEnter={(e)=>handlePopoverOpen(e,room)}
                              //onMouseLeave={handlePopoverClose}
                              onClick={()=>handleRoomChange(room)}
                            >
                              {room.roomNo}
                            </Button>
                          </DarkTooltip>)                                           
                        }
                          
                        <Box sx={{display:'flex',
                          alignItems:'center',
                          justifyContent:'flex-start'}}
                        >
                          <Button variant="body1" component="span" display="inline">
                            ₹{parseInt(room.costPrice)}
                          </Button>
                        </Box>
                      </Box>
                    ))}
                </List>
            </Paper>
        ))}
    </div>
    </>
  )
  //#endregion
});

export default TabsAvailability;
