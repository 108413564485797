import './Cofirmation.css';
import { 
  Box, Button, Card, CardContent, CardMedia, Dialog, DialogContent, DialogTitle,
   Grid, Stack, Typography 
  } from '@mui/material'
import React from 'react';
import configServ from '../../../Services/Config/config';
import { useDispatch } from 'react-redux';
import { rLoading } from '../../../Redux/Slice/loadingSlice';
import { message } from '../../../Redux/Action/alertActions';
import paymentQR from '../../../Assets/Images/paymentQR.png';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';

const ConfirmationPopUp = ({open,onClose,roomData,formDetails,nights}) => {

  const dispatch = useDispatch();

  const handleBooking = async () =>{
    try{
       dispatch(rLoading(true));
       if(formDetails){
      const res = await configServ.bookRoom(formDetails);
      if(res.status === 200){
        dispatch(rLoading(false));
        dispatch(message(true,res.message));
        onClose(true)
      }
      else if(res.status === 401){
        dispatch(rLoading(false));
        dispatch(message(true,'Please sign in to book rooms.'));
      }
      else{
        dispatch(rLoading(false));
        dispatch(message(true,'Booking failed.'));
      }
      //window.location.reload();  
    }}
    catch(error){
      dispatch(rLoading(false));
      onClose(true);
      console.log(error);
    }    
  }
 
  return (
    <>
       <Dialog
       open={open}
      onClose={onClose}
       fullWidth
       maxWidth='lg'
       >
        <DialogTitle 
        //  className='labels' 
        sx={{
          fontWeight:'bold', 
          fontSize:'18px',
          fontFamily:'Times New Roman',
          lineHeight:1.5,
          }}
          >
            Conform Your Booking Details</DialogTitle>
        <DialogContent>

        <Grid container spacing={2} direction="row" justifyContent={'space-around'}> 
      {/* Left Column */}
      <Grid item xs={12} md={5} sx={{ height: 'fit-content' }}>
        <Stack spacing={4}>
          <Card sx={{backgroundColor:'#f0f3fc',border:'1px solid #5c82f2'}}>
            <CardContent>
              {/* Content for the left column card 1 */}
              <Typography variant='body1' sx={{backgroundColor:'yellow',width:'43px',fontFamily:'Times New Roman'}}>Hotel</Typography>
                 <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                  >{roomData.brand}</Typography>
                 <Typography  
                //  className='labels' 
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'18px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  }}
                  >
                    63 Sector 63 Road SD 01 Sector 63A, 201307 Noida, India</Typography>
                 <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  
                  Pet friendly Free WiFi Parking Loundry Restaurant Swimming pool</Typography>
                </CardContent>
          </Card>
          <Card sx={{backgroundColor:'#f0f3fc' , border:'1px solid #5c82f2'}}> 
            <CardContent>
              {/* Content for the left column card 2 */}
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Your Price Summery</Typography>
                <Stack direction={'row'} gap={15}>
                <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                  >
                  Price
                </Typography>
                <Stack>
                <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                >
                  ₹1001
                  {/* {roomData.price} */}
                </Typography>
                <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  + ₹0
                  {/* {formDetails.taxRate}  */}
                  taxes and charges</Typography>
                </Stack>
                </Stack>
                <Stack direction={'row'} gap={15}>
                <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                  >
                  Advance Payment
                </Typography>
                <Stack>
                <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                >
                  ₹501
                  {/* {roomData.price} */}
                </Typography>
                <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  + ₹501
                  {/* {formDetails.taxRate}  */}
                  on arrival</Typography>
                </Stack>
                </Stack>
            </CardContent>
          </Card>
          <Card sx={{display:'flex', alignItems:'center', justifyContent:'center'}} elevation={0}>
            <div className='bg-image hover-zoom'>
              <CardMedia 
                style={{
                  width: '180px',
                  height: '200px'
                }}
                image={paymentQR} 
              />
            </div>
          </Card>
          <Card  
          className="message"
          sx={{
            fontSize:'16px',
            fontFamily:'Times New Roman',
            lineHeight:1.5,
            color:'darkgreen',
            }}>              
            <CardContent >
              <ul>
                <li>Advance payment ₹501 (Non-refundable)</li>
                <li>Remaining payment ₹501 on arrival</li>
              </ul>
              {/* <Typography >No payment today. You'll pay when you stay.</Typography>
              <Typography >Free cancellation 1 day before chek-in-date</Typography>
              <Typography >Check-IN-Time 12:00 PM</Typography> */}
            </CardContent>
          </Card>
        </Stack>
      </Grid>

      {/* Right Column */}
      <Grid item xs={12} md={7} sx={{ height: 'fit-content' }}>
        <Stack spacing={4}>
          <Card sx={{backgroundColor:'#f0f3fc' ,border:'1px solid #5c82f2'}}> 
            <CardContent>
              {/* Content for the right column card 1 */}
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Your Booking Details</Typography>
              <Typography 
                //  className='heads'
                 sx={{
                  fontWeight:'bold', 
                  fontSize:'28px',
                  fontFamily:'Times New Roman',
                  lineHeight:1.5,
                  color:'maroon',
                  }}
                  >{roomData.title}</Typography>
                <Stack gap={30} direction={'row'} > 
                 <Stack>
                 <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Check-In-Date:</Typography>
                 <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {formDetails.checkIn}</Typography>
                 </Stack>
                 <Stack>
                 <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Check-Out-Date:</Typography>
                <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {formDetails.checkOut}</Typography>
                 </Stack>
                 </Stack>
                 <Stack direction={'row'} gap={2}>
                 <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Total Length of Stay:</Typography>
                 <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {nights} night</Typography>
                 </Stack>
                 <Box>
                 {roomData.facilities.length >0 &&
                    <>    <Stack direction={'row'} gap={2}>            
                        <Typography display="inline" className='labels'>
                            Amenities:
                        </Typography>
                            {roomData.facilities.map((facility, fIndex) => (
                                    <Typography
                                    // className='txt-value'
                                    sx={{
                                      fontSize:'16px',
                                      fontFamily:'Times New Roman',
                                      lineHeight:1.5,
                                      color:'navy',
                                      }}
                                       key={fIndex}>
                                        {facility.item_name}
                                    </Typography>
                            ))}
                            </Stack>
                            </>
                }
                </Box>
                            <Stack direction={'row'} gap={2}>
                              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >You have selected:</Typography>
                              <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  
                  1 room 
                  {/* for {formDetails.quantity} Guest.  */}
                  </Typography>
                            </Stack>
                            <Stack direction={'row'} gap={2}>
                              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Room Type:</Typography>
                              <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {roomData.roomType}</Typography>
                              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              > Room No.:</Typography>
                              <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {roomData.roomNo}</Typography>
                               <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Floor:</Typography>
                              <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {roomData.group}</Typography>
                            </Stack> 
                            <Stack direction={'row'} gap={2}>
                              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Email ID:</Typography>
                              <Typography
                  // className='txt-value'
                  sx={{
                    fontSize:'16px',
                    fontFamily:'Times New Roman',
                    lineHeight:1.5,
                    color:'navy',
                    }}
                  >  {formDetails.email}</Typography>
                           </Stack>
            </CardContent>
          </Card>
          {/* <Card>
            <CardContent >
            <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Guest Details</Typography>
            <Stack direction={'row'} gap={2}>
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Name of Guest:</Typography>
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >{formDetails.customerName}</Typography>
              </Stack>
              <Stack direction={'row'} gap={2}>
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >Email ID:</Typography>
              <Typography 
                //  className='labels' 
                sx={{
                 fontWeight:'bold', 
                 fontSize:'18px',
                 fontFamily:'Times New Roman',
                 lineHeight:1.5,
                 }}
              >{formDetails.email}</Typography>
              </Stack>
            </CardContent>
          </Card> */}
        </Stack>
        <Stack direction={'row'} gap={2}>
        <Button 
          sx={{my:'20px'}} 
          variant='contained' 
          fullWidth 
          size='md' 
          
        >          
          <a 
            //href='https://buy.stripe.com/test_dR65kLghUcjm3KgaEE' target='_blank'
            style={{textDecoration: 'none', color:'white'}} 
            onClick={handleBooking}
          >
            Confirm and Pay
          </a>
          </Button>
        <Button sx={{my:'20px'}} variant='contained' fullWidth size='md' onClick={onClose}>Replan your Booking</Button><br/>
        </Stack> 
      </Grid>
      </Grid>
   <br/>
    <Typography 
    className='scrolling-line'
    sx={{
      fontWeight:'bold', 
      fontSize:'18px',
      fontFamily:'Times New Roman',
      lineHeight:1.5,
      color:'lightgrey',
      backgroundColor:'navy'
      }}
    >
      Great deal grab now ** Limited supply for your dates 26 four-star hotels like this are already unavailable on our site** Great deal grab now ** Limited supply for your dates 26 four-star hotels like this are already unavailable on our site** Great deal grab now ** Great deal grab now ** Great deal grab now** Great deal grab now ** Great deal grab now ** Great deal grab now** Great deal grab now ** Great deal grab now ** Great deal grab now** Great deal grab now ** Great deal grab now ** Great deal grab now
    </Typography>
        </DialogContent>
       </Dialog>
    </>
  )
}

export default ConfirmationPopUp