import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isAlert: false,
    message: '',
}

export const alertSlice = createSlice({
    name:'alert',
    initialState,
    reducers:{
        rMessageBox:(state, action)=>{
            state.isAlert = action.payload.isAlert;
            state.message = action.payload.message;
        },
    },
});

export const {rMessageBox} = alertSlice.actions;
export default alertSlice.reducer;