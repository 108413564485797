import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
  const navigate = useNavigate();

  return (
    <MDBFooter bgColor='dark' className='text-white text-center text-lg-start'>
      {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Soon our presence will be on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <FacebookIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <TwitterIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <GoogleIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <InstagramIcon />
          </a>
          <a href='' className='me-4 text-reset'>
            <GitHubIcon />
          </a>
        </div>
      </section> */}

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5 pt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md='3' lg='4' xl='3' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon color='secondary' icon='gem' className='me-3' />
                LAND SALE RECORD
              </h6>
              <p>
                
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful Links</h6>
              <p>
                <a onClick={()=>navigate('/')} className='text-reset' style={{cursor:'pointer'}}>
                  Home
                </a>
              </p>
              <p>
                <a onClick={()=>navigate('/ashram')} className='text-reset' style={{cursor:'pointer'}}>
                  Ashram
                </a>
              </p>
            </MDBCol>

            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Terms of Use</h6>
              <p>
                <a onClick={()=>navigate('terms-of-use')} className='text-reset' style={{cursor:'pointer'}}>
                  Disclaimer
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset' style={{cursor:'pointer'}}>
                  Terms of Service
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset' style={{cursor:'pointer'}}>
                  Privacy Policy
                </a>
              </p>
            </MDBCol>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon color='secondary' icon='home' className='me-2' />
                Ayodhya, UP 221234, INDIA
              </p>
              <p>
                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                
              </p>
              <p>
                <MDBIcon color='secondary' icon='phone' className='me-3' /> 
                {/* + 01 234 567 88 */}
              </p>
              <p>
                <MDBIcon color='secondary' icon='print' className='me-3' /> 
                {/* + 01 234 567 89 */}
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href='https://ricitech.in/' target='_blank'>
          All rights reserved to Ricitech.in
        </a>
      </div>
    </MDBFooter>
  );
}
