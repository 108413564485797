import React, { useState } from "react";
import {
    Grid,
    CardMedia,
    Box,
    Stack,
    Button,
} from '@mui/material';
import TabsAvailability from "../TabsDetail/Availability/TabsAvailability";
import RoomImg from "../PopUp/ImageView/RoomImg";
import { useSelector } from "react-redux";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import CloseIcon from '@mui/icons-material/Close';

const RoomAvailability = React.memo(() => {
    const selectedRoom = useSelector((state)=> state.room.room);
    
    const [openImage, setOpenImage] = useState(false);

    return (
        <>
            <RoomImg open={openImage} setOpen={setOpenImage} imgData={selectedRoom} />
            <Box >
                {/* <Button size='small' variant='contained' color='error' sx={{
                    borderRadius: '0',
                    position: 'absolute',
                    top: 0,
                    right: '50%',
                    zIndex: 100,
                    }} 
                    onClick={handleClose}
                >
                    <CloseIcon color='white' sx={{
                            fontSize:'20px',
                            zIndex:100
                        }} />
                </Button> */}
                <Grid container alignContent={"space-around"}>
                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                position: 'relative',
                                alignItems: 'center',
                                justifyContent: 'center',
                                overflowY: 'scroll',
                                height: '690px',
                                margin: '0px',
                              
                            }}>
                            <Stack>
                                <TabsAvailability />
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
});

export default RoomAvailability;