import { combineReducers } from '@reduxjs/toolkit';

// Your slices or reducers from @reduxjs/toolkit
import CityPopUpSlice from './Slice/CityPopUpSlice';
import authReducer from './Slice/authSlice';
import roomListReducer from './Slice/roomListSlice';
import roomCategoryReducer from './Slice/roomCategorySlice';
import roomReducer from './Slice/roomSlice';
import roomsByHotelReducer from './Slice/roomsByHotelSlice';
import groupByHotelReducer from './Slice/groupByHotelSlice';
import toggleSignInReducer from './Slice/signInSlice';
import loadingReducer from './Slice/loadingSlice';
import alertReducer from './Slice/alertSlice';
import lockRoomsReducer from './Slice/lockRoomsSlice';
import stayTypeReducer from './Slice/stayTypeSlice';
import locationReducer from './Slice/rLocationSlice';
import catalogReducer from './Slice/rCatalogSlice';
import rGlobalVariableReducer from './Slice/rGlobalVariableSlice';
import cartReducer from './Slice/cartSlice';
import ReservationReducer from './Slice/reservationSlice'

// Combine reducers into a root reducer
const rootReducer = combineReducers({
    cities: CityPopUpSlice,
    auth: authReducer,
    rooms: roomListReducer,
    roomCategory: roomCategoryReducer,
    room: roomReducer,
    roomsByHotel: roomsByHotelReducer,
    groupByHotel: groupByHotelReducer,
    toggleSignIn: toggleSignInReducer,
    rLoading: loadingReducer,
    message: alertReducer,
    lockRooms: lockRoomsReducer,
    stay: stayTypeReducer,
    rLocation: locationReducer,
    rCatalog: catalogReducer,
    rGlobalVariable: rGlobalVariableReducer,
    cart: cartReducer,
    reservation: ReservationReducer,
  // Add other reducers here
});

export default rootReducer;
