import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import { format, addDays } from 'date-fns';

const Moveable = ({ numDaysToShow, handleDateSelection }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [dateTabs, setDateTabs] = useState([]);
   
  useEffect(() => {
    // Calculate the dates and set them in the dateTabs state
    const today = new Date();
    const dates = Array.from({ length: numDaysToShow }, (_, index) =>
      format(addDays(today, index), 'dd/MM/yyyy')
    );
    setDateTabs(dates);
  }, [numDaysToShow]);

  const handleDateClick = (selectedDate) => {
    handleDateSelection(selectedDate); // Trigger the parent component's handleDateSelection function
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <>
       <Box>
      <Tabs
       value={selectedTab}
       onChange={handleChange}
        variant="scrollable"
        sx={{ backgroundColor: 'skyblue', color:'white' }}
        >
        {dateTabs.map((date, index) => (
          <Tab
           key={index} 
          label={date} 
          onClick={() => handleDateClick(date)}
          sx={{ color: 'white' }}
          />
        ))}
      </Tabs>
      {/* Your content for each date tab goes here */}
      {/* You can use selectedTab state to conditionally render content */}
      {/* For example, display the content for the selected tab like this */}
      {/* {selectedTab === 0 && <YourComponentForDate date={dateTabs[selectedTab]} />} */}
      </Box>
    </>
  )
}

export default Moveable
